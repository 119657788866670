import React, { Component, Fragment } from "react";
import "./LiveActivity.css";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from "../header/Header";
import LiveActivityTickMark from "../svgicon/LiveActivityTickMark";
import PageTitle from "../page-title/PageTitle";
import { urls } from "../../services/UrlList";
import LoadingBar from "../common/LoadingBar";
import LiveActivityFilter from "./LiveActivityFilter/LiveActivityFilter";
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {Container, Row, Col, Label, Card, Table} from 'reactstrap';
import PmLeadsList from "../Reports/PmLeadsList";

// Framer Motion
import {motion} from 'framer-motion'

export default class LiveActivityDesigners extends Component {
  constructor(props) {
    super(props);
    this.state = {   
      myPrivileges:[], 
      myId:'',    
      rpeople:[],    
      progressBar:false,    
      showMenu:true,  
      showTaskAssigned:false,
      showTaskId:'',
      tasks:'',
      progressBarTask:false,
      isMobileView:0,
      orginalUsers:[],
      usersCount:0,
      showOnebyOneProgress:false,
      stopRunningSearch:false,
      selectedPL:'',
    }
  }

  componentDidMount = () => {
    document.title = "HUB - Live Activity"; 
    this.checkUserPrivilege();   
    this.renderContentDevice(); 
   // this.dataActivity(); 
    // this.taskinterval = setInterval(() => this.getLiveData(), 60000);
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") === null)
    {
      this.setState({isLoggedOut:true})
    }else
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid,myBaseId:login.basecampid, showExtBreak:login.showext });
    }
  };

  renderContentDevice = () => {
    if (isMobile) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  
  dataActivity = (uid, uname) => {   
    let opts={
      uid:uid,
      uname:uname
    }
    fetch(urls.activityDataDesigners,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response => {
        const peoples = [].concat(this.state.rpeople, response);
        this.setState({rpeople:peoples});
      })
      .catch(error => {
        this.setState({
          rpeople: [],
          progressBar: false
        });
      });
  };

  getSortedRecords = response => {
    let temp_list = response;
    let list = [];
    temp_list.forEach(p1 => {
      if (list.length > 0) {
        let found = false;
        list.forEach(p2 => {
          if (p2.uid === p1.uid) {
            found = true;
          } else {
            found = false;
          }
        });
        if (found === false) {
          list.push(p1);
        }
      } else {
        list.push(p1);
      }
    });
    return list;
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

 

  applyFilter = (activefilters) =>{    
    const users = document.querySelectorAll('.doto-users');
    if(activefilters[0] === 'all')
    {
      users.forEach(user => {
        user.classList.add('item-show');     
      })
    }else
    {
      users.forEach(user => {
        user.classList.remove('item-show');
        var showitem = 1;
        for (var index = 0; index < activefilters.length; index++) { 
          if(!user.classList.contains(activefilters[index]))
          {
             showitem = 0;
             break;
          }         
        } 
        if(showitem === 1)
        {
          user.classList.add('item-show');
        }     
      })
    }
  };

  
  showTasks = (id) =>
  {
   if(this.state.showTaskId != id)
   {
    this.setState({
      showTaskId:id,
      progressBarTask:true,
      tasks: [],
      showTaskAssigned:false,
    });
    fetch(urls.getMyTasksDesigners+id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(response => {
          this.setState({
            tasks: (response['today'] && response['today'][0].project_id)? response['today'] :'',
            loading: false,
            showTaskId:id,
            showTaskAssigned:true,
            progressBarTask:false,
          });
        })
        .catch(error => {
          this.setState({
            tasks: [],
            loading: false,
            progressBarTask:false,
            showTaskAssigned:false
          });
        });
    }else
    {
      this.setState({
        showTaskId:'',
        showTaskAssigned:false,
      });
    }
  }

  closeTaskPopup = ()=>
  {
    this.setState({
        showTaskId:'',
        showTaskAssigned:false,
    })
  }
  
  getAllUsers = (selectedPL) =>{  
    this.setState({ progressBar: true});
    let opts = {
      pl_id:selectedPL>0 ?selectedPL : 0,
    };
    var fUrl = urls.getPlDesigners;  
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response && response.length > 0) {     
        this.setState({
          orginalUsers: response,  
          usersCount: response.length        
        });   
        const _this = this;
        setTimeout(()=>{
          var lcount =   _this.loopThroughUsers(0);
        }, 100);
      } 
    })
  };

  loopThroughUsers = (count) =>
  {
    var lcount = count;
    const _this = this;
    this.setState({
      stopRunningSearch:false
    })
    setTimeout(()=>{
      if(lcount === _this.state.usersCount || this.state.stopRunningSearch)
      {
        this.setState({
          showOnebyOneProgress:false,
          stopRunningSearch:false,
          progressBar:false
        })
        return;
      }
      _this.dataActivity(_this.state.orginalUsers[lcount].id, _this.state.orginalUsers[lcount].name);
      lcount++;
      _this.loopThroughUsers(lcount);
    }, 1000);
  }

  selectplmon = (pl)=>
  {
    this.setState({ progressBar: false});
    this.setState({rpeople:[], selectedPL: pl});
    if(pl>0)
    {
      this.getAllUsers(pl);
    }
  }
  
  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.5,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      hidden: { opacity: 0, y: -20 },
      visible: { opacity: 1, y: 0 },
    }
    return (

      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
           logout={this.signOut.bind(this)} 
           onmenuaction={this.toggleSidebar.bind(this)}
           quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="livedata" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
         <Row>
           <Col sm={12} md={12}>
             <Card className="cl_helth_report">
               <div className="heading_filters">
                <h1 class="page-title her_title">Live Data</h1>
                <div className="page-filters">
                    <PmLeadsList  selectplmon={this.selectplmon} />
                </div>
               </div>
             {this.state.isMobileView == 0 ?
             (
            <div>
                <Table responsive className="theme_table liveactive_desiner">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Assigned</th>
                        <th>Pending Tasks</th>
                        <th>R.Work Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.rpeople && this.state.rpeople.length > 0 ?
                    (<div style={{ display:'contents' }}>
                         { this.state.rpeople.map( (item, index) =>
                                {
                                  return (
                      <tr  key={index}>
                        <td>{item.name}</td>
                        <td> <LiveActivityTickMark  classname={item.total_pending_tasks >0 ? "active" : '' } /></td>
                        <td>
                        {item.total_pending_tasks > 0?
                            (
                                <p onClick={this.showTasks.bind(this,item.basecamp_user_id)}>{item.total_pending_tasks}</p>
                            )
                            :
                            (
                                <p>{item.total_pending_tasks}</p>
                            )
                        }
                        </td>
                        <td>
                        <p className={["la-data-body la-rm-time-body"] +(item.extra_mile === 1? ' extra-mile ':'') }><span className="r-hours">{item.remaining_work_hours}</span> </p>
                        </td>
                        </tr>
                        )
                        })
                        }
                      </div>
                     ) : (
                      <>
                        {(this.state.selectedPL==='' && !this.state.progressBar) &&
                        (
                        <tr>
                          <td colSpan={4}>
                            <div className="d-flex justify-content-center">Please select a Project Lead from the dropdown.</div>
                          </td>
                        </tr>
                        )}
                      </>
                     )
                    }
                    </tbody>
                </Table>
            </div>  
            ):
            (
              <div>
                <img src="https://image.flaticon.com/icons/svg/136/136355.svg"></img>
                <div className="mobile-view-blocked text-center">You can't access via mobile.</div>
              </div>             
              ) 
          }  
               {/* </section> */}
               <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.progressBar}>
                       
                </LoadingWrapper>
             </Card>
           </Col>
         </Row>
        </div>
      </Row>
      </Container>
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Fragment>
        <Row>
          <Col sm="10">
          <section id="live-view" className={this.state.isMobileView == 1? "in-mobile": "" }></section>
         </Col>
        </Row>
      </Fragment>
      </Container>
      </div>
    );
  }
}
