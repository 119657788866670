import React, { Component, Fragment } from "react";
import "./LoanDetails.css";
import { Card, Button, Row, Col } from "reactstrap";
import UserPicker from "./UserPicker";
import LoadingBox from "../../LoadingBox/LoadingBox"
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper"

export default class LoanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showApply:false
    };
  }

  showApply = () => {
    this.props.showApply();
  };

  showRepay = () => {
    this.props.showRepay();
  };

  onPeopleChange = (people) =>
  {
    this.props.peoplechange(people);
    if(people >0)
    {
      this.setState({showApply: true});
    }else
    {
      this.setState({showApply: false});
    }
  }
  
  render() {
    let showApplyClass = [
      "btn " + this.props.activeApply ? "btn-green" : "btn-green-outline"
    ].join(" ");
    return (
      <Card id="loan-details-head" >        
        {!this.props.progress?
          (
            <>
            {this.props.loansummary.credit_amount>0 &&
            (
              <Row className="loan-inr-row">
                  <Col xs={12} sm={3} md={3} lg={3}>
                  <div id="loan-total-taken" className="d-flex">
                    <p className="title">Currency</p>
                    <p>INR</p>  
                  </div>
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3}>
                  <div id="loan-total-taken" className="d-flex">
                    <p className="title">Taken</p>
                    <p>
                      {this.props.loansummary.credit_amount >0?
                      (this.props.loansummary.credit_amount)
                      :
                      '0'
                      } 
                    </p>
                  </div>
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3}>
                  <div id="loan-total-paid" className="d-flex">
                    <p className="title">Repaid</p>
                    <p>
                      {this.props.loansummary.debit_amount >0?
                      (this.props.loansummary.debit_amount)
                      :
                        '0'
                      }
                    </p>
                  </div>
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3}>

                  <div id="loan-total-outstanding" className="d-flex">
                    <p className="title">Outstanding</p>
                    <p>
                    {this.props.loansummary.credit_amount >0?
                      (this.props.loansummary.credit_amount - this.props.loansummary.debit_amount)
                      :
                      '0'
                    }
                  </p>
                  </div>
                  </Col>
                </Row>
            )}
            
            {this.props.loansummaryusd.credit_amount>0 &&
             (<Row>
              <Col xs={12} sm={3} md={3} lg={3}>
              <div id="loan-total-taken" className="d-flex">
                <p className="title">Currency</p>
                <p>USD</p>  
              </div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
              <div id="loan-total-taken" className="d-flex">
                <p className="title">Taken</p>
                <p>
                  {this.props.loansummaryusd.credit_amount >0?
                   (this.props.loansummaryusd.credit_amount)
                  :
                  '0'
                  } 
                </p>
              </div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
              <div id="loan-total-paid" className="d-flex">
                <p className="title">Repaid</p>
                <p>
                  {this.props.loansummaryusd.debit_amount >0?
                   (this.props.loansummaryusd.debit_amount)
                  :
                    '0'
                  }
                </p>
              </div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>

              <div id="loan-total-outstanding" className="d-flex">
                <p className="title">Outstanding</p>
                <p>
                {this.props.loansummaryusd.credit_amount >0?
                   (this.props.loansummaryusd.credit_amount - this.props.loansummaryusd.debit_amount)
                  :
                  '0'
                }
               </p>
              </div>
              </Col>
            </Row>
             )}
            </>
          )
          :
          (<LoadingWrapper isLoading={this.props.progress}></LoadingWrapper>)
        }                
      </Card>
    );
  }
}
