import React,{useState, useEffect} from 'react';
import { urls } from '../../services/UrlList';
import axios from 'axios';
import '../filter/filter.css';

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export default function PmLeadsList(props)
{
   
   const selectPLMon =(e)=>
   {
        props.selectplmon(e.target.value);
   }
    const pmLeadsData = async()=>
   {
    return await axios.get(urls.getPMTeamLeads);
   }

   const usePMLeadsData = () => {
        const queryClient = useQueryClient();
        return useQuery(['PMLeads'], () =>  pmLeadsData(),
        {
            refetchOnMount:false,
            refetchOnWindowFocus:false,
        })
    }

    const {
        isLoading: PlsLoading,
        isError,
        error,
        data: pmLeads,
      } = usePMLeadsData();
    
    if(PlsLoading)
    {
        return 'Loading...'
    }else

    return(
        <div class="people date-col pl_list plead_list d-flex justify-content-left">
            {/* <p class="filter-itemlabel">PL: </p> */}
            <select name="pmTeamLead" className="form-control" onChange={selectPLMon}>
                <option value="">Select PL</option>
                {pmLeads?.data[0].pmtls.map((item, index)=>
                {
                    return( 
                    <option key={index} value={item.id}>{item.name}</option>
                    )
                })}
            </select>
        </div>
    )
}