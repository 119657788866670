import React,{useState, useEffect, Fragment, useMemo} from 'react';
import { urls } from '../../services/UrlList';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg';
import {Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, Table, Input, Textarea, Button, Label, InputGroup, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import axios from 'axios';
import { useHistory } from "react-router";
import AccessDenied from "./AccessDenied";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import ProjectCurrentStatus from './ProjectCurrentStatus';
import toast, { Toaster } from 'react-hot-toast';
import MaterialIcon from "material-icons-react";
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import { useFetchMailboxEmailsList } from './SourceManagement/useQueryHooks';
import MailboxEmail from './MailboxEmail';
import SideMenu from "../NewSideMenu/NewSideMenu";

export default function BoardMailBox (props)
{
   const [myId, setmyId] = useState(0);
   const [opencount, setOpenCount] = useState(0);
   const [accessdenied, setAccessDenied] = useState(false);
   const [projectdetails, setProjectDetails] = useState([]);
   const [progressbar, setProgressBar] = useState(true);
   const [showMenu, setShowMenu] = useState(true); 
   const [activeEmailId, setActiveEmailId]= useState(0);
  

   
   const history = useHistory();
   useEffect(() =>
   {    
        AccessCheck('access_projects_management_module');
        getProjectDetails();
   },[] );

   const {
    isLoading: emailsloading,
    isError,
    error,
    isFetching,
    data: emailsData,
    refetch: fetchMailboxEmailsList,
  } = useFetchMailboxEmailsList('1234', props.match.params.id);
  

  function toggleSidebar() {
    setShowMenu(!showMenu);
  }

  function hideMenu() {
    console.log("hello");
    setShowMenu(!showMenu);
  }

    function goToHome()
    {
        history.push({ pathname: "/home" });
    }

    function goToProjectsHome()
    {
        history.push({ pathname: '/boards' });
    }

    function breadNavigation(murl)
    {
        history.push({ pathname: murl });
    }


    function stripslashes(str){
        if(str && str.length>0)
        {
            str = str.replace(/\\'/g, '\'');
            str = str.replace(/\\"/g, '"');
            str = str.replace(/\\0/g, '\0');
            str = str.replace(/\\\\/g, '\\');
        }
        return str;
     }

  const getProjectDetails = () =>
  {
    const data = {
      project_id: props.match.params.id,
      owner: 1,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getProjectDetails, data, {
        headers: headers
      })
      .then((response) => {
          setProgressBar(false);
          setAccessDenied(response.data.valid === 10 ? true : false);
          setProjectDetails(response.data.valid !== 10 && response.data.project_details[0]);
      })
      .catch((error) => {
          setProgressBar(false);
          setAccessDenied(false);
      })
  }

  const showDetails = (id)=>
  {
    setActiveEmailId(activeEmailId === id ? 0 : id);
  }

  function imagedecoder(dat)
  {
    let decodeddata = atob(dat.replace(/-/g, '+').replace(/_/g, '/'));
    return(
        <img src={decodeddata} alt="Red dot" />
    );
  }


  const  emailImgGenerator = (msg) =>
  {
        let html = msg;
        const parser = new DOMParser();
        const dom = parser.parseFromString(html, "text/html");
        const imgTags = dom.querySelectorAll("img");
        imgTags.forEach((imgTag) => {
        imgTag.remove();
        });
        return dom.documentElement.innerHTML;
  }

  const decodeImg =(img)=>
  {
    if(img!='')
    {
    let decodedattach = img!='' ? img.replace(/-/g, '+').replace(/_/g, '/') : '';
    return (
        <img src={`data:image/jpeg;base64, ${decodedattach}`} alt=""  style={{'width': 'auto', 'maxWidth': '100%'}}/>
    );  
    }                                              
  }

  const decodeImgNew =(img)=>
  {
    if(img!='')
    {
    let decodedattach = img.data!='' ? img.data.replace(/-/g, '+').replace(/_/g, '/') : '';
    return (
        <div className='d-flex justify-content-start gap-20'>
            <a  target='_blank' href={`data:image/jpeg;base64, ${decodedattach}`} download ><img src={`data:image/jpeg;base64, ${decodedattach}`} alt=""  style={{'maxWidth': '50%','width': 'auto'}}/></a>
            <div className='attach-filename'><a target='_blank' href={`data:image/jpeg;base64, ${decodedattach}`} download >{img.filename}</a></div>
        </div>
    );  
    }                                              
  }

  const isImage = (base64Data) => {
    const imageTypes = [
      { type: 'jpg', prefix: '/9j/' },
      { type: 'png', prefix: 'iVBORw0KG' },
      { type: 'gif', prefix: 'R0lGOD' }
    ];
    
    for (let i = 0; i < imageTypes.length; i++) {
      if (base64Data.startsWith(imageTypes[i].prefix)) {
        return true;
      }
    }
    
    return false;
  };

  const  hideBrokenImages = (content) => {
    const element = document.createElement('div');
    element.innerHTML = content;
  
    const images = element.getElementsByTagName('img');
  
    for (let i = 0; i < images.length; i++) {
      images[i].setAttribute('onerror', 'this.style.display="none"');
    }
  
    return element.innerHTML;
  }

 
   return(
 
    <Fragment>
          <div className="fullwidth_mobilemenu">
        <Container fluid className="top-bar-inactive bc_module_container">
            <Row>
                <Toaster position="top-right" containerClassName="notifier"
                      toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                          background: '#363636',
                          color: '#fff',
                        },}}
                  />
                <div className="theme_header">
                        <Header
                        ncount={opencount}
                        {...props}
                        onmenuaction={toggleSidebar.bind(this)}
                        className="tick-timer-header"
                        quickactionblock={false}
                    />
                    <SideMenu  
                        activeitem="project" 
                        showmenu={showMenu} 
                        hidemenu={hideMenu.bind(this)}  /> 
                  
                </div>
                <div className="themeContentBody themefullwidthContentBoday">
                    <LoadingWrapper isLoading={progressbar}>
                    {accessdenied ?
                    (
                        <>
                            <AccessDenied gotohome={goToHome} gotoprojectshome={goToProjectsHome} />
                        </>
                    ):
                    (
                        <>
                            <Col sm="12">
                                <div className="bc_Board_wrapper">
                                    <h2 class="section_title d-flex align-items-center justify-content-center">{projectdetails.board_name}
                                    <ProjectCurrentStatus 
                                    graphics={projectdetails.graphics_pack}
                                    video={projectdetails.video_pack}
                                    graphics_video={projectdetails.graphics_video_pack}
                                    canva_graphics={projectdetails.canva_graphics}
                                    canva_plus={projectdetails.canva_plus}
                                    whitelabel={projectdetails.white_label}
                                    clientstatus={projectdetails.client_status} />
                                    </h2>
                                    <p class="board_discrption">{projectdetails.short_description}</p>       
                                    <h2 className="section_title subtitle">Mailbox</h2>
                                    {projectdetails.canva_access!==null && projectdetails.canva_access!== '' &&
                                    (
                                        <MailboxEmail canva_access={projectdetails.canva_access}/>
                                    )}
                                    <div className="page_header_wrap">
                                        <Row>
                                        <Col sm={6}></Col>
                                        <Col sm={6}>
                                            <div className="search-box">
                                            <a className="edit_board_detail_icon" onClick={()=>breadNavigation('/boards')} > <MaterialIcon icon="fact_check"  /> </a>
                                               
                                                <a className="home_button_box" onClick={goToHome}><img src={Homebackicon} alt="back" /></a>
                                            </div>
                                        </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="d-flex ">
                                    <div className="breadcrumb_boards">
                                        <Breadcrumb>
                                            <BreadcrumbItem><a onClick={()=>breadNavigation('/boards')}>Boards</a></BreadcrumbItem>
                                            <BreadcrumbItem><a onClick={()=>breadNavigation('/boardsdetails/'+props.match.params.id)}>Board Details</a></BreadcrumbItem>
                                            <BreadcrumbItem active>MailBox</BreadcrumbItem>
                                        </Breadcrumb>
                                    </div>
                                    <Button className="refrshBtn" onClick={fetchMailboxEmailsList}><MaterialIcon icon="refresh"/></Button>
                                </div>
                                
                               
                            </Col>

                            <Col sm="12">
                                <div className="note_add_wrapper brand_note_add_wrapper">
                                    <Card>
                                        {emailsloading || isFetching ?
                                        (
                                            <span style={{'textAlign':'center'}}>Loading....</span>
                                        ):(
                                            <>
                                                {emailsData.data.emails?.length> 0 ?
                                                (
                                                    <>
                                                        {emailsData.data.emails?.map((email, index)=>
                                                        {
                                                            let decodedBody = atob(email.body.replace(/-/g, '+').replace(/_/g, '/'));
                                                            //let decodedattach = email.attachment!='' ? email.attachment.replace(/-/g, '+').replace(/_/g, '/') : '';
                                                            //emailImgGenerator(decodedBody, email.attachments, email.attachmentnames);
                                                            return(
                                                                <div className='mailbox-emails' key={email.id}>
                                                                    <div className='d-flex justify-content-between' onClick={showDetails.bind(this,email.id)}>
                                                                        <span className='mailbox-from'>{email.from}</span>
                                                                        <span className='mailbox-subject'>{email.subject}</span>
                                                                        <span className='mailbox-date'>{email.date}</span>
                                                                    </div>
                                                                    <div className={`email-body ${activeEmailId === email.id && 'activeopen'}`}>
                                                                        <Linkify className="cmd_editer_preview" options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(decodedBody + " ")}</Linkify>
                                                                        {(email.attachments?.length>0 || email.inlineitems?.length>0) && 
                                                                        (
                                                                        <>
                                                                            <h6 className="email-attachment-head">Attachments</h6>
                                                                            <div className="email-attachments">
                                                                                {email.attachments?.map((attach, index)=>
                                                                                {
                                                                                    let fileByteArray = attach.data!='' ? attach.data.replace(/-/g, '+').replace(/_/g, '/') : '';
                                                                                    let fileBlob = new Blob([fileByteArray], { type: 'application/octet-stream' });
                                                                                    let fileUrl = URL.createObjectURL(fileBlob);
                                                                                    return(

                                                                                        <span key={index}>
                                                                                            
                                                                                        {(isImage(fileByteArray) ) ?
                                                                                            (
                                                                                                decodeImgNew(attach)
                                                                                            )  
                                                                                            :
                                                                                            (
                                                                                                (<a href={fileUrl}>{attach.filename}</a>)
                                                                                            )} 
                                                                    
                                                                                        </span>
                                                                                    )
                                                                                })}


                                                                                {/* {email.inlineitems?.map((attach, index)=>
                                                                                {
                                                                                    let fileByteArray = atob(attach.data);
                                                                                    let fileBlob = new Blob([fileByteArray], { type: 'application/octet-stream' });
                                                                                    let fileUrl = URL.createObjectURL(fileBlob);
                                                                                    return(

                                                                                        <span key={index}>
                                                                                        {attach.filename}
                                                                                        {(attach.filename.endsWith(".jpg") || attach.filename.endsWith(".png") || attach.filename.endsWith(".jpeg")
                                                                                        || attach.filename.endsWith(".gif") ) ?
                                                                                            (
                                                                                                decodeImg(attach.data)
                                                                                            )  
                                                                                            :
                                                                                            (
                                                                                                (<a href={fileUrl}>{attach.filename}</a>)
                                                                                            )} 
                                                                    
                                                                                        </span>
                                                                                    )
                                                                                })} */}
                                                                            </div>
                                                                        </>
                                                                        )}
                                                                        
                                                                        
                                                                        {/* {email.attachment!='' && decodedattach!='' &&  <img src={`data:image/jpeg;base64, ${decodedattach}`} alt=""  style={{'width': 'auto'}}/>} */}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                ):
                                                (
                                                    <div>No matching emails found</div>
                                                )}
                                            </>
                                        )}
                                       
                                    </Card>
                                </div>
                            </Col>
                          
                        </>
                    )}
                    </LoadingWrapper>
                </div>
            </Row>
        </Container>
        </div>
    </Fragment>
   )
}
