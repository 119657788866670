import React, { useState, useEffect, Fragment, useMemo } from "react";
import { urls } from "../../services/UrlList";
import "./basecampmodule.css";
import Header from "../header/Header";
import Homebackicon from "../../images/bc_homeicon.svg";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Table,
  Input,
  Textarea,
  Button,
  Label,
  InputGroup,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { AccessCheck, AccessCheckBlock } from "./AccessCheck";
import axios from "axios";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import AccessDenied from "./AccessDenied";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import ConfirmAction from "../admincomment/ConfirmAction";
import ProjectCurrentStatus from "./ProjectCurrentStatus";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import "react-quill/dist/quill.snow.css";
import toast, { Toaster } from "react-hot-toast";
import MaterialIcon from "material-icons-react";
import Linkify from "linkifyjs/react";
import ReactHtmlParser from "react-html-parser";
import SideMenu from "../NewSideMenu/NewSideMenu";
import MailboxEmail from "./MailboxEmail";

Quill.register("modules/imageUploader", ImageUploader);
export default function BrandPreset(props) {
  const notifySuccess = () => toast("Brand predefined informations updated.");
  const notifyError = () => toast("Something went wrong. Please try again.");
  const [myId, setmyId] = useState(0);
  const [opencount, setOpenCount] = useState(0);
  const [accessdenied, setAccessDenied] = useState(false);
  const [projectdetails, setProjectDetails] = useState([]);
  const [brandname, setBrandName] = useState("");
  const [foldername, setFolderName] = useState("");
  const [progressbar, setProgressBar] = useState(true);
  const [brandinstructions, setBrandInstructions] = useState("");
  const [colorinstructions, setColorInstructions] = useState("");
  const [fontinstructions, setFontInstructions] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedIn] = useState("");
  const [instagram, setInstagram] = useState("");
  const [pinterest, setPinterest] = useState("");
  const [othermedia, setOtherMedia] = useState("");
  const [brandcolors, setBrandColors] = useState([]);
  const [cropneeded, setCropNeeded] = useState(0);
  const [bleedsize, setBleedSize] = useState("");
  const [bleedunit, setBleedUnit] = useState("");
  const [dsmmmodule, setDsmmModule] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [showConfirmBox, setShowConfirmBox]= useState(false);
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link", "image"],
    ["clean"],
  ];

  const modules = React.useMemo(
    () => ({
      toolbar: toolbarOptions,
      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("file", file);
            data.append("filename", file.name);
            data.append("filesize", file.size);
            data.append("filetype", file.type);
            data.append("folder_path", "editor");
            data.append("user_id", 1);
            axios
              .post(urls.uploadProjectMediaEditor, data, {})
              .then((res) => {
                resolve(res.data.file_name);
              })
              .catch((error) => {
                reject("Upload failed");
                console.error("Error:", error);
              });
          });
        },
      },
    }),
    []
  );

  const history = useHistory();
  useEffect(() => {
    AccessCheck("access_projects_management_module");
  }, []);

  useEffect(() => {
    getFolderAssets();
  }, [myId]);

  const checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user") !== null) {
      setmyId(login.uid);
    }
  };



  function getFolderAssets() {
    const data = {
      project_id: props.match.params.pid,
      brand_id: props.match.params.bid,
      folder_id: props.match.params.fid,
      user_id: myId,
    };
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-origin": "*",
    };
    axios
      .post(urls.getBrandFolderInfo, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.project_details[0].board_type === 1) {
          setDsmmModule(false);
        }
        response.data.success === 10 && setAccessDenied(true);
        response.data.success !== 10 &&
          setProjectDetails(response.data.project_details[0]);
        response.data.success !== 10 && setBrandName(response.data.brand_name);
        response.data.success !== 10 &&
          setFolderName(response.data.folder_name);

        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setBrandInstructions(response.data.info[0].branding_instructions);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setBrandColors(JSON.parse(response.data.info[0].colors));
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setColorInstructions(response.data.info[0].color_instructions);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setFontInstructions(response.data.info[0].font_instructions);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setWebsite(response.data.info[0].Website);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setFacebook(response.data.info[0].Facebook);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setTwitter(response.data.info[0].Twitter);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setLinkedIn(response.data.info[0].Linkedin);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setPinterest(response.data.info[0].Pinterest);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setInstagram(response.data.info[0].Instagram);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setOtherMedia(response.data.info[0].OtherMedia);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setCropNeeded(response.data.info[0].resize_crop);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setBleedSize(response.data.info[0].bleed_size);
        response.data.success !== 10 &&
          response.data.info.length > 0 &&
          setBleedUnit(response.data.info[0].bleed_unit);
        setProgressBar(false);
      })
      .catch((error) => {
        setAccessDenied(true);
        setProjectDetails([]);
        setBrandName("");
        setFolderName("");
      });
  }

  function toggleSidebar() {
    setShowMenu(!showMenu);
  }

  function hideMenu() {
    setShowMenu(!showMenu);
  }

  function goToHome() {
    //this.props.history.push("/home");
    history.push({ pathname: "/home" });
  }

  function goToProjectsHome() {
    //this.props.history.push("/boards");
    history.push({ pathname: "/boards" });
  }

  function breadNavigation(murl) {
    console.log(murl);
    history.push({ pathname: murl });
  }

  function stripslashes(str) {
    if (str && str.length > 0) {
      str = str.replace(/\\'/g, "'");
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, "\0");
      str = str.replace(/\\\\/g, "\\");
    }
    return str;
  }

  function handleChangeEditor(value) {
    setBrandInstructions(value);
  }

  function handleChangeEditorColor(value) {
    setColorInstructions(value);
  }

  function handleChangeEditorFont(value) {
    setFontInstructions(value);
  }

  function handleColorInputChange(index, event) {
    const values = [...brandcolors];
    if (event.target.name === "hexcode") {
      values[index].hexcode = event.target.value;
    } else if (event.target.name === "primary") {
      if (event.target.checked) {
        values[index].primary = 1;
      } else {
        values[index].primary = 0;
      }
    }
    setBrandColors(values);
  }

  function addColorFields() {
    const values = [...brandcolors];
    values.push({ hexcode: "", primary: 0 });
    setBrandColors(values);
  }

  function removeColorFields(index) {
    const values = [...brandcolors];
    values.splice(index, 1);
    setBrandColors(values);
  }

  function updateMedia(event) {
    if (event.target.name === "website") {
      setWebsite(event.target.value);
    } else if (event.target.name === "facebook") {
      setFacebook(event.target.value);
    } else if (event.target.name === "twitter") {
      setTwitter(event.target.value);
    } else if (event.target.name === "linkedin") {
      setLinkedIn(event.target.value);
    } else if (event.target.name === "instagram") {
      setInstagram(event.target.value);
    } else if (event.target.name === "pinterest") {
      setPinterest(event.target.value);
    } else if (event.target.name === "othermedia") {
      setOtherMedia(event.target.value);
    }
  }

  function saveBrandInfoData() {
    const data = {
      brand_id: props.match.params.bid,
      branding_instructions: brandinstructions,
      colors: brandcolors,
      color_instructions: colorinstructions,
      font_instructions: fontinstructions,
      Website: website,
      Facebook: facebook,
      Twitter: twitter,
      Linkedin: linkedin,
      Instagram: instagram,
      Pinterest: pinterest,
      OtherMedia: othermedia,
      resize: cropneeded,
      bleedsize: bleedsize,
      bleedunit: bleedunit,
    };
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-origin": "*",
    };
    axios
      .post(urls.savebrandInstructions, data, {
        headers: headers,
      })
      .then((response) => {
        response.data.success === 1 ? notifySuccess() : notifyError();
        if (response.data.success === 1 && projectdetails.board_type === 2) {
          setDsmmModule(true);
        }
        //response.data.success ===1 &&  history.push({ pathname: "/brand/"+props.match.params.pid+"/"+props.match.params.bid });
      })
      .catch((error) => {
        notifyError();
      });
  }

  const handleSizeInputChange = (event) => {
    if (event.target.name === "bleed-size") {
      setBleedSize(event.target.value);
    } else if (event.target.name === "unitsize") {
      setBleedUnit(event.target.value);
    }
  };

  //faheemadded
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(true);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const closeDialogue=()=>
  {
    setShowConfirmBox(false);
  }


  const resetBrandBestDesignsTriger = ()=>
  {
    setShowConfirmBox(true);
  }

  function resetBrandBestDesigns() {
    const data = {
      brandId: props.match.params.bid,
      boardID: props.match.params.pid,
    };
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-origin": "*",
    };
    axios
      .post(urls.resetBrandBestDesigns, data, {
        headers: headers,
      })
      .then((response) => {
        setShowConfirmBox(false);
        response.data.success === 1 ? toast("Brand settings updated.") : notifyError();
      })
      .catch((error) => {
        setShowConfirmBox(false);
        notifyError();
      });
  }


  return (
    <Fragment>
      <div className="fullwidth_mobilemenu">
        <Container fluid className="top-bar-inactive bc_module_container">
          <Row>
            <Toaster
              position="top-right"
              containerClassName="notifier"
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },
              }}
            />
            <div className="theme_header">
              <Header
                ncount={opencount}
                {...props}
                onmenuaction={toggleSidebar.bind(this)}
                className="tick-timer-header"
                quickactionblock={false}
              />
              <SideMenu  
                activeitem="project" 
                showmenu={showMenu} 
                hidemenu={hideMenu.bind(this)}  /> 
            </div>
            <div className="themeContentBody themefullwidthContentBoday">
              <LoadingWrapper isLoading={progressbar}>
                {accessdenied ? (
                  <>
                    <AccessDenied
                      gotohome={goToHome}
                      gotoprojectshome={goToProjectsHome}
                    />
                  </>
                ) : (
                  <>
                    <Col sm="12">
                      <div className="bc_Board_wrapper">
                        <h2 class="section_title d-flex align-items-center justify-content-center">
                          {projectdetails.board_name}
                          <ProjectCurrentStatus
                            graphics={projectdetails.graphics_pack}
                            video={projectdetails.video_pack}
                            graphics_video={projectdetails.graphics_video_pack}
                            canva_graphics={projectdetails.canva_graphics}
                            canva_plus={projectdetails.canva_plus}
                            whitelabel={projectdetails.white_label}
                            clientstatus={projectdetails.client_status}
                          />
                        </h2>
                        <p class="board_discrption">
                          {projectdetails.short_description}
                        </p>
                        <MailboxEmail
                          canva_access={projectdetails.canva_access}
                        />
                        <h2 className="section_title subtitle">{foldername}</h2>
                        <div className="page_header_wrap">
                          <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                              <div className="search-box">
                                <a
                                  className="edit_board_detail_icon"
                                  onClick={() => breadNavigation("/boards")}
                                >
                                  {" "}
                                  <MaterialIcon icon="fact_check" />{" "}
                                </a>

                                <a
                                  className="home_button_box"
                                  onClick={goToHome}
                                >
                                  <img src={Homebackicon} alt="back" />
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="breadcrumb_boards">
                        <Breadcrumb>
                          <BreadcrumbItem>
                            <a onClick={() => breadNavigation("/boards")}>
                              Boards
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a
                              onClick={() =>
                                breadNavigation(
                                  "/boardsdetails/" + props.match.params.pid
                                )
                              }
                            >
                              Board Details
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a
                              onClick={() =>
                                breadNavigation(
                                  "/docandfiles/" + props.match.params.pid
                                )
                              }
                            >
                              Doc and Files
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a
                              onClick={() =>
                                breadNavigation(
                                  "/brand/" +
                                    props.match.params.pid +
                                    "/" +
                                    props.match.params.bid
                                )
                              }
                            >
                              {brandname}
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem active>{foldername}</BreadcrumbItem>
                        </Breadcrumb>
                      </div>
                    </Col>
                    <Col>
                      <div className="note_add_wrapper brand_note_add_wrapper">
                        <Card>
                          {projectdetails.board_type === 2 ? (
                            <div className="d-flex justify-content-between">
                              <h6>Instructions</h6>
                              {dsmmmodule && (
                                <div
                                  className="edit_board_detail_icon"
                                  onClick={() => setDsmmModule(false)}
                                >
                                  <MaterialIcon icon="edit" />
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              <h5>Branding</h5>
                              <h6>Brand Instructions</h6>
                            </>
                          )}
                          {AccessCheckBlock("add_edit_doc_files") &&
                          !dsmmmodule ? (
                            <div className="dsmm-pre-instructions">
                              <ReactQuill
                                value={stripslashes(brandinstructions)}
                                onChange={handleChangeEditor}
                                placeholder="Add brand instructions....."
                                modules={modules}
                                theme="snow"
                              />
                            </div>
                          ) : (
                            <div className="dsmm-pre-instructions">
                              <Linkify
                                options={{
                                  attributes: (href, type) =>
                                    type === "url"
                                      ? { rel: "nofollow noopener noreferrer" }
                                      : {},
                                }}
                              >
                                {ReactHtmlParser(
                                  stripslashes(brandinstructions) + " "
                                )}
                              </Linkify>
                            </div>
                          )}
                        </Card>
                      </div>

                      {projectdetails.board_type === 1 && (
                        <>
                          <div className="note_add_wrapper brand_note_add_wrapper color_note_add_box">
                            <Card>
                              <h5>Colors</h5>
                              <Table responsive className="theme_table">
                                <thead>
                                  <tr>
                                    <th>Hex Code</th>
                                    <th>Primary</th>
                                    <th>Delete</th>
                                  </tr>
                                </thead>
                                {brandcolors && brandcolors.length > 0 && (
                                  <tbody>
                                    {brandcolors.map((sitem, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="d-flex align-items-center">
                                            <span
                                              className="hexcodeview"
                                              style={{
                                                background: sitem.hexcode
                                                  ? sitem.hexcode
                                                  : "#fff",
                                              }}
                                            ></span>
                                            <span>
                                              <Input
                                                type="text"
                                                className="hexcode_input"
                                                name="hexcode"
                                                value={sitem.hexcode}
                                                onChange={(event) =>
                                                  handleColorInputChange(
                                                    index,
                                                    event
                                                  )
                                                }
                                              />
                                            </span>
                                          </td>
                                          <td className="text-right">
                                            <div className="primary_hexcode_checkbox">
                                              <Input
                                                type="checkbox"
                                                name="primary"
                                                value={sitem.primary}
                                                checked={
                                                  sitem.primary === 1 &&
                                                  "checked"
                                                }
                                                onChange={(event) =>
                                                  handleColorInputChange(
                                                    index,
                                                    event
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          {/* <td>
                                                                    {index !== 0 &&
                                                                        (<button className="remove_btn" onClick={event => removeColorFields(index)}></button>
                                                                    )}
                                                                </td> */}
                                          <td>
                                            <button
                                              className="remove_btn"
                                              onClick={(event) =>
                                                removeColorFields(index)
                                              }
                                            ></button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                )}
                              </Table>

                              <Button
                                className="btn-green save_btn add_not_morecolor"
                                onClick={addColorFields}
                                name="add-more-colors"
                                value="add-coolors"
                              >
                                + Add More Colors
                              </Button>
                              <div className="color_instr_note_add">
                                <h6>Color Instructions</h6>
                                <ReactQuill
                                  value={stripslashes(colorinstructions)}
                                  onChange={handleChangeEditorColor}
                                  placeholder="Add color instructions....."
                                  modules={modules}
                                  theme="snow"
                                />
                              </div>
                            </Card>
                          </div>

                          <div className="note_add_wrapper brand_note_add_wrapper">
                            <Card>
                              <h5>Fonts</h5>
                              <h6>Font Instructions</h6>
                              <ReactQuill
                                value={stripslashes(fontinstructions)}
                                onChange={handleChangeEditorFont}
                                placeholder="Add font instructions....."
                                modules={modules}
                                theme="snow"
                              />
                            </Card>
                          </div>

                          <div className="note_add_wrapper brand_note_add_wrapper">
                            <Card>
                              <h5>Crop and Bleed</h5>
                              <div className="d-flex reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2x">
                                <h6>Crop / Trim Required:</h6>
                                <div className="d-flex crop-items">
                                  <Input
                                    type="radio"
                                    id="crop-yes"
                                    name="crop-resize"
                                    value="1"
                                    checked={cropneeded == 1 && "checked"}
                                    onClick={(e) => setCropNeeded(1)}
                                  />
                                  <Label for="crop-yes">Yes</Label>
                                </div>
                                <div className="d-flex crop-items">
                                  <Input
                                    type="radio"
                                    id="crop-no"
                                    name="crop-resize"
                                    value="0"
                                    checked={cropneeded == 0 && "checked"}
                                    onClick={(e) => setCropNeeded(0)}
                                  />
                                  <Label for="crop-no">No</Label>
                                </div>
                              </div>

                              <div>
                                <h6>Bleed Size</h6>
                                <div className="d-flex">
                                  <div className="bleed-size">
                                    <Label>Size</Label>
                                    <Input
                                      type="text"
                                      name="bleed-size"
                                      value={bleedsize}
                                      onChange={(event) =>
                                        handleSizeInputChange(event)
                                      }
                                    />
                                  </div>
                                  <div className="bleed-unit">
                                    <Label>Unit</Label>
                                    <select
                                      className="choose_temp"
                                      name="unitsize"
                                      onChange={(event) =>
                                        handleSizeInputChange(event)
                                      }
                                    >
                                      <option
                                        selected={
                                          bleedunit === "Inch" && "selected"
                                        }
                                        value="Inch"
                                      >
                                        Inch
                                      </option>
                                      <option
                                        selected={
                                          bleedunit === "PX" && "selected"
                                        }
                                        value="PX"
                                      >
                                        PX
                                      </option>
                                      <option
                                        selected={
                                          bleedunit === "CM" && "selected"
                                        }
                                        value="CM"
                                      >
                                        CM
                                      </option>
                                      <option
                                        selected={
                                          bleedunit === "MM" && "selected"
                                        }
                                        value="MM"
                                      >
                                        MM
                                      </option>
                                      <option
                                        selected={
                                          bleedunit === "FT" && "selected"
                                        }
                                        value="FT"
                                      >
                                        FT
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </div>

                          <div className="note_add_wrapper brand_note_add_wrapper websitelink">
                            <Card>
                              <h5>Website & Social Networks</h5>
                              <div className="d-flex flex-wrap flex-row-3">
                                <div className="social-items">
                                  <Label>Website</Label>
                                  <Input
                                    type="text"
                                    name="website"
                                    value={website}
                                    onChange={(event) => updateMedia(event)}
                                  />
                                </div>
                                <div className="social-items">
                                  <Label>Facebook</Label>
                                  <Input
                                    type="text"
                                    name="facebook"
                                    value={facebook}
                                    onChange={(event) =>
                                      updateMedia(event, "facebook")
                                    }
                                  />
                                </div>
                                <div className="social-items">
                                  <Label>Twitter</Label>
                                  <Input
                                    type="text"
                                    name="twitter"
                                    value={twitter}
                                    onChange={(event) =>
                                      updateMedia(event, "twitter")
                                    }
                                  />
                                </div>
                                <div className="social-items">
                                  <Label>LinkedIn</Label>
                                  <Input
                                    type="text"
                                    name="linkedin"
                                    value={linkedin}
                                    onChange={(event) =>
                                      updateMedia(event, "linkedin")
                                    }
                                  />
                                </div>
                                <div className="social-items">
                                  <Label>Instagram</Label>
                                  <Input
                                    type="text"
                                    name="instagram"
                                    value={instagram}
                                    onChange={(event) =>
                                      updateMedia(event, "instagram")
                                    }
                                  />
                                </div>
                                <div className="social-items">
                                  <Label>Pinterest</Label>
                                  <Input
                                    type="text"
                                    name="pinterest"
                                    value={pinterest}
                                    onChange={(event) =>
                                      updateMedia(event, "pinterest")
                                    }
                                  />
                                </div>
                                <div className="social-items">
                                  <Label>Other</Label>
                                  <Input
                                    type="text"
                                    name="othermedia"
                                    value={othermedia}
                                    onChange={(event) =>
                                      updateMedia(event, "othermedia")
                                    }
                                  />
                                </div>
                              </div>
                            </Card>
                          </div>
                        </>
                      )}
                      {AccessCheckBlock("add_edit_doc_files") &&
                        !dsmmmodule && (
                          <div className="note_add_wrapper brand_note_add_wrapper">
                            <Card className="d-flex">
                              {!dsmmmodule && (
                                <Button
                                  name="cancel"
                                  className="btn-grey cancel_btn"
                                  value="cancel"
                                  onClick={() => setDsmmModule(true)}
                                >
                                  Cancel
                                </Button>
                              )}
                              <Button
                                name="save"
                                className="btn-green save_btn notesave_btn"
                                value="save"
                                onClick={saveBrandInfoData}
                              >
                                Save
                              </Button>
                            </Card>
                          </div>
                        )}
                    </Col>
                  </>
                )}
                {(AccessCheckBlock("i_am_pm") || AccessCheckBlock("i_am_pl")) &&
                (
                <Col sm="12">
                <div className="note_add_wrapper brand_note_add_wrapper confirm-form">
                  <Card>
                    <h5>Best Design Settings</h5>
                    <Row>
                      <Col sm="12">
                        <div className="custom_radio">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleChange}
                            id="confirm"
                          />
                          <Label for="confirm">
                            I confirm to remove all files from the best designs related to this brand till date. 
                          </Label>
                        </div>
                        {isChecked && (
                          <div style={{ marginTop: "15px" }}>
                            <Button
                              onClick={resetBrandBestDesignsTriger}
                              className="btn-green save_btn notesave_btn verify-btn"
                            >
                              Submit
                            </Button>
                          </div>
                        )}
                      </Col>
                      {/* <Col sm="6">
                        {isVisible && (
                          <>
                            <ul className="tasklist-wrapper">
                              <li
                                onClick={() =>
                                  openInNewTab("https://codingbeautydev.com")
                                }
                                className="tasktitle-box"
                              >
                                <div>
                                  <span>Task Name</span>
                                  <h3>Clone Board Test</h3>
                                </div>
                                <button className="iconbutton">
                                  <MaterialIcon icon="north_east" size="15" />
                                </button>
                              </li>
                              <li
                                onClick={() =>
                                  openInNewTab("https://codingbeautydev.com")
                                }
                                className="tasktitle-box"
                              >
                                <div>
                                  <span>Task Name</span>
                                  <h3>Clone Board Test</h3>
                                </div>
                                <button className="iconbutton">
                                  <MaterialIcon icon="north_east" size="15" />
                                </button>
                              </li>
                              <li
                                onClick={() =>
                                  openInNewTab("https://codingbeautydev.com")
                                }
                                className="tasktitle-box"
                              >
                                <div>
                                  <span>Task Name</span>
                                  <h3>Clone Board Test</h3>
                                </div>
                                <button className="iconbutton">
                                  <MaterialIcon icon="north_east" size="15" />
                                </button>
                              </li>
                            </ul>
                            <Button
                              className="btn-green save_btn notesave_btn verify-btn"
                              style={{ marginTop: "15px" }}
                            >
                              Confirm
                            </Button>
                          </>
                        )}
                      </Col> */}
                    </Row>
                  </Card>
                </div>
              </Col>
              )}
              </LoadingWrapper>
            </div>
          </Row>
        </Container>
      </div>
      {showConfirmBox ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={closeDialogue}
            show={showConfirmBox}
            note="*This action will be logged, are you sure?"
            content={`You are about to remove all designs linked to this brand from best designs.`}
            handledesignupdate={resetBrandBestDesigns}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="updatebestdesigns"
          />
        ) : null}
    </Fragment>
  );
}
