import React, {
  useState,
  Component,
  Fragment,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { urls } from "../../services/UrlList";
import axios from "axios";
import { useForm } from "react-hook-form";
import MaterialIcon from "material-icons-react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useInfiniteQuery } from "@tanstack/react-query";
import Linkify from "linkifyjs/react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import UserPicker from "./UserPicker";

export default function AllActivities(props) {
  const [filterProgress, setFilterProgress] = useState(false);
  const [VisibleAlltems, setVisibleAllItems] = useState(true);
  const [VisibleItems, setVisibleItems] = useState([]);
  const observerElem = useRef(null);
  const [boardId, setBoarId] = useState(props.boardid);
  let borderType = " left border-right-activity ";
  let borderType2 = 1;
  const boardActivityLogs = async (pageParam) => {
    return await axios.post(urls.allBoardActivityLog, {
      pageparam: pageParam,
      postedby: props.userid,
    });
  };
  const {
    isSuccess,
    isLoading,
    isError,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["board-logs", props.boardid],
    ({ pageParam = 1 }) => boardActivityLogs(pageParam),
    {
      //refetchInterval: 180000,
      getNextPageParam: (lastPage, allPages) => {
        //console.log("Allpages", allPages);
        //console.log("lastPage", lastPage);
        const nextPage = allPages.length + 1;
        return lastPage.data.logs.length !== 0 ? nextPage : undefined;
      },
    }
  );

  useEffect(() => {
    let fetching = false;
    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement;
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
        fetching = true;
        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [fetchNextPage, hasNextPage]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [fetchNextPage, hasNextPage, handleObserver]);

  const stripslashes = (str) => {
    if (str && str.length > 0) {
      str = str.replace(/\\'/g, "'");
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, "\0");
      str = str.replace(/\\\\/g, "\\");
    }
    return str;
  };

  const options = {
    attributes: null,
    className: "linkified",
    defaultProtocol: "http",
    events: null,
    format: function (value, type) {
      return value;
    },
    formatHref: function (href, type) {
      return href;
    },
    ignoreTags: [],
    nl2br: false,
    tagName: "a",
    target: {
      url: "_blank",
    },
    validate: true,
  };
  const logType = (id) => {
    if (VisibleItems.length > 0) {
      if (VisibleItems.includes(id)) {
        return "All-logs comment-log";
      } else {
        return "hide-others";
      }
    } else {
      return "All-logs";
    }
  };

  const handleFilters = (e, id) => {
    let selectedFilters = [...VisibleItems];
    if (e.target.checked) {
      if (id == 0) {
        selectedFilters = [];
      } else {
        let findex = selectedFilters.findIndex((e) => e == 0);
        if (findex !== -1) selectedFilters.splice(findex, 1);
        if (id === 25 || id === 24) {
          selectedFilters.push(25);
          selectedFilters.push(24);
        } else if (id === 2) {
          selectedFilters.push(2);
        } else if (id === 20) {
          selectedFilters.push(20);
        } else if (id === 21 || id === 22 || id === 23) {
          selectedFilters.push(21);
          selectedFilters.push(22);
          selectedFilters.push(23);
        } else if (id === 18 || id === 19) {
          selectedFilters.push(19);
          selectedFilters.push(18);
        } else if (id === 16 || id === 17) {
          selectedFilters.push(16);
          selectedFilters.push(17);
        }
      }
    } else {
      if (id === 25 || id === 24) {
        let findex = selectedFilters.findIndex((e) => e == 25);
        if (findex !== -1) selectedFilters.splice(findex, 1);

        findex = selectedFilters.findIndex((e) => e == 24);
        if (findex !== -1) selectedFilters.splice(findex, 1);
      } else if (id === 2) {
        let findex = selectedFilters.findIndex((e) => e == 2);
        if (findex !== -1) selectedFilters.splice(findex, 1);
      } else if (id === 20) {
        let findex = selectedFilters.findIndex((e) => e == 20);
        if (findex !== -1) selectedFilters.splice(findex, 1);
      } else if (id === 21 || id === 22 || id === 23) {
        let findex = selectedFilters.findIndex((e) => e == 21);
        if (findex !== -1) selectedFilters.splice(findex, 1);

        findex = selectedFilters.findIndex((e) => e == 22);
        if (findex !== -1) selectedFilters.splice(findex, 1);

        findex = selectedFilters.findIndex((e) => e == 23);
        if (findex !== -1) selectedFilters.splice(findex, 1);
      } else if (id === 18 || id === 19) {
        let findex = selectedFilters.findIndex((e) => e == 18);
        if (findex !== -1) selectedFilters.splice(findex, 1);

        findex = selectedFilters.findIndex((e) => e == 19);
        if (findex !== -1) selectedFilters.splice(findex, 1);
      } else if (id === 16 || id === 17) {
        let findex = selectedFilters.findIndex((e) => e == 16);
        if (findex !== -1) selectedFilters.splice(findex, 1);

        findex = selectedFilters.findIndex((e) => e == 17);
        if (findex !== -1) selectedFilters.splice(findex, 1);
      }
    }
    setVisibleItems(selectedFilters);
    console.log(selectedFilters);
  };

  const onPeopleChange = (selected) => {
    if (selected.length > 0) {
      props.onpeoplechange(selected[0].id);
    }
  };

  return (
    <div className="Board-level-activities All-board-level-activities">
      <Row>
        <Col sm={12} lg={12}>
          <Card className="cl_helth_report all-acitivity-page">
            <h1 class="page-title text-center her_title pb-0 mt-0 mb-0">
              All Board Activities
            </h1>
            <div className="allactivity_Container">
              {!filterProgress && isSuccess && (
                <>
                  <div className="flex board-activtity-filter-types">
                    <div className="log-activity-filters d-flex">
                      <div class="form-group">
                        <Input
                          id="all-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 0)}
                          checked={VisibleItems.includes(0) && "checked"}
                        />
                        <label for="all-filter">All</label>
                      </div>
                      <div class="form-group">
                        <Input
                          id="access-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 2)}
                          checked={VisibleItems.includes(2) && "checked"}
                        />
                        <label for="access-filter">Access</label>
                      </div>
                      <div class="form-group">
                        <Input
                          id="comment-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 25)}
                          checked={VisibleItems.includes(25) && "checked"}
                        />
                        <label for="comment-filter">Comments</label>
                      </div>
                      <div class="form-group">
                        <Input
                          id="due-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 20)}
                          checked={VisibleItems.includes(20) && "checked"}
                        />
                        <label for="due-filter">Due Date</label>
                      </div>
                      <div class="form-group">
                        <Input
                          id="label-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 16)}
                          checked={VisibleItems.includes(16) && "checked"}
                        />
                        <label for="label-filter">Labels</label>
                      </div>
                      <div class="form-group">
                        <Input
                          id="priority-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 18)}
                          checked={VisibleItems.includes(18) && "checked"}
                        />
                        <label for="priority-filter">Priority</label>
                      </div>
                      <div class="form-group">
                        <Input
                          id="status-filter"
                          name="priority"
                          type="checkbox"
                          class="form-check-input"
                          onClick={(e) => handleFilters(e, 21)}
                          checked={VisibleItems.includes(21) && "checked"}
                        />
                        <label for="status-filter">Status</label>
                      </div>
                    </div>
                    <div>
                      <UserPicker
                        onPeopleChange={onPeopleChange}
                        selected={[{ id: "1", name: "Tibin" }]}
                        selecteduser={props.userid}
                      />
                    </div>
                  </div>
                </>
              )}

              {!filterProgress &&
                isSuccess &&
                data.pages.map((page) =>
                  page.data.logs.map((itemlog, index) => {
                    //(index == 0) ? borderType = ' right border-left-activity ' : borderType = ' right border-left-activity ';
                    index > 0 &&
                      (page.data.logs[index - 1].activity_date !=
                        page.data.logs[index].activity_date ||
                        page.data.logs[index - 1].project_id !=
                          page.data.logs[index].project_id) &&
                      (borderType == " left border-right-activity "
                        ? (borderType = " right border-left-activity ")
                        : (borderType = " left border-right-activity "));

                    return (
                      <>
                        {index == 0 && (
                          <div className="allactiv_header">
                            <span>
                              {itemlog.activity_date ==
                              moment().format("YYYY-MM-DD") ? (
                                "Today"
                              ) : (
                                <>{itemlog.activity_date_text}</>
                              )}
                            </span>
                          </div>
                        )}
                        {index > 0 &&
                          page.data.logs[index - 1].activity_date !=
                            page.data.logs[index].activity_date && (
                            <div className={`allactiv_header `}>
                              <span>{itemlog.activity_date_text}</span>
                            </div>
                          )}
                        <div
                          key={itemlog.id}
                          className={`latest-activity-wrap ${logType(
                            itemlog.activity_type
                          )} ${VisibleAlltems && "show-all-active"} `}
                        >
                          <div className={`inner-activity ${borderType} `}>
                            <h4 className="live_activity_project">
                              {(index === 0 ||
                                page.data.logs[index - 1].activity_date !=
                                  page.data.logs[index].activity_date ||
                                page.data.logs[index - 1].project_id !=
                                  page.data.logs[index].project_id) &&
                                itemlog.project_name}
                            </h4>
                            <div className={`d-flex-activinner-box`}>
                              <div className="activity_user_detail_project">
                                <span className="user_pic_activity">
                                  <img
                                    src={
                                      urls.storageUrl +
                                      "storage/profile/image/" +
                                      itemlog.activity_by +
                                      ".jpg"
                                    }
                                    alt="back"
                                  />
                                </span>
                                <div className="project_details">
                                  <Linkify tagName="p" options={options}>
                                    {ReactHtmlParser(
                                      stripslashes(itemlog.activity_title)
                                    )}
                                  </Linkify>
                                  <span className="full_time_details">
                                    <Linkify tagName="p" options={options}>
                                      {ReactHtmlParser(
                                        stripslashes(itemlog.activity_desc)
                                      )}
                                    </Linkify>
                                  </span>
                                </div>
                              </div>
                              <span className="time">
                                {itemlog.activity_time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                    {
                      borderType2 = 0;
                    }
                  })
                )}
            </div>
          </Card>
        </Col>
      </Row>
      <div className="activities-loader" ref={observerElem}>
        {isFetchingNextPage && hasNextPage
          ? "Loading..."
          : "No more logs to show here"}
      </div>
    </div>
  );
}
