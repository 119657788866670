import React, {useState, useEffect, Fragment} from "react";
import { urls } from "../../services/UrlList";

export default function PlList(props)
{
    const [PlLeads, setPlLeads] = useState([]);
    const [pmTeamLead, setPmTeamLead] = useState(0);

    useEffect(()=>{
        getPMTeamLeads();
    },[]);

    const getPMTeamLeads = ()=>
	{
		fetch(urls.getPMTeamLeads,
			{
				headers:{
					'Access-Control-Allow-origin':'*',
					'Content-Type':'application/json'
				}
			})
			.then(response => response.json())
			.then(response =>{
                setPlLeads(response[0].pmtls);
                console.log(response[0].pmtls);
			})
			.catch((error) =>
			{
				console.log(error);
			})
	}

   const  handleChange = ({ target }) => {
        setPmTeamLead(target.value);
        props.triggerthechange(target.value);
   };

    return(
            <>
              <div class="people date-col pl_list" style={{marginRight:'10px'}}>
                  <p class="filter-label">PL: </p>
                  <select name="pmTeamLead" className="form-control" 
                    onChange={handleChange} >
                        <option value="">Select</option>
                    {PlLeads.map((item, index)=>
                    {
                      return(
                        <option key={index} value={item.id} 
                        selected={pmTeamLead == item.id? 'selected':''}
                        >{item.name}</option>
                      )
                    })}
                  </select>
                  </div>
            </>
    )   
};