import React, {useState, useEffect, Component, Fragment} from 'react';
import MaterialIcon from "material-icons-react";

export default function ProjectCurrentStatusShort(props)
{
const [projectpacks, setProjectPacks] = useState('');
 
// useEffect(() => {
//   let packs ='';
//   if(props.graphics>0)
//   {
//     packs += props.graphics>1 ? props.graphics+"G" : 'G';
//   }
//   if(props.video>0)
//   {
//     if(packs!='')
//     {
//         packs +=',';
//     }
//     packs += props.video>1 ? props.video+"V" : 'V';
//   }
//   if(props.graphics_video>0)
//   {
//     if(packs!='')
//     {
//         packs +=',';
//     }
//     packs += props.graphics_video>1 ? props.graphics_video+"G+V" : 'G+V';
//   }
//   setProjectPacks(packs);
  
// },[]);

  return(
    <>
      <div className="packs_count d-flex align-items-center">
          {/* {projectpacks !='' && ' - ['+projectpacks+']'} */}
          {(props.graphics>0) &&
          (
             <div className="packs_count_inner">
                {props.graphics>0 &&
                (<>
                  {`G: ${props.graphics}`}
                </>)}
              </div>
          )}
          {(props.video>0) &&
          (
              <div className="packs_count_inner">
                {props.video>0 &&
                (<>
                {/* {props.video>1 ? props.video+"V" : "V"} */}
                {`V: ${props.video}`}
                </>)}
              </div>
          )}
          {(props.graphics_video>0) &&
          (
            <div className="packs_count_inner">
                {props.graphics_video>0 &&
                (<>
                {/* {props.graphics_video>1 ? props.graphics_video+"G+V" : "G+V"} */}
                {`G+V: ${props.graphics_video}`}
                </>)}
              </div>
          )}
          {(props.canva_graphics>0) &&
          (
            <div className="packs_count_inner">
                {props.canva_graphics>0 &&
                (<>
                {/* {props.canva_graphics>1 ? props.canva_graphics+"CG" : "CG"} */}
                {`CG: ${props.canva_graphics}`}
                </>)}
              </div>
          )}
          {(props.canva_plus>0) &&
          (
            <div className="packs_count_inner">
                {props.canva_plus>0 &&
                (<>
                {/* {props.canva_plus>1 ? props.canva_plus+"CP" : "CP"} */}
                {`CP: ${props.canva_plus}`}
                </>)}
              </div>
          )}
      </div>
  </>
  )
  }