import React, { useState, useEffect, Fragment } from 'react';
import Header from '../header/Header';
import './salarytable.css';
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table, CardTitle, InputGroup, Label } from 'reactstrap';
import MaterialIcon from "material-icons-react";
import CountryFilter from './countryFilter'
import SelectMonth from './selectMonth'
import { useHistory } from "react-router";
import {usePaymentUsersSoft, usePaymentUsersDeep} from "./useQueryHooks";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import SalaryTableItemSoft from './SalaryTableItemSoft';
import SalaryTableItemDeep from './SalaryTableItemDeep';
import ExcelReport from './ExcelReport';
import SalaryTableItemSoftPrefetch from './SalaryTableItemSoftPrefetch';

export default function SalaryTable(props) {
  const [singleActive, setSingleActive] = useState(false)
  const [activeStaff, setActiveStaff] = useState([]);
  const [activeStaffExport, setActiveStaffExport] = useState([]);
  const [activeStaffOriginal, setActiveStaffOriginal] = useState([]);
  const [opencount, setOpenCount] = useState(0);
  const [allActive, setAllActive] = useState(false)
  const [myId, setmyId] = useState(0);
  const [myPrivileges, setMyPrivileges] = useState([]);
  const [progressbar, setProgressBar] = useState(true);
  const [showMenu, setshowMenu] = useState(true);
  const [changeUpdate, setChangeUpdate] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [selectedMonth, setSelectedMonth]=useState('');
  const [selectedMonthValue, setSelectedMonthValue]=useState('');
  const [country, setCountry]=useState('');
  const [searchType, setSearchType]=useState('');
  const [dateError, setDateError]=useState(false);
  const [prefetched, setPrefetched] = useState(false);
  const [allVerified, setAllVerified] = useState(false);
  const [verifiedCount, setVerifiedCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [showExport, setShowExport] = useState(true);
  const history = useHistory();

  //const {isLoading:deepLoading, data: deepdata, refetch: deepSearch} = usePaymentUsersDeep(selectedMonth);
  const { isLoading: softLoading, isError, error, data: softdata, isFetching, refetch: softSearch} = usePaymentUsersSoft(selectedMonth);

 
  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const updateMonthSelected = (value)=>
  {
    let splitmonth = value.split('-');
    setSelectedMonthValue(splitmonth[1]);
  }

  useEffect(() => { 
    if(softdata && softdata.data.users.length>0)
    {
      setSearchType(1);
      setDateError(false);
      setActiveStaff(softdata.data.users);
      setActiveStaffOriginal(softdata.data.users);
      setPrefetched(softdata.data.prefetched === 1 ? true : false);
      handleVerificationChangeInitial(softdata.data.users);
      setShowExport(true);
    }else if(softdata && softdata.data.error === 1)
    {
      setDateError(true);
    }
  }, [softdata]);

  // useEffect(() => { 
  //   if(deepdata && deepdata.data.users.length>0)
  //   {
  //     setSearchType(2);
  //     setDateError(false);
  //     setActiveStaff(deepdata.data.users);
  //     setActiveStaffOriginal(deepdata.data.users);
  //   }else if(deepdata && deepdata.data.error === 1)
  //   {
  //     setDateError(true);
  //   }
  // }, [deepdata]);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
      setMyPrivileges(login.privileges);
      if (!login.privileges.includes('i_am_hradmin')) {
        history.push('/home');
			}
    }else
    {
      history.push('/home');
    }
  }
  

  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }
  function goToHome() {
    history.push({ pathname: "/home" });
  }
  
  const handleCountryChange = (countryval) =>
  {
    setCountry(countryval.value);
    let countyVal = countryval.value;
    if(countyVal === 'All')
    {
      setActiveStaff(activeStaffOriginal);
      setActiveStaffExport(activeStaffOriginal);
      setSelectAll(false);
      return;
    }
    setActiveStaff(activeStaffOriginal.filter(function(item){
      if(countyVal=== 'Others')
      {
        return item.addressCountryCode != 'ID' && item.addressCountryCode != 'IN' && item.addressCountryCode != 'LK';  
      }else
      {
        return item.addressCountryCode == countyVal;  
      }   
    }));
    setSelectAll(true);
    setActiveStaffExport(activeStaffOriginal.filter(function(item){
      if(countyVal=== 'Others')
      {
        return item.addressCountryCode != 'ID' && item.addressCountryCode != 'IN' && item.addressCountryCode != 'LK';  
      }else
      {
        return item.addressCountryCode == countyVal;  
      }   
    }));
  }

  const handleVerificationChange = () =>
  {
    let unverified = activeStaffOriginal.filter(function(item){
      return item.hr_verification == 0;    
    })
    unverified.length<=0 ? setAllVerified(true) : setAllVerified(false);
    verifyCountChecker();
  }

  const verifyCountChecker =() =>
  {
    let verified = activeStaffOriginal.filter(function(item){
      return item.hr_verification == 1;    
    })
    setVerifiedCount(verified.length);
  }

  const handleVerificationChangeInitial = (data) =>
  {
    let unverified = data.filter(function(item){
      return item.hr_verification == 0;    
    })
    unverified.length<=0 ? setAllVerified(true) : setAllVerified(false);
    let verified = data.filter(function(item){
      return item.hr_verification == 1;    
    })
    setVerifiedCount(verified.length);
  }

  const handleUnverifiedChange = (e) =>
  {
    if(e.target.checked)
    {
      setActiveStaff(activeStaffOriginal.filter(function(item){
        return item.hr_verification == 0;    
      }));
    }else
    {
      setActiveStaff(activeStaffOriginal);
    }
  }

  const clearData = () =>
  {
    setActiveStaff([]);
    setActiveStaffOriginal([]);
    setCountry('All');
    softSearch();
  }

  const changeSelectStatus = ()=>
  {
    setSelectAll(!selectAll);
  }

  const updateValues=()=>
  {
    setShowExport(false);
    softSearch();
  }

  const handleCheckboxChange = (id, checkval) => 
  {
    let index = activeStaffExport.findIndex((staff) => staff.id === id);
    let newObject = [];
      if (index !== -1) {
        newObject =  [...activeStaffExport.slice(0, index), ...activeStaffExport.slice(index + 1)];
      } else {
        newObject =  [...activeStaffExport, activeStaff.find((staff) => staff.id === id)];
      }
      setActiveStaffExport(newObject);
  };

  return (
    <div className="fullwidth_mobilemenu">
      <Container fluid className=''>
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props} className="tick-timer-header"
              quickactionblock={false} />
          </div>
          {/* <div className="SideNavbar">
                <SideMenu activeitem="admin" onClick={toggleSidebar} />
          </div>  */}
         
          <div className="themeContentBody themefullwidthContentBoday">
            <Row>
              <Col xs={12} md={12}>
                <Card className="themeCard log_timer_card">
                  <div className="with_filter">
                    <div className="d-flex align-items-center frist_div">
                      <CardTitle tag="h1" className="themeCard_title">Salary Calculator</CardTitle>
                      <div className="filter_salary_box1">
                        <div className="d-flex filter_full">
                          <SelectMonth selectedmonth={(value)=>{setSelectedMonth(value); updateMonthSelected(value); }}  />
                          <Button className="btn btn-green export_btn" onClick={()=> {clearData();}}>Search</Button>
                          {/* <Button className="btn btn-green" onClick={deepSearch}>Deep Search</Button> */}
                        </div>
                      </div>
                    </div>
                    {(activeStaffExport && activeStaffExport.length>0 && showExport) &&
                    (
                      <div className="d-flex">
                        {myPrivileges.includes('salary_data_export') &&
                        (
                          <ExcelReport downloadData={activeStaffExport} selectedcountry={country} />
                        )}
                       <div className='d-flex justify-content-center verification-counter ml-3 mt-0 align-items-center'>{`${verifiedCount} / ${activeStaffOriginal.length} `} Verified </div>
                      </div>
                    )}
                  </div>
                  <div className="checkbox_filter d-flex">
                       <div>
                        <CountryFilter setCountry={handleCountryChange} />
                        </div>
                        <div>
                          <InputGroup className="non-payable-block payable">
                            <Input id="payable" type="checkbox" value="late" />
                            <Label for="payable" >Payable</Label>
                          </InputGroup>
                        </div>
                        <div>
                          <InputGroup className="non-payable-block new_joinee">
                            <Input id="new-Joinee" type="checkbox" value="late" />
                            <Label for="new-Joinee" >New Joinee</Label>
                          </InputGroup>
                        </div>
                        <div>
                          <InputGroup className="non-payable-block nopay">
                            <Input id="non-payable" type="checkbox" value="late" />
                            <Label for="non-payable" >Non Payable / Loan Pending</Label>
                          </InputGroup>
                        </div>
                        <div>
                          <InputGroup className="non-payable-block still_paying">
                            <Input id="still-paying" type="checkbox" value="late" />
                            <Label for="still-paying" >Leave Paying</Label>
                          </InputGroup>
                        </div>
                        <div>
                          <InputGroup className="non-payable-block greater-pay">
                            <Input id="greater-pay" type="checkbox" value="late" />
                            <Label for="greater-pay" >{'> INR 100000 or $1000'}</Label>
                          </InputGroup>
                        </div>
                        <div className="ml-auto ">
                          <InputGroup className="non-payable-block">
                            <Input id="still-paying" type="checkbox" value="late" onClick={handleUnverifiedChange} />
                            <Label for="still-paying" >Unverified</Label>
                          </InputGroup>
                        </div>
                        
                     
                  </div>
                    
                  <Table className="theme_table salarytable">
                    <thead style={{position: 'sticky',top: '81px',zIndex: '9'}}>
                      <tr>
                        <th>
                          <span className='d-flex'>
                           <span className='salary-all-check'>
                            {country!='' && country!='All' &&
                            (
                              <input type="checkbox" checked={selectAll} onClick={changeSelectStatus} />
                            )}
                            </span>
                            <span>Name</span>
                          </span>
                        </th>
                        <th>Prev.Month</th>
                        <th>This Month</th>
                        <th>Loan</th>
                        <th>Advance</th>
                        <th>LOP</th>
                        <th>HRA</th>
                        <th>Others</th>
                        <th>Calculated</th>
                        <th>HR Finalized</th>
                        <th>HR Verify</th>
                      </tr>
                    </thead>
                    <LoadingWrapper isLoading={isFetching} />
                    {dateError &&
                    (
                      <div className='search-not-allowed'>You are not allowed to perform new search today.</div>
                    )}
                    <tbody>
                      {!softLoading && searchType === 1 && activeStaff && activeStaff.length>0 && activeStaff.map((row, index) => (
                        <>
                        {prefetched ? 
                        (
                          <SalaryTableItemSoftPrefetch handleCheckboxChange={handleCheckboxChange} updateValues={updateValues} checkall={selectAll} selectedcountry={country} handleverification={handleVerificationChange} key={row.user_id} user={row} monthselected={selectedMonthValue} />
                        ):
                        (
                          <SalaryTableItemSoft updateValues={updateValues} checkall={selectAll} handleverification={handleVerificationChange} key={row.user_id} user={row} monthselected={selectedMonthValue} />
                        )}
                          
                        </>
                      ))}
                       {/* {!deepLoading && searchType === 2 && activeStaff && activeStaff.length>0 && activeStaff.map((row, index) => (
                        <SalaryTableItemDeep key={row.user_id} user={row} monthselected={selectedMonth} />
                      ))} */}
                    </tbody>
                  </Table>
                </Card>

              </Col>
            </Row>
          </div>
        </Row>

      </Container>
    </div>
  )

}


