import React, { useState, useEffect, useRef } from "react";
import Header from '../../header/Header';
import '../list-head.css';
import '../listcard.css';
import {urls} from "../../../services/UrlList";
import LoadingBar from '../../common/LoadingBar';
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper"
import LoadingBox from '../../LoadingBox/LoadingBox'
import {motion} from 'framer-motion'
import toast, { Toaster } from 'react-hot-toast';
import { useGetBoardCollectionMaster, useUpdateBoardCollection } from './useQueryCollections';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function BoardCollections(props) {
  const [opencount, setOpenCount] = useState(0);
  const [progressbar, setProgressBar] = useState(true);

  const typeaheadRefs = useRef({});

  const {
    isLoading,
    data: collectionData,
  } = useGetBoardCollectionMaster();

  const handleChange = (id, selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      console.log(selectedOptions[0].id);
      const data2 = {
        id: selectedOptions[0].id,
        user_id: id,
        pm: 1
      };
      updateCollection(data2);
    }
  }

  const { isLoading: updateLoading, mutate: updateCollection } = useUpdateBoardCollection();

  // const handleInputChange = (input, e) => {
  //   typeaheadRef.current.clear();
  //   handleChange(input.id, []);
  // }

  
  const handleInputChange = (input, e) => {
    const modifiedCollectionData = { ...collectionData };
    if (modifiedCollectionData.data.collections.length > 0) {
      modifiedCollectionData.data.collections[0].user_id = 0;
    }
    findSelectedCollectionPM(0);
  };

  const findSelectedCollectionPM=(id)=>
  {
    if(id>0)
    {
    return collectionData.data.collections.filter((item) => item['user_id'] === id && item['pm'] === 1);
    }else
    {
      return {};
    }
  }

  const findSelectedCollectionPL=(id)=>
  {
    return collectionData.data.collections.filter((item) => item['user_id'] === id && item['pl'] === 1);
  }

  const storeTypeaheadRef = (id, ref) => {
    typeaheadRefs.current[id] = ref;
  };

  return (
      <>
      <div className="fullwidth_mobilemenu">
    <Container fluid className="top-bar-inactive bc_module_container board_collections">
            <Toaster position="top-right" containerClassName="notifier"
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff',
                    },}}
              />
            <div className="theme_header">
            <Header
                ncount={opencount}
                {...props}  className="tick-timer-header" 
                quickactionblock={false} />
              
            </div>
            <div className="themeContentBody themefullwidthContentBoday">
                <div className="bc_Board_wrapper">
                  <h2 class="section_title d-flex align-items-center justify-content-center">Collections</h2>
                </div>
                
                  <div className="note_add_wrapper brand_note_add_wrapper">
                    {collectionData?.data.pms.length>0 && 
                    (
                      <div className="health_rpot_header pm_ratio_header">
                        <Row className="inner-row-wraper">
                            <Col sm="6"><span className="report-tab-head">PM</span></Col> 
                            <Col sm="6"><span className="report-tab-head">Collection</span></Col> 
                        </Row>
                      </div>
                    )}
                    {collectionData?.data.pms.map((item, index)=>
                    {
                      return(
                        <Row key={item.key} className="inner-row-wraper-for-list">
                              <Col sm="6" className="font-weight-bold">{item.name}</Col>  
                              <Col sm="6">
                              <Typeahead
                                ref={(ref) => storeTypeaheadRef(item.id, ref)}
                                id={`pm-picker-${item.id}`}
                                labelKey="label"
                                onInputChange={handleInputChange}
                                onChange={(e) => handleChange(item.id, e)}
                                options={collectionData.data.collections}
                                placeholder="Choose Collection..."
                                selected={findSelectedCollectionPM(item.id)}
                              />
                              </Col>  
                          </Row>
                      )
                    })}
                    {collectionData?.data.pls.length>0 && 
                    (
                      <div className="health_rpot_header pm_ratio_header">
                        <Row className="inner-row-wraper">
                            <Col sm="6"><span className="report-tab-head">PL</span></Col> 
                            <Col sm="6"><span className="report-tab-head">Collection</span></Col> 
                        </Row>
                      </div>
                    )}
                    {collectionData?.data.pls.map((item, index)=>
                    {
                      return(
                        <Row key={item.key} className="inner-row-wraper-for-list">
                              <Col sm="6" className="font-weight-bold">{item.name}</Col>  
                              <Col sm="6">
                                <Typeahead
                                id="pm-picker"
                                labelKey="label"
                                onInputChange={handleInputChange}
                                onChange={handleChange}
                                options={collectionData.data.collections}
                                placeholder="Choose Collection..."
                                selected={findSelectedCollectionPL(item.id)}
                                />
                              </Col>  
                          </Row>
                      )
                    })}
                  </div>
                
            </div>
    </Container>
    </div>
</>
    )
  }

