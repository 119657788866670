import React, { useState, useEffect } from "react";
import "./basecampmodule.css";
import { Button, Input, Label, UncontrolledTooltip } from "reactstrap";
import { AccessCheckBlock } from "./AccessCheck";
import ReactHtmlParser from "react-html-parser";
import Linkify from "linkifyjs/react";
import ConfirmAction from "../admincomment/ConfirmAction";
import moment from "moment";
import MaterialIcon from "material-icons-react";
import { useGetTaskClientRating, useUpdateBestDesigns } from "./useBoardHooks";
import toast, { Toaster } from "react-hot-toast";
import BestDesignsLog from "./BestDesignsLog";

export default function BestDesignsApproval(props) {
  const [manualBest, setManualBest] = useState("");
  const [showConfirmBox, setShowConfirmBox]= useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const {
    isLoading,
    isError,
    error,
    isFetching,
    data: taskRating,
    refetch: refreshClientRating,
  } = useGetTaskClientRating(props.taskid);

  const closeDialogue=()=>
  {
    setShowConfirmBox(false);
    refreshClientRating();
  }

  const handleAction = () =>
  {
    setShowConfirmBox(true);
  }
  

  const updateBestDesigns = async () => {
    const data1 = {
      status: manualBest,
      taskid: props.taskid,
    };
    bestDesignUpdate(data1);
    setShowConfirmBox(false);
  };
  const { mutate: bestDesignUpdate, data: bestDesignData } =
    useUpdateBestDesigns();

  useEffect(() => {
    if (taskRating?.data.rating[0].star_rated_manually) {
      setManualBest(taskRating?.data.rating[0].star_rated_manually);
    }
  }, [taskRating]);

  if (isLoading || isFetching) {
    return <div>Loading....</div>;
  } else
    return (
      <div className="best-designs-wrap">
        <>
          <h6 className="canva-access-head">Best Designs</h6>
          <div className={`d-flex canva-fetch-block`}>
            <div>
              <span>
                Client Rating: {taskRating?.data.rating[0].star_rated}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="best-designs-sub-head">Manual Override</h5>
            {(taskRating?.data.rating[0].star_rated_manually === 1 || taskRating?.data.rating[0].star_rated_manually === 2) && (
              <span className="tick-iconbox">
                <MaterialIcon icon="check" />
              </span>
            )}
          </div>

          <div className="reminder-options custom_radio  fileformat_card mb-2x">
            <span className="d-flex">
              <Input
                id="add-best-designs"
                type="radio"
                name="best-designs"
                checked={manualBest === 1 && "checked"}
                onClick={() => setManualBest(1)}
              />
              <Label for="add-best-designs">
                {taskRating?.data.rating[0].star_rated_manually === 1
                  ? "Added"
                  : "Add"}{" "}
                to Best Designs
              </Label>
            </span>
            <span className="d-flex">
              <Input
                id="remove-best-designs"
                type="radio"
                name="best-designs"
                checked={manualBest === 2 && "checked"}
                onClick={() => setManualBest(2)}
              />
              <Label for="remove-best-designs">
              {taskRating?.data.rating[0].star_rated_manually === 2
                ? "Removed"
                : "Remove"}{" "}
                from Best Designs</Label>
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <Button onClick={handleAction}>Update</Button>
            <span className="best-designs-logs" onClick={()=>setShowLogs(!showLogs)}> { showLogs ? 'Hide Logs' : 'Logs' }</span>
          </div>
        </>
        {showLogs && <BestDesignsLog  taskid={props.taskid} /> }
        {showConfirmBox ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={closeDialogue}
            show={showConfirmBox}
            note="*This action will be logged, are you sure?"
            content={`You are about to ${manualBest===1 ? 'add this designs to best designs.' : 'remove this designs from best designs.'}`}
            handledesignupdate={updateBestDesigns}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="updatebestdesigns"
          />
        ) : null}
       
      </div>
    );
}
