import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import Homebackicon from '../../images/bc_homeicon.svg'
import PeopleTags from "./PeopleTag";
import KFileUpload from './kFileUpload';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import toast, { Toaster } from 'react-hot-toast';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import MaterialIcon from "material-icons-react";
import ProjectCurrentStatus from './ProjectCurrentStatus';
import ImageUploader from "quill-image-uploader";
import LightboxPop from "./LightboxPop";

import NewConnectionWith360 from './NewConnectionWith360';


Quill.register("modules/imageUploader", ImageUploader);
const notifySuccess = () => toast('Card created successfully.');
const notifyDraft = () => toast('Card has been saved as draft.');
const notifyError = () => toast('Something went wrong, please try again.');

const toolbarOptions = [
  ['bold', 'italic', 'underline'],        
  [{ 'color': [] }, { 'background': [] }],           
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
  ['link','image'],
  ['clean']    
];



export default class NewTask extends Component {
  fileObj = []; 
  fileArray = [];
  fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      saveupdateProgress:false,
      taskName:'',
      activeClass:'',
      dueDate:'',
      errors: [],
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      myTimezone:'',
      showProgress: true,
      taskAttachmentsChange:false,
      textInstructionFilesChange:false,
      imageryFilesChange:false,
      referenceFilesChange:false,
      approvedWorkFilesChange:false,
      pastDueError:false,
      selectedTemplate:'',
      availableAssignees:[],
      availableNotifiers:[],
      labels:[],
      tassignees:[],
      tnotifiers:[],
      selectedLabels:[],
      tags: [],
      setTags:[],
      templateFields:[],
      brandingFiles:[],
      textInstructionFiles:[],
      imageryFiles:[],
      referenceFiles:[],
      approvedWorkFiles:[],
      taskAttachments:[],
      accessDenied:'',
      projectDetails:[],
      progressBar:true,
      showNetworks:false,
      outputFileFormats:[],
      softwarestoUse:[],
      Style_Colors:[],
      addonColors:[],
      Guidelines:'',
      brandPredefinedColors:[],
      ColorInstructions:'',
      FontInstructions:'',
      showBrandColors:false,
      showAddonColors:false,
      showColorGuides:false,
      addonColors:[],
      draftAssigneWarning:false,
      animate: false,
      fileFormatOther:'',
      fileFormatOtherShow:false,
      softwareOther:'',
      outsoftInstructions:'',
      softwareOtherShow:false,
      resizeCrop:'',
      bleedSize:'',
      bleedUnit:'Inch',
      sizeFields:[{
          width:'',
          height:'',
          unit:'',
          note:'',
      }],
      lastSelectedUnit:'',
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true,
      },
      taskAttachmentTextOptions:1,
      taskAttachmentTextArray:[],

      textInstructionFilesTextOptions:1,
      textInstructionFilesTextArray:[],

      imageryFilesTextOptions:1,
      imageryFilesTextArray:[],

      referenceFilesTextOptions:1,
      referenceFilesTextArray:[],

      approvedWorkFilesTextOptions:1,
      approvedWorkFilesTextArray:[],
      cardId360:'',
      cardData360:'',
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.reactTags = React.createRef();
  }

  modules = {
    toolbar: toolbarOptions,
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          data.append('filename', file.name);
          data.append('filesize', file.size);
          data.append('filetype', file.type);
          data.append('folder_path', 'editor')
          data.append('user_id', 1);
          axios.post(urls.uploadProjectMediaEditor, data, { 
          })
          .then(res => {
            console.log(res);
            resolve(res.data.file_name);
          })
          .catch(error => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      }
    }
  };

  minimodules = {
    toolbar:  [
      ['bold', 'italic', 'underline'], 
      [{ 'color': [] }, { 'background': [] }],           
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
      ['link']    
    ]
  };

  handleClick(e) {
    // modify the state, this will automatically recall render() below.
    this.setState((prevState) => {
      return { animate: !prevState.animate }
    });
  }

  componentDidMount = () => {
    document.title = "KIMPHUB - Boards";
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      //_this.renderContentViewOwnership();
      _this.getTaskAssets();
    }, 500);
  };

  componentWillUnmount() { 
    this.checkUserPrivilege(); 
    this.getTaskAssets();
  }


  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));             
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };

  renderContentViewOwnership = () =>
  { 
    if (this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets') ) {
      if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'full'});
      }else if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'partial'});
      }
    }else
    {
      this.props.history.push("/home");
    }
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('am_admin')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  getTaskAssets = ()=>
  {
      const data = {
        project_id: this.props.match.params.bid
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.taskAssets, data, {
          headers: headers
        })
      .then((response) => {
        this.setState({
          accessDenied: response.data.success === 10 ? true : false,
          projectDetails: response.data.success !== 10 && response.data.project_details[0],
          availableAssignees: response.data.assignees,
          availableNotifiers: response.data.notifiers,
          availableBrands: response.data.brands,
          designTypes: response.data.design_types,
          labels: response.data.labels,
          progressBar:false
        })
      })
      .catch((error) => {
        this.setState({
         progressBar:false
        })
      })
  }

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 
  /*handleChangeEditor(value) {
    this.setState({ ticketDetails: value })
  }*/

  handleChangeEditor = (e) => {
    //this.setState({ [target.id]: target.value });
    console.log(e.target.id);
    this.setState({name : e});
  };


  


onTdateChange = (date) => 
  {
    this.setState({ ticketDate: date });
  }

  showAssigneeNames = () =>
  {
    var tempassginees = '';
    let temp = this.state.tassignees.map(Number);
    this.state.availableAssignees.forEach(assignee => {
      if(temp.includes(assignee.id))
      {
        tempassginees += assignee.name +', ';
      }
    });
    this.setState({
      tassigneesnames: tempassginees.slice(0, -2)
    })
  }

  

  onDelete (i) {
    const tassignees = this.state.tassignees.slice(0)
    tassignees.splice(i, 1)
    this.setState({ tassignees })
  }
 
  onAddition (tag) {
    const tassignees = [].concat(this.state.tassignees, tag)
    this.setState({ tassignees })
  }

  onDeleteNotifiers (i) {
    const tnotifiers = this.state.tnotifiers.slice(0)
    tnotifiers.splice(i, 1)
    this.setState({ tnotifiers })
  }
 
  onAdditionNotifiers (tag) {
    const tnotifiers = [].concat(this.state.tnotifiers, tag)
    this.setState({ tnotifiers })
  }


  onDeleteTag (i) {
    const setTags = this.state.setTags.slice(0)
    setTags.splice(i, 1)
    this.setState({ setTags })
  }
 
  onAdditionTag (tag) {
    const setTags = [].concat(this.state.setTags, tag)
    this.setState({ setTags })
  }

  setAssignees = (tags) =>
  {
    this.setState({tassignees: tags })
  }

  setNotifiers = (tags) =>
  {
    this.setState({tnotifiers: tags })
  }

  setLabels = (tags) =>
  {
    this.setState({selectedLabels: tags })
  }
  removePreviewImg = (url) =>
  {
    var index = this.fileArray.indexOf(url); // 1
    this.fileArray.splice(index, 1);
    this.fileNamesArray.splice(index, 1);
    this.setState({imgagePreviews: this.fileArray, files: this.fileNamesArray});
  }

  onPeopleChange = (userid) =>
  {        
   this.setState({responsibleBy: userid});
  }

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("YYYY-MM-DD");
    this.setState({incidentDate:momentString});
  };

  setReminderime = (e)=>
  {
    console.log(e);
    this.setState({
      reminderTime:e
    })
  }

  setDueDate = (e)=>
  {
    this.setState({
      dueDate:e
    })
  }

  setReminderTimeOptions = (e)=>
  {
    if(e.target.value>0)
    {
      this.setState({
        reminderOptions: e.target.value,
        reminderTime:new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }

 
  handleOnChange(key, e) {
    console.log(e.target.value);
    let post = this.state.post;
    post[key] = e.target.value; // key variable can be a string
    console.log(this.state);
  }

  handleTemplateChange = async(e) =>
  {
    if(e.target.value<=0)
    {
      this.setState({selectedTemplate:e.target.value, templateFields:[]});  
      return;
    }
    this.setState({
      selectedTemplate:e.target.value
    });  
    const data = {
        design_type_id: e.target.value
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.taskTemplateFields, data, {
          headers: headers
        })
        .then((response) => {
          this.setState({
            templateFields: response.data.fields
          })
        })
        .catch((error) => {
          
        })
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleBrandChange = ({ target }) => {
    this.setState({ brand: target.value });
    const data = {
      project_id: this.props.match.params.bid,
      user_id: this.state.myId,
      brand_id: target.value
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getBrandInfoTask, data, {
        headers: headers
      })
    .then((response) => {
      if(response.data.success !=10)
      {
        console.log("hello");
        this.setState({
        showBrandColors: false,
        showAddonColors: false,
        showColorGuides: false,
        Guidelines: response.data.info[0].branding_instructions,
        brandPredefinedColors: JSON.parse(response.data.info[0].colors),
        ColorInstructionsTemp: response.data.info[0].color_instructions,
        FontInstructionsTemp: response.data.info[0].font_instructions,
        ColorInstructions: '',
        FontInstructions: '',
        resizeCrop: response.data.info[0].resize_crop,
        bleedSize: response.data.info[0].bleed_size,
        bleedUnit: response.data.info[0].bleed_unit,
        Website: response.data.info[0].Website,
        Facebook:response.data.info[0].Facebook,
        Twitter: response.data.info[0].Twitter,
        LinkedIn: response.data.info[0].Linkedin,
        Instagram: response.data.info[0].Instagram,
        Pinterest: response.data.info[0].Pinterest,
        OtherMedia: response.data.info[0].OtherMedia
      })
      }
    })
    .catch((error) => {
      this.setState({
       progressBar:false
      })
    })
  };


  handleChangeUrls = ({ target }) => {
    let url = target.value.replace(/(^\w+:|^)\/\//, '');
    this.setState({ [target.name]: url });
  };

  storeFileNames = async(arrayname, item)=>
  {
      if(arrayname === 'brandingFiles')  
      { 
        let temp =[...this.state.brandingFiles];
        await this.setState({brandingFiles:[]});
        temp.push(item);
        await this.setState({brandingFiles: temp});
      }else if(arrayname === 'textInstructionFiles')  
      {
        let temp =[...this.state.textInstructionFiles];
        let tempText = [...this.state.textInstructionFilesTextArray];
        await this.setState({textInstructionFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({textInstructionFiles: temp, 
          textInstructionFilesTextArray: tempText});
      
      }else if(arrayname === 'imageryFiles')  
      {
        let temp =[...this.state.imageryFiles];
        let tempText = [...this.state.imageryFilesTextArray];
        await this.setState({imageryFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({imageryFiles: temp, imageryFilesTextArray: tempText});
      }else if(arrayname === 'referenceFiles')  
      {
        let temp =[...this.state.referenceFiles];
        let tempText = [...this.state.referenceFilesTextArray];
        await this.setState({referenceFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({referenceFiles: temp, referenceFilesTextArray: tempText});
      }else if(arrayname === 'approvedWorkFiles')  
      {
        let temp =[...this.state.approvedWorkFiles];
        let tempText = [...this.state.approvedWorkFilesTextArray];
        await this.setState({approvedWorkFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({approvedWorkFiles: temp, approvedWorkFilesTextArray: tempText});
      }
  }


  storeTaskFileNames = async(arrayname, item)=>
  {
    let temp =[...this.state.taskAttachments];
    let tempText = [...this.state.taskAttachmentTextArray];
    await this.setState({taskAttachments:[]});
    temp.push(item);
    tempText.push('');
    await this.setState({taskAttachments: temp, taskAttachmentTextArray: tempText});
  }
  
  deletefiles = (filename, arrayname) =>
  {
    if(arrayname == 'taskAttachments')
    {
      this.setState({taskAttachmentsChange:true});
      var assetupdate = [...this.state.taskAttachments];
      var assetTextupdate = [...this.state.taskAttachmentTextArray];
      this.setState({taskAttachments:[]});
      var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
      assetupdate.splice(itemkey, 1);
      assetTextupdate.splice(itemkey, 1);
        const _this = this;
        setTimeout(()=>
        {
          _this.setState({taskAttachments: assetupdate, taskAttachmentTextArray:assetTextupdate, taskAttachmentsChange:false});
        }, 500);
      }else if(arrayname == 'textInstructionFiles')
      {
        this.setState({textInstructionFilesChange:true});
        var assetupdate = [...this.state.textInstructionFiles];
        var assetTextupdate = [...this.state.textInstructionFilesTextArray];
        this.setState({textInstructionFiles:[]});
        var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
        assetupdate.splice(itemkey, 1);
        assetTextupdate.splice(itemkey, 1);
          const _this = this;
          setTimeout(()=>
          {
            _this.setState({textInstructionFiles: assetupdate, textInstructionFilesTextArray:assetTextupdate, textInstructionFilesChange:false});
          }, 500);
        }else if(arrayname == 'imageryFiles')
        {
          this.setState({imageryFilesChange:true});
          var assetupdate = [...this.state.imageryFiles];
          var assetTextupdate = [...this.state.imageryFilesTextArray];
          this.setState({imageryFiles:[]});
          var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
          assetupdate.splice(itemkey, 1);
          assetTextupdate.splice(itemkey, 1);
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({imageryFiles: assetupdate,
                imageryFilesTextArray:assetTextupdate, 
                imageryFilesChange:false});
            }, 500);
        }else if(arrayname == 'referenceFiles')
        {
          this.setState({referenceFilesChange:true});
          var assetupdate = [...this.state.referenceFiles];
          var assetTextupdate = [...this.state.referenceFilesTextArray];
          this.setState({referenceFiles:[]});
          var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
          assetupdate.splice(itemkey, 1);
          assetTextupdate.splice(itemkey, 1);
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({referenceFiles: assetupdate,
                referenceFilesTextArray: assetTextupdate, 
                referenceFilesChange:false});
            }, 500);
        }else if(arrayname == 'approvedWorkFiles')
        {
          this.setState({approvedWorkFilesChange:true});
          var assetupdate = [...this.state.approvedWorkFiles];
          var assetTextupdate = [...this.state.approvedWorkFilesTextArray];
          this.setState({approvedWorkFiles:[]});
          var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
          assetupdate.splice(itemkey, 1);
          assetTextupdate.splice(itemkey, 1);
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({approvedWorkFiles: assetupdate, 
                approvedWorkFilesTextArray: assetTextupdate,
                approvedWorkFilesChange:false});
            }, 500);
        } 
  }

  updateFileText = (arrayname, values) =>
  {
    if(arrayname == 'taskAttachments')
    {
      this.setState({taskAttachmentTextArray:values});
    }else  if(arrayname == 'textInstructionFiles')
    {
      this.setState({textInstructionFilesTextArray:values});
    }else  if(arrayname == 'imageryFiles')
    {
      this.setState({imageryFilesTextArray:values});
    }else  if(arrayname == 'referenceFiles')
    {
      this.setState({referenceFilesTextArray:values});
    }else  if(arrayname == 'approvedWorkFiles')
    {
      this.setState({approvedWorkFilesTextArray:values});
    }
  }


  updateCardInfo = (cardid, cardinfo)=>
  {
    this.setState({cardId360: cardid,cardData360:JSON.stringify(cardinfo)});
  }

  updateCardInfoCancel = ()=>
  {
    this.setState({cardId360:'',cardData360:''});
  }


  saveNewTask = async(type)=>
  {
    let temp = [];
    this.setState({errors:[], draftAssigneWarning:false});
    if(type == 2 && this.state.tassignees.length>0)
    {
        this.setState({draftAssigneWarning:true});
        return false;
    }
    else if(this.state.taskName == '')
    {
      temp.push('taskName');
      await this.setState({errors: temp});
      return false;
    }else if(this.state.dueDate=='' && type === 1 && this.props.match.params.bid !== 'gIiAZmVlZoCIgA==')
    {
      temp.push('dueDate');
      await this.setState({errors: temp});
      return false;
    }else if(this.props.match.params.bid !== 'gIiAZmVlZoCIgA==')
    {
      let todayDate = moment();
      if (todayDate.isAfter(this.state.dueDate)) {
        temp.push('dueDate');
        await this.setState({errors: temp});
        return false;
      }
    }
    else if(this.state.projectDetails.onboard_360 === 1 && this.state.cardId360 =='')
    {
      temp.push('link360');
      await this.setState({errors: temp});
      return false;
    }
  
    this.setState({errors: [], saveupdateProgress:true});
    const data = {
      project_name: this.state.projectDetails.board_name,
      savetype: type,
      project_id: this.props.match.params.bid,
      creator: this.state.myId,
      mname: this.state.myName,
      task_name: this.state.taskName,
      template_id: this.state.selectedTemplate,
      assignees:this.state.tassignees,
      notifiers: this.state.tnotifiers,
      labels: this.state.selectedLabels,
      priority: this.state.priority,
      brand_id: this.state.brand,
      description: this.state.description,
      task_attachments: this.state.taskAttachments,
      dueDate:this.state.dueDate,
      reminder: this.state.reminderTime,
      task_type: this.state.TaskType,
      task_360_id: this.state.cardId360,
      task_360_data: this.state.cardData360,
      software_to_use: this.state.softwarestoUse,
      fieldDataText: {
        Size: this.state.sizeFields,
        FileSizeLimit: this.state.FileSizeLimit,
        Purpose: this.state.Purpose,
        Guidelines: (this.state.Guidelines && this.state.Guidelines.length<=0) ? ' ' : this.state.Guidelines,
        Website: this.state.Website && this.state.Website.replace(/(^\w+:|^)\/\//, ''),
        Facebook:this.state.Facebook && this.state.Facebook.replace(/(^\w+:|^)\/\//, ''),
        Twitter: this.state.Twitter && this.state.Twitter.replace(/(^\w+:|^)\/\//, ''),
        LinkedIn: this.state.LinkedIn && this.state.LinkedIn.replace(/(^\w+:|^)\/\//, ''),
        Instagram: this.state.Instagram && this.state.Instagram.replace(/(^\w+:|^)\/\//, ''),
        Pinterest: this.state.Pinterest && this.state.Pinterest.replace(/(^\w+:|^)\/\//, ''),
        OtherMedia: this.state.OtherMedia && this.state.OtherMedia.replace(/(^\w+:|^)\/\//, ''),
        Text_Instructions: this.state.Text_Instructions,
        Style_Info: this.state.Style_Info,
        Style_Colors: (this.state.addonColors && this.state.addonColors.length>0) ?  this.state.Style_Colors.concat(this.state.addonColors) : this.state.Style_Colors,
        Style_color_predefined: this.state.Style_Colors,
        Style_color_addon: this.state.addonColors,
        color_instructions: this.state.ColorInstructions,
        Style_Fonts: this.state.Style_Fonts,
        font_instructions:this.state.FontInstructions,
        Target_Audience: this.state.Target_Audience,
        Other_Instructions: this.state.Other_Instructions,
        File_Formats: this.state.outputFileFormats,
        File_Formats_other: this.state.fileFormatOther,
        Softwares: this.state.softwarestoUse,
        Software_other: this.state.softwareOther,
        outsoftInstructions: this.state.outsoftInstructions,
        Watermark: this.state.Watermark,
        Mockup: this.state.Mockup,
        mokupInstructions: this.state.Mockup === 'Yes' ? this.state.mokupInstructions : '',
        Provided_by_client: this.state.Provided_by_client,
        Previous_Approved_work: this.state.Previous_Approved_work,
        resize_crop: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.resizeCrop : '' ),
        bleedSize: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.bleedSize : '' ),
        bleedUnit: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.bleedUnit : '' )
      },
      fieldDataLinkText:
      {
        textInstructionFilesText: this.state.textInstructionFilesTextOptions == 1? this.state.textInstructionFilesText : '',
        textInstructionFilesTextArray: this.state.textInstructionFilesTextOptions == 2? this.state.textInstructionFilesTextArray : [],
        
        imageryFilesText: this.state.imageryFilesTextOptions == 1? this.state.imageryFilesText : '',
        imageryFilesTextArray: this.state.imageryFilesTextOptions == 2? this.state.imageryFilesTextArray : [],

        referenceFilesText: this.state.referenceFilesTextOptions == 1? this.state.referenceFilesText : '',
        referenceFilesTextArray: this.state.referenceFilesTextOptions == 2? this.state.referenceFilesTextArray : [],

        approvedWorkFilesText: this.state.approvedWorkFilesTextOptions == 1? this.state.approvedWorkFilesText : '',
        approvedWorkFilesTextArray: this.state.approvedWorkFilesTextOptions == 2? this.state.approvedWorkFilesTextArray : [],

        taskAttachmentsText: this.state.taskAttachmentTextOptions == 1 ? this.state.taskAttachmentsText:'',
        taskAttachmentTextArray: this.state.taskAttachmentTextOptions == 2 ? this.state.taskAttachmentTextArray:[]
      },
      fieldDataUploads:
      {
        brandingFiles: this.state.brandingFiles,
        textInstructionFiles: this.state.textInstructionFiles,
        imageryFiles: this.state.imageryFiles,
        referenceFiles: this.state.referenceFiles,
        approvedWorkFiles: this.state.approvedWorkFiles
      }
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.addNewTask, data, {
        headers: headers
      })
      .then((response) => {
        response.data.success === 1? notifySuccess() : (response.data.success === 2 ? notifyDraft() : notifyError() )
        if(response.data.success === 1 || response.data.success === 2)
        {
          window.scrollTo(0, 0);
          this.props.history.push("/task/detail/"+response.data.project_id+"/"+response.data.task_id+"?uuid="+this.state.myId);
        }
      })
      .catch((error) => {
        console.log(error);
        notifyError();
      })
  }

  goToHome = () =>
  {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  goToProjectsHome = () =>
  {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl+"?uuid="+this.state.myId);
  }
 
  addSizeFields = () =>
  {
    const values = [...this.state.sizeFields];
    values.push({ width: '', height: '' ,unit:this.state.lastSelectedUnit, note:''});
    this.setState({sizeFields:values})
  }
  
 handleSizeInputChange = (index, event) => {
  const values = [...this.state.sizeFields];
    if (event.target.name === "widthsize") {
      values[index].width = event.target.value;
    } else if (event.target.name === "heightsize") {
      values[index].height = event.target.value;
    }else if (event.target.name === "unitsize") {
      values[index].unit = event.target.value;
      this.setState({lastSelectedUnit: event.target.value});
    }else if (event.target.name === "note") {
      values[index].note = event.target.value;
    }
    this.setState({sizeFields:values})
  };


  removeSizeFields = index => {
    const values = [...this.state.sizeFields];
    values.splice(index, 1);
    this.setState({sizeFields:values})
  }

  toggleNetwork = () =>
  {
    this.setState({showNetworks: !this.state.showNetworks})
  }


  clearSelection = (type) => {
    type == 'Watermark-clear' && this.setState({'Watermark': '' });
    type == 'Mockup-clear' && this.setState({'Mockup': '' });
    type == 'Provided-clear' && this.setState({'Provided_by_client': '' });
    type == 'Approved-clear' && this.setState({'Previous_Approved_work': '' });
    type == 'Reminder-clear' && this.setState({'reminderOptions': '', reminderTime:'' });
    type == 'tasktype-clear' && this.setState({'TaskType': ''});
  };

  handleFileFormats = e => {
    let fileFormats = this.state.outputFileFormats;
    if (e.target.checked) {
      fileFormats.push(e.target.value);
      if(e.target.value=='other')
      {
        this.setState({fileFormatOtherShow:true});
      }
    } else {
      var array = [...this.state.outputFileFormats]; 
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ outputFileFormats: array });
      }
      if(e.target.value=='other')
      {
        this.setState({fileFormatOtherShow:false, fileFormatOther:''});
      }
    }
  };
   
  handleSoftwares = e => {
    let softwares = this.state.softwarestoUse;
    if (e.target.checked) {
      softwares.push(e.target.value);
      if(e.target.value=='other')
      {
        this.setState({softwareOtherShow:true});
      }
    } else {
      var array = [...this.state.softwarestoUse]; 
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ softwarestoUse: array });
      }
      if(e.target.value=='other')
      {
        this.setState({softwareOtherShow:false, softwareOther:''});
      }
    }
  };


  handlePriority = e => {
    if (e.target.checked) {
      this.setState({priority:1});
    } else {
      this.setState({priority:0});
    }
  };

  handleColorInputChange =  (index, event) => {
    const values = [...this.state.addonColors];
      if (event.target.name === "hexcode") {
        values[index]= event.target.value;
      }
     this.setState({addonColors: values});
 }

  addColorFields = ()=>
  {
      const values = [...this.state.addonColors];
      values.push('');
      this.setState({addonColors: values});
  }

  removeColorFields = (index) => {
      const values = [...this.state.addonColors];
      values.splice(index, 1);
      this.setState({addonColors: values});
  }

  showOptions = ({target}) =>
  {
      if(target.name ==='brandcolors')
      {
        let temp =[];
        this.state.brandPredefinedColors.forEach(p1 => {
          temp.push(p1.hexcode);
        });

        this.setState({Style_Colors:temp});

        this.setState({
          showBrandColors: !this.state.showBrandColors,
        })
      }else if(target.name ==='addoncolors')
      {
        this.setState({
          showAddonColors: !this.state.showAddonColors,
        })
      }else if(target.name ==='brandinstro')
      {
        if(target.checked)
        {
          this.setState({
            showColorGuides: !this.state.showColorGuides,
            ColorInstructions: this.state.ColorInstructionsTemp ? this.state.ColorInstructionsTemp : '',
            FontInstructions: this.state.FontInstructionsTemp? this.state.FontInstructionsTemp : ''
          })
        }else
        {
          this.setState({
            showColorGuides: !this.state.showColorGuides,
            ColorInstructions: '',
            FontInstructions: ''
          })
        }
      }
  }

  addSelectedColors = (code) =>
  {
    let temp = [...this.state.Style_Colors];
    if(temp.length>0)
    {
      var index = temp.indexOf(code);
      if (index !== -1) {
        temp.splice(index, 1);
        this.setState({ Style_Colors: temp });
      }else
      {
        temp.push(code);
        this.setState({ Style_Colors: temp });
      }
    }else
    {
      temp.push(code);
      this.setState({ Style_Colors: temp });
    }
  }

  showActiveClass = ()=>
  {
    this.setState({activeClass:'active'});
  }

  hideActiveClass = ()=>
  {
    this.setState({activeClass:''});
  }

 handleBleedInputChange =  (event) => {
    if (event.target.name === "bleed-size") {
        this.setState({bleedSize:event.target.value});
        } else if (event.target.name === "unitsize") {
          this.setState({bleedUnit:event.target.value});
      }
};

handClickUpdate = ({ target }) => {
  if(target.name=='taskAttachmentTextOptions')
  {
    this.setState({ [target.name]: target.value, taskAttachmentsChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({taskAttachmentsChange:false});
    }, 500);
  }else if(target.name=='textInstructionFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, textInstructionFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({textInstructionFilesChange:false});
    }, 500);
  }else if(target.name=='imageryFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, imageryFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({imageryFilesChange:false});
    }, 500);
  }else if(target.name=='referenceFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, referenceFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({referenceFilesChange:false});
    }, 500);
  }else if(target.name=='approvedWorkFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, approvedWorkFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({approvedWorkFilesChange:false});
    }, 500);
  }
};

  render() {
    let animationClasses = (this.state.animate ? ' active_arrow': '');
    return (
      <Fragment>
      <Container fluid className="top-bar-inactive bc_module_container">
      <Row>
        <div className="theme_header">
            <Header {...this.props}  className="tick-timer-header" 
              logout={this.signOut.bind(this)} 
              onmenuaction={this.toggleSidebar.bind(this)}
              quickactionblock={false} />
        </div>
       <div className="themeContentBody themefullwidthContentBoday">
       <LoadingWrapper isLoading={this.state.progressBar}>
            {this.state.accessDenied ?
            (
              <>
                <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
              </>
            ):
            (
            <>
            <Row>
            <Col sm="12" className="bc_module">
            <Toaster position="top-right" containerClassName="notifier"
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff',
                    },}}
              />
            <div className="bc_Board_wrapper">
              <h2 class="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
              <ProjectCurrentStatus 
              graphics={this.state.projectDetails.graphics_pack}
              video={this.state.projectDetails.video_pack}
              graphics_video={this.state.projectDetails.graphics_video_pack}
              canva_graphics={this.state.projectDetails.canva_graphics}
              canva_plus={this.state.projectDetails.canva_plus}
              whitelabel={this.state.projectDetails.white_label}
              clientstatus={this.state.projectDetails.client_status} />
              </h2>
              <p class="board_discrption">{this.state.projectDetails.short_description}</p>
              <h2 className="section_title subtitle">{this.state.taskName !='' ? this.state.taskName : 'New Card'}</h2>
              <div className="page_header_wrap new_task_head">
                <div className="search-box">
                      <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this,'/boards')} > <MaterialIcon icon="fact_check"  /> </a>
                      <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                  </div>
                
              </div>
            </div>
              <div className="breadcrumb_boards">
              <Breadcrumb>
                  <BreadcrumbItem><a  href={"/boards"+"?uuid="+this.state.myId}>Boards</a></BreadcrumbItem>
                  <BreadcrumbItem><a  href={'/boardsdetails/'+this.props.match.params.bid+"?uuid="+this.state.myId}>Board Details</a></BreadcrumbItem>
                  <BreadcrumbItem><a href={'/task-list/'+this.props.match.params.bid+"?uuid="+this.state.myId}>Cards</a></BreadcrumbItem>
                  <BreadcrumbItem active>New Card</BreadcrumbItem>
                </Breadcrumb>
                </div>
          </Col>
          </Row>
          <div className="new_tas_formbody bc_module">
          <Row className="justify-content-center">
          <Col sm="11" xl="10">
            <div className="newtask_form_body" id="newtask">
              {/* <h3>Create New Task</h3> */}
                <div className="form-group">
                  <label>Card Name</label>
                  <Input autoComplete="off" type="text" 
                  className={this.state.errors.includes('taskName')? 'field-error text_box' : 'text_box'}
                  name="taskName" onChange={this.handleChange} />
                  
                </div>
                <Row>
                <Col sm="4">
                  {this.state.availableAssignees && this.state.availableAssignees.length>0 &&
                  (
                    <div className="form-group">
                      <label>Assigned To</label>
                        <PeopleTags tagslist={this.state.availableAssignees} 
                        settags={this.setAssignees}
                        placeholder="Add Assignees"
                        tags={this.state.tassignees}
                        boardtype={this.state.projectDetails.board_type}
                        />
                    </div>
                  )}
                </Col>
                <Col sm="4">
                  {this.state.availableNotifiers && this.state.availableNotifiers.length>0 &&
                  (
                    <div className="form-group">
                      <label>Notify when done</label>
                      <PeopleTags tagslist={this.state.availableNotifiers} 
                      settags={this.setNotifiers}
                      tags={this.state.tnotifiers}
                      placeholder="Add Notifiers" />
                    </div>
                  )}
                </Col>
                <Col sm="4">
                  <div className="form-group ">
                    {this.state.labels && this.state.labels.length>0 &&
                    (
                      <div className="form-group labels-group">
                        <label>Labels</label>
                        <PeopleTags tagslist={this.state.labels} 
                        settags={this.setLabels}
                        tags={this.state.selectedLabels}
                        placeholder="Add Labels"
                        onfocus={this.showActiveClass} />
                        <div className={`label-suggestions ${this.state.activeClass}`} >
                          (
                          {this.state.labels.map((slabel, index)=>{
                            return(
                              <span>{slabel.name}{(index+1) < this.state.labels.length && ', '}</span>
                            )
                          })}
                          )
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                </Row>
                
                <div className="form-group">
                  <label>Choose Template</label>
                  <select className="choose_temp"  onChange={e => this.handleTemplateChange(e)}>
                      <option value="0">Choose Template</option>
                      {(this.state.designTypes && this.state.designTypes.length>0) &&
                      (
                        <>
                          {this.state.designTypes.map((item, index)=>
                          {
                            return(
                              <option value={item.id} >{item.design_type_name}</option>
                            )
                          })}
                        </>
                      )}
                  </select>
                </div>
                
                
                <div className="template-group-fields">
                  <Row>
                        {this.state.selectedTemplate && this.state.selectedTemplate>0 &&
                        (
                          <Col sm="6">
                          <div className="form-group">
                            <label>Brand</label>
                            <select className="choose_temp" name="brand"  onChange={this.handleBrandChange}>
                              <option value="">Select</option>
                              {this.state.availableBrands && this.state.availableBrands.length>0 &&
                              (
                                <>  
                                  {this.state.availableBrands.map((branditem)=>
                                  {
                                    return(
                                      <option key={branditem.brand_id} value={branditem.brand_id}>{branditem.brand_name}</option>
                                    )
                                  })}
                                </>
                              )}
                            </select>
                          </div>
                        </Col>
                        )}


                      {this.state.templateFields.indexOf("10") !== -1 &&
                      (
                        <Col sm="12">
                          <h6 className="template-field-subheads">Client Comments</h6>
                          </Col>
                      )}
                      {this.state.templateFields.indexOf("15") !== -1 &&
                      (
                        <Col sm="12">
                         
                          <ReactQuill 
                          id="Other_Instructions" 
                          onChange={(e)=>this.setState({Other_Instructions:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          placeholder= "Add Comments Here....." 
                          theme="snow" 
                          // modules={{mention: this.mentionModule,toolbar: toolbarOptions}}
                          modules={this.modules}
                          />
                        </Col>
                      )}
                      {/* {this.state.templateFields.indexOf("11") !== -1 &&
                      (
                        <Col sm="6">
                          <Label>Style</Label>
                          <Input type="text" name="Style_Info"  onChange={this.handleChange}/>
                        </Col>
                      )} */}
                      {/* {this.state.templateFields.indexOf("12") !== -1 &&
                      (
                        <Col sm="6">
                          <Label>Colors</Label>
                          <Input type="text" name="Style_Colors" onChange={this.handleChange} />
                        </Col>
                      )} */}

                      {/* {this.state.templateFields.indexOf("13") !== -1 &&
                      (
                        <Col sm="6">
                          <Label>Fonts</Label>
                          <Input type="text"  name="Style_Fonts" onChange={this.handleChange} />
                        </Col>
                      )} */}
                      {this.state.templateFields.indexOf("14") !== -1 &&
                      (
                        <Col sm="12" className="mt-3">
                          <Label>Target Audience</Label>
                          <Input autoComplete="off" type="text" name="Target_Audience" onChange={this.handleChange} />
                        </Col>
                      )}
                      { this.state.templateFields.indexOf("3") !== -1 &&
                      (
                        <>
                          <Col sm="4" className="mt-5">
                            <Label>Medium</Label>
                            <Input type="select" name="Purpose" onChange={this.handleChange}>
                              <option value="">Select</option>
                              <option value="Digital">Digital</option>
                              <option value="Print">Print</option>
                            </Input>
                          </Col>
                          {(this.state.Purpose && this.state.Purpose === 'Print') &&
                          (
                            <>
                            <Col sm="4" className="mt-5 new-task-crop">
                                <Label>Crop / Trim Required</Label>
                                <div className='d-flex reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2x'>
                                  <div className='d-flex crop-items'>
                                      <Input type='radio' id="crop-yes" name="crop-resize" value="1"
                                      checked={this.state.resizeCrop == 1 && 'checked' }
                                      onClick={(e)=> this.setState({resizeCrop: 1})}  />
                                      <Label for="crop-yes">Yes</Label>
                                  </div>
                                  <div className='d-flex crop-items'>
                                      <Input type='radio' id="crop-no" name="crop-resize" value="0"
                                      checked={this.state.resizeCrop == 0 && 'checked' } 
                                      onClick={(e)=> this.setState({resizeCrop: 0})} />
                                      <Label for="crop-no">No</Label>
                                  </div>
                                </div>
                            </Col>
                            <Col sm="4" className="mt-5">
                                <div className='d-flex new-task-bleed'>
                                <div className='bleed-size'>
                                <Label>Bleed Size </Label>
                                <Input type='text' name="bleed-size" value={this.state.bleedSize} onChange={(e)=>this.handleBleedInputChange(e)} />
                                </div>
                                <div className='bleed-unit'>
                                <Label>Bleed Unit</Label>
                                <select className="choose_temp" name="unitsize"  onChange={(e)=>this.handleBleedInputChange(e)}>
                                  <option selected={this.state.bleedUnit === 'Inch' && 'selected'} value="Inch">Inch</option>
                                  <option selected={this.state.bleedUnit === 'PX' && 'selected'} value="PX">PX</option>
                                  <option selected={this.state.bleedUnit === 'CM' && 'selected'} value="CM" >CM</option>
                                  <option selected={this.state.bleedUnit === 'MM' && 'selected'} value="MM" >MM</option>
                                  <option selected={this.state.bleedUnit === 'FT' && 'selected'} value="FT" >FT</option>
                                </select>
                                </div>
                                </div>
                            </Col>
                            </>
                          )}
                        </>
                      )}
                      { this.state.templateFields.indexOf("2") !== -1 &&
                      (
                        
                        <Col sm="6" className='mt-5'>
                          <Label>File Size Limit</Label>
                          <Input type="text" name="FileSizeLimit" onChange={this.handleChange} />
                        </Col>
                      )}
                      { this.state.templateFields.indexOf("1") !== -1 &&
                      (
                      <Col sm="12">
                          <Label className="custom_lable">Size</Label>
                          
                            {(this.state.sizeFields && this.state.sizeFields.length>0) &&
                            (
                              <div className='size-group'>
                                {this.state.sizeFields.map((sitem, index)=>
                                {
                                  return(
                                    <div key={index} className="d-flex ">
                                      <div className="flex-itmes small-text-box">
                                        <Label>Width</Label>
                                        <Input autoComplete="off" type="text" name="widthsize" value={sitem.width}  onChange={event => this.handleSizeInputChange(index, event)} />
                                      </div>
                                      <div className="flex-itmes small-text-box">
                                        <Label>Height</Label>
                                        <Input autoComplete="off" type="text" name="heightsize" value={sitem.height} onChange={event => this.handleSizeInputChange(index, event)} />
                                      </div>
                                      <div className="flex-itmes unit_dropselect">
                                        <Label>Unit</Label>
                                        <select className="choose_temp" name="unitsize"  onChange={event => this.handleSizeInputChange(index, event)} >
                                          <option selected={sitem.unit === '' && 'selected'} value="">Select</option>
                                          <option selected={sitem.unit === 'PX' && 'selected'} value="PX">PX</option>
                                          <option selected={sitem.unit === 'Inch' && 'selected'} value="Inch">Inch</option>
                                          <option selected={sitem.unit === 'CM' && 'selected'} value="CM" >CM</option>
                                          <option selected={sitem.unit === 'MM' && 'selected'} value="MM" >MM</option>
                                          <option selected={sitem.unit === 'FT' && 'selected'} value="FT" >FT</option>
                                        </select>
                                      </div>
                                      <div className="flex-itmes big-text-box">
                                        <Label>Size Short Note</Label>
                                        <Input autoComplete="off" type="text" name="note" value={sitem.note} onChange={event => this.handleSizeInputChange(index, event)} />
                                      </div>
                                      {index=== 0 ?
                                      (
                                          <div className="flex-itmes flex-items-center plus_btn_taskadd" onClick={this.addSizeFields}><MaterialIcon icon="add"/> </div>
                                      ):
                                      (
                                        <>
                                          <div className="flex-itmes flex-items-center plus_btn_taskadd" onClick={this.addSizeFields}><MaterialIcon icon="add"/> </div>
                                          <div className="flex-itmes flex-items-center remove_btn_taskadd" onClick={this.removeSizeFields.bind(this.setState,index)}><MaterialIcon icon="remove"/> </div>
                                        </>
                                      )}
                                    </div>
                                  )
                               })}
                              </div>
                            )}
                          </Col>
                        )}
                  </Row>
                  <Row>
                    { this.state.templateFields.indexOf("4") !== -1 &&
                      (
                        <Col sm="12">
                          <h6 className="template-field-subheads">Branding</h6>
                        </Col>
                    )}
                    { this.state.templateFields.indexOf("5") !== -1 &&
                      (
                        <Col sm="12">
                          <Label>Brand Assets and Guidelines</Label>
                          <ReactQuill 
                          id="Guidelines" 
                          onChange={(e)=>this.setState({Guidelines:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          placeholder= "Add Comments Here....." 
                          theme="snow" 
                          value={this.state.Guidelines}
                          modules={this.modules}
                          />
                        </Col>
                      )}
                      { this.state.templateFields.indexOf("12") !== -1 &&
                      (
                        <>
                        {this.state.brand>0 &&
                        (
                          <>
                              <Col sm="12">
                                <div className="color_edit_wrap">
                                  <h6>Colors</h6>
                                  <FormGroup className="edit_brand_color">
                                  <Input checked={this.state.showBrandColors && 'checked'} type="checkbox" name="brandcolors" id="brandcolors" onClick={(e) => this.showOptions(e) } />
                                  <Label for="brandcolors">Brand Colors</Label>
                                  </FormGroup>
                                  {(this.state.showBrandColors && this.state.brandPredefinedColors && this.state.brandPredefinedColors.length>0) &&
                                  (
                                    <div className="d-flex brand_color_box">
                                    {this.state.brandPredefinedColors.map((color, index)=>{
                                      return(
                                        <div key={color.id} className={`d-flex task-colors align-items-center ${this.state.Style_Colors.includes(color.hexcode) && 'selected-color'}`} onClick={this.addSelectedColors.bind(this, color.hexcode)}>
                                          <span className="hexcodeview" style={{background: color.hexcode && color.hexcode}}></span>
                                          <span>{color.hexcode}</span>
                                          {color.primary === 1 && 
                                          (
                                            <div className="primary_color"><MaterialIcon icon="star"/> </div>
                                          )}
                                        </div>
                                      )
                                    })}
                                    </div>
                                  )}
                              </div>
                            </Col>
                          
                            <Col sm="12">
                              <div className="color_edit_wrap">
                                <FormGroup className="edit_brand_color">
                                  <Input checked={this.state.showAddonColors && 'checked'} type="checkbox" name="addoncolors" id="addoncolors" onClick={(e) => this.showOptions(e) }/>
                                  <Label for="addoncolors">Add custom Colors</Label>
                                </FormGroup>
                                <div className="custom_edit_color">
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <Row>
                                        {(this.state.showAddonColors && this.state.addonColors && this.state.addonColors.length>0) &&
                                        (
                                            <>
                                                {this.state.addonColors.map((sitem, index)=>
                                                {
                                                return(
                                                    <Col sm={3} key={index}>
                                                        <div className="custom_color_addbox">
                                                        <span className="hexcodeview" style={{background: sitem ? sitem : '#fff'}}></span>
                                                        <span>
                                                        <Input type="text" name="hexcode" value={sitem}  onChange={event => this.handleColorInputChange(index, event)} />
                                                        </span>
                                                        <button className="remove_btn" onClick={event => this.removeColorFields(index)}></button>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </>
                                        )}
                                        {this.state.showAddonColors &&
                                        (
                                          <Button className="btn-green save_btn add_color_edit_btn" onClick={this.addColorFields.bind(this)} name="add-more-colors" value="add-coolors">+</Button>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                            <Col sm="12">
                              <div className="color_edit_wrap">
                              <FormGroup className="edit_brand_color">
                              <Input checked={this.state.showColorGuides && 'checked'} type="checkbox" name="brandinstro" id="brandinstro"  onClick={(e) => this.showOptions(e) }/>
                              <Label for="brandinstro">Attach Color and Font Instructions</Label>
                              </FormGroup>
                              </div>
                            </Col>
                          </>
                        )}  
                        <Col sm="12">
                          <div className="color_edit_wrap">
                          { this.state.templateFields.indexOf("12") !== -1 && 
                          (
                            <>
                              <br/>
                              <Label>Color Instructions</Label>
                              <ReactQuill 
                              id="ColorInstructions" 
                              onChange={(e)=>this.setState({ColorInstructions:e})}
                              laceholder= "Add color instructions here....." 
                              theme="snow" 
                              value={this.state.ColorInstructions}
                              modules={this.modules}
                              />
                            </>
                          )}
                        
                        </div>
                        </Col>

                        {(this.state.templateFields.indexOf("13") !== -1) &&
                        (  
                          <Col sm="12" className="mt-4"> 
                            <Label>Font Instructions</Label>
                            <ReactQuill 
                            id="FontInstructions" 
                            onChange={(e)=>this.setState({FontInstructions:e})}
                            laceholder= "Add font instructions here....." 
                            theme="snow" 
                            value={this.state.FontInstructions}
                            modules={this.modules}
                            />
                          </Col>
                        )}

                          {/* <KFileUpload myid={this.state.myId} folderpath="task/guidelines" arrayname="brandingFiles" storefiles={this.storeFileNames} /> */}
                    
                        </>
                      )}
             
                    {/* {this.state.templateFields.indexOf("6") !== -1 &&
                      (
                        <Col sm="6">
                          <Label>Website & Social Networks</Label>
                          <Input type="text" name="Website" onChange={this.handleChange} />
                        </Col>
                      )} */}
                    {/* {this.state.templateFields.indexOf("7") !== -1 &&
                      (
                        <Col sm="6">
                          <Label>Social Media</Label>
                          <Input type="text" name="Social_Media" onChange={this.handleChange} />
                        </Col>
                      )} */}
                  </Row>
                  <Row>
                      {this.state.templateFields.indexOf("8") !== -1 &&
                      (
                        <Col sm="12">
                          <h6 className="template-field-subheads">Text Copy</h6>
                          <ReactQuill 
                          id="Text_Instructions" 
                          onChange={(e)=>this.setState({Text_Instructions:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          placeholder= "Add Comments Here....." 
                          theme="snow" 
                          modules={this.modules}
                          />
                          <br />
                          <Row className={`uploader-text ${this.state.textInstructionFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                            <Col sm="6">
                              <KFileUpload 
                              myid={this.state.myId} 
                              folderpath="task/text-instructions" 
                              arrayname="textInstructionFiles" 
                              storefiles={this.storeFileNames} />
                            </Col>
                            <Col sm="6">
                              <ReactQuill 
                              id="textInstructionFilesText" 
                              onChange={(e)=>this.setState({textInstructionFilesText:e})}
                              className={this.state.detailError? 'field-error' : ''}
                              placeholder= "Add the file details or links" 
                              theme="snow" 
                              modules={this.minimodules}
                              />
                            </Col>

                            <Col sm="12" >
                                {(this.state.textInstructionFiles && this.state.textInstructionFiles.length>0) &&
                                (
                                  <div className='reminder-options custom_radio options-items-parent d-flex'>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.textInstructionFilesTextOptions == 1 && 'checked'}  id="textInstructionscombined" 
                                      type="radio" name="textInstructionFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                      <Label for="textInstructionscombined">Combined instructions for all attachments</Label>
                                    </div>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.textInstructionFilesTextOptions == 2 && 'checked'} id="textInstructionsseparate" 
                                      type="radio" name="textInstructionFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                      <Label for="textInstructionsseparate">Separate instructions for attachments</Label>
                                    </div>
                                  </div>
                                )}
                                {(this.state.textInstructionFiles && this.state.textInstructionFiles.length>0 && !this.state.textInstructionFilesChange) &&
                                (
                                  <LightboxPop 
                                  attachments={this.state.textInstructionFiles} 
                                  deleteitem="yes"
                                  deletefiles={this.deletefiles} 
                                  arrayname={'textInstructionFiles'}
                                  listtype={this.state.textInstructionFilesTextOptions == 2 ? this.state.textInstructionFilesTextOptions : 1}
                                  taskAttachmenttextarray={this.state.textInstructionFilesTextArray}
                                  updatefiletext={this.updateFileText}
                                  />
                                )}
                            </Col>
                          </Row> 
                        </Col>
                      )}
                  </Row>
                  <Row>
                      {this.state.templateFields.indexOf("9") !== -1 &&
                      (
                        <Col sm="12">
                          <h6 className="template-field-subheads">Images</h6>
                          <Row className={`uploader-text ${this.state.imageryFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                            <Col sm="6">
                              <KFileUpload 
                              myid={this.state.myId} 
                              folderpath="task/imagery" 
                              arrayname="imageryFiles" 
                              storefiles={this.storeFileNames}  />
                            </Col>
                            <Col sm="6">
                              <ReactQuill 
                                id="imageryFilesText" 
                                onChange={(e)=>this.setState({imageryFilesText:e})}
                                className={this.state.detailError? 'field-error' : ''}
                                placeholder= "Add the file details or links" 
                                theme="snow" 
                                modules={this.minimodules}
                                />
                            </Col>

                            <Col sm="12" >
                                {(this.state.imageryFiles && this.state.imageryFiles.length>0) &&
                                (
                                  <div className='reminder-options custom_radio options-items-parent d-flex'>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.imageryFilesTextOptions == 1 && 'checked'}  id="imageryFilescombined" 
                                      type="radio" name="imageryFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                      <Label for="imageryFilescombined">Combined instructions for all attachments</Label>
                                    </div>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.imageryFilesTextOptions == 2 && 'checked'} id="imageryFilesseparate" 
                                      type="radio" name="imageryFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                      <Label for="imageryFilesseparate">Separate instructions for attachments</Label>
                                    </div>
                                  </div>
                                )}
                                {(this.state.imageryFiles && this.state.imageryFiles.length>0 && !this.state.imageryFilesChange) &&
                                (
                                  <LightboxPop 
                                  attachments={this.state.imageryFiles} 
                                  deleteitem="yes"
                                  deletefiles={this.deletefiles} 
                                  arrayname={'imageryFiles'}
                                  listtype={this.state.imageryFilesTextOptions == 2 ? this.state.imageryFilesTextOptions : 1}
                                  taskAttachmenttextarray={this.state.imageryFilesTextArray}
                                  updatefiletext={this.updateFileText}
                                  />
                                )}
                            </Col>
                          </Row>

                        </Col>
                      )}
                  </Row>

                  
                  <Row>
                    {this.state.templateFields.indexOf("16") !== -1 &&
                        (
                          <Col sm="12">
                            <h6 className="template-field-subheads">File Formats</h6>
                          </Col>
                    )}
                    
                    { this.state.templateFields.indexOf("19") !== -1 &&
                      (
                        <Col sm="12" className="mb-3">
                          <Label className="title_lable">Output File Formats</Label>
                          <div className="d-flex fileformat_card align-items-center">
                            <div className="d-flex flex-itmes">
                              <Input id="file-jpg" type="checkbox" name="file-formats" value="JPG" 
                               onChange={this.handleFileFormats.bind(this)} />
                              <Label for="file-jpg">JPG</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-png" type="checkbox" name="file-formats" value="PNG"
                              onChange={this.handleFileFormats.bind(this)} />
                              <Label for="file-png">PNG</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-gif" type="checkbox" name="file-formats" value="GIF"
                              onChange={this.handleFileFormats.bind(this)} />
                              <Label for="file-gif">GIF</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-pdf" type="checkbox" name="file-formats" value="PDF"
                              onChange={this.handleFileFormats.bind(this)} />
                              <Label for="file-pdf">PDF</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-ppt" type="checkbox" name="file-formats" value="PPT"
                              onChange={this.handleFileFormats.bind(this)} />
                              <Label for="file-ppt">PPT</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-other" type="checkbox" name="file-formats" value="other"
                              onChange={this.handleFileFormats.bind(this)} />
                              <Label for="file-other">Other</Label>
                            </div>
                            {this.state.fileFormatOtherShow &&
                            (
                              <div className="d-flex flex-itmes">
                                <Input  type="text" name="fileFormatOther" value={this.state.fileFormatOther}
                                onChange={this.handleChange} />
                              </div>
                            )}
                            </div>
                        </Col>
                      )}
                      { this.state.templateFields.indexOf("20") !== -1 &&
                      (
                        <Col sm="12" className="mb-3">
                          <Label className="title_lable">Software to Use</Label>
                          <div className="d-flex fileformat_card align-items-center">
                            {/* {this.state.selectedTemplate && this.state.selectedTemplate == 47 &&
                            ( */}
                              <div className="d-flex flex-itmes">
                                <Input id="soft-designer" type="checkbox" name="Softwares" value="Let My Designer Choose" 
                                onChange={this.handleSoftwares.bind(this)} />
                                <Label for="soft-designer">Let My Designer Choose</Label>
                              </div>
                            {/* )} */}
                            <div className="d-flex flex-itmes">
                              <Input id="soft-psd" type="checkbox" name="Softwares" value="PSD"
                               onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-psd">PSD</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-ai" type="checkbox" name="Softwares" value="Ai"
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-ai">Ai</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-xd" type="checkbox" name="Softwares" value="XD"
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-xd">XD</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-indd" type="checkbox" name="Softwares" value="INDD" 
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-indd">INDD</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-figma" type="checkbox" name="Softwares" value="Figma" 
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-figma">Figma</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-canva" type="checkbox" name="Softwares" value="Canva" 
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-canva">Canva</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-ae" type="checkbox" name="Softwares" value="AE or PP" 
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-ae">AE or PP</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="soft-other" type="checkbox" name="Softwares" value="other" 
                              onChange={this.handleSoftwares.bind(this)} />
                              <Label for="soft-other">Other</Label>
                            </div>
                            {this.state.softwareOtherShow &&
                            (
                              <div className="d-flex flex-itmes">
                                <Input  type="text" name="softwareOther" value={this.state.softwareOther}
                                onChange={this.handleChange} />
                              </div>
                            )}
                          </div>
                        </Col>
                      )}

                      {(this.state.templateFields.indexOf("20") !== -1  ||  this.state.templateFields.indexOf("19") !== -1) &&
                      (
                        <Col sm="12" className="mb-3">
                            <Label className="title_lable">Instructions for Output file formats & Software to use</Label>
                            <ReactQuill 
                            id="outsoftInstructions" 
                            onChange={(e)=>this.setState({outsoftInstructions:e})}
                            placeholder= "" 
                            theme="snow" 
                            modules={this.minimodules}
                            />
                        </Col>
                      )}
                      {this.state.templateFields.indexOf("17") !== -1 &&
                        (
                          <Col sm="12" className="mb-3">
                            <Label className="title_lable">Watermark</Label>
                            <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card">
                              <div className="d-flex ">
                                  <Input id="Watermark-Yes" value="Yes" type="radio" name="Watermark" 
                                  onChange={this.handleChange} checked={this.state.Watermark === 'Yes' && 'checked' } />
                                  <label for="Watermark-Yes">Yes</label>
                              </div>
                              <div className="d-flex">
                                  <Input id="Watermark-No" value="No" type="radio" name="Watermark" 
                                  onChange={this.handleChange} checked={this.state.Watermark === 'No' && 'checked' }/>
                                  <label for="Watermark-No">No</label>
                              </div>
                              {(this.state.Watermark && this.state.Watermark !='') &&
                              (
                                <div className="d-flex clear-selection" name="Watermark-clear" onClick={this.clearSelection.bind(this,'Watermark-clear')}>Clear Selection</div>
                              )}
                            </div>
                          </Col>
                    )}
                    {this.state.templateFields.indexOf("18") !== -1 &&
                        (
                          <>
                            <Col sm="12" className="mb-3">
                              <Label className="title_lable">Mockup</Label>
                              <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card">
                                <div className="d-flex">
                                    <Input id="Mockup-Yes" value="Yes" type="radio" name="Mockup" 
                                    onChange={this.handleChange}  checked={this.state.Mockup === 'Yes' && 'checked' }/>
                                    <label for="Mockup-Yes">Yes</label>
                                </div>
                                <div className="d-flex">
                                    <Input id="Mockup-No" value="No" type="radio" name="Mockup" 
                                    onChange={this.handleChange} checked={this.state.Mockup === 'No' && 'checked' }/>
                                    <label for="Mockup-No">No</label>
                                </div>
                                {(this.state.Mockup && this.state.Mockup !='') &&
                                (
                                  <div className="d-flex clear-selection" name="Mockup-clear" onClick={this.clearSelection.bind(this,'Mockup-clear')}>Clear Selection</div>
                                )}
                              </div>
                            </Col>
                            {this.state.Mockup === 'Yes' &&
                            (
                              <Col sm="12">
                                <Label>Mockup Instruction(optional)</Label>
                                <ReactQuill 
                                id="referenceFilesText" 
                                onChange={(e)=>this.setState({mokupInstructions:e})}
                                placeholder= "Add comments here..." 
                                theme="snow" 
                                modules={this.minimodules}
                                />
                              </Col>
                            )}
                      </>
                          
                    )}
                  </Row>
                  <Row>
                    {this.state.templateFields.indexOf("21") !== -1 &&
                        (
                          <Col sm="12">
                          <h6 className="template-field-subheads">References</h6>
                          </Col>
                    )}
                    {this.state.templateFields.indexOf("22") !== -1 &&
                        (
                          <Col sm="12">
                            <Label className="title_lable">Provided By Client</Label>
                            <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2">
                              <div className="d-flex">
                                  <Input id="Client-Recreate" value="Recreate" type="radio" name="Provided_by_client" 
                                  onChange={this.handleChange} checked={this.state.Provided_by_client === 'Recreate' && 'checked' }/>
                                  <label for="Client-Recreate">Recreate</label>
                              </div>
                              <div className="d-flex">
                                  <Input id="Client-Redesign" value="Redesign" type="radio" name="Provided_by_client" 
                                  onChange={this.handleChange} checked={this.state.Provided_by_client === 'Redesign' && 'checked' }/>
                                  <label for="Client-Redesign">Redesign</label>
                              </div>
                              <div >
                                  <Input id="Client-reference" value="Designer Reference Only" className="form-check-input" type="radio" name="Provided_by_client"
                                   onChange={this.handleChange} checked={this.state.Provided_by_client === 'Designer Reference Only' && 'checked' }/>
                                  <label for="Client-reference">Designer Reference Only</label>
                              </div>
                              {(this.state.Provided_by_client && this.state.Provided_by_client !='') &&
                              (
                                <div className="d-flex clear-selection" name="Provided-clear" onClick={this.clearSelection.bind(this,'Provided-clear')}>Clear Selection</div>
                              )}
                            </div>
                            {(this.state.Provided_by_client!='' && this.state.Provided_by_client!=null)  &&
                            (
                            <Row className={`uploader-text ${this.state.referenceFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                              <Col sm="6">
                                <KFileUpload 
                                  myid={this.state.myId} 
                                  folderpath="task/client-provided" 
                                  arrayname="referenceFiles" 
                                  storefiles={this.storeFileNames} />
                                </Col>
                                <Col sm="6">
                                    <ReactQuill 
                                    id="referenceFilesText" 
                                    onChange={(e)=>this.setState({referenceFilesText:e})}
                                    className={this.state.detailError? 'field-error' : ''}
                                    placeholder= "Add the file details or links" 
                                    theme="snow" 
                                    modules={this.minimodules}
                                    />
                                </Col>
                                <Col sm="12" >
                                  {(this.state.referenceFiles && this.state.referenceFiles.length>0) &&
                                  (
                                    <div className='reminder-options custom_radio options-items-parent d-flex'>
                                      <div className='d-flex options-items'>
                                        <Input checked={this.state.referenceFilesTextOptions == 1 && 'checked'}  id="referenceFilescombined" 
                                        type="radio" name="referenceFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                        <Label for="referenceFilescombined">Combined instructions for all attachments</Label>
                                      </div>
                                      <div className='d-flex options-items'>
                                        <Input checked={this.state.referenceFilesTextOptions == 2 && 'checked'} id="referenceFilesseparate" 
                                        type="radio" name="referenceFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                        <Label for="referenceFilesseparate">Separate instructions for attachments</Label>
                                      </div>
                                    </div>
                                  )}
                                  {(this.state.referenceFiles && this.state.referenceFiles.length>0 && !this.state.referenceFilesChange) &&
                                  (
                                    <LightboxPop 
                                    attachments={this.state.referenceFiles} 
                                    deleteitem="yes"
                                    deletefiles={this.deletefiles} 
                                    arrayname={'referenceFiles'}
                                    listtype={this.state.referenceFilesTextOptions == 2 ? this.state.referenceFilesTextOptions : 1}
                                    taskAttachmenttextarray={this.state.referenceFilesTextArray}
                                    updatefiletext={this.updateFileText}
                                    />
                                  )}
                                </Col>
                              </Row>
                            )}
                          </Col>
                    )}
                    {this.state.templateFields.indexOf("23") !== -1 &&
                        (
                          <Col sm="12">
                            <Label className="title_lable">Previously Approved Work</Label>
                            <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2">
                              <div >
                                  <Input id="Work-Recreate" value="Recreate" className="form-check-input" type="radio" name="Previous_Approved_work"
                                    onChange={this.handleChange} checked={this.state.Previous_Approved_work === 'Recreate' && 'checked' }/>
                                  <label for="Work-Recreate">Recreate</label>
                              </div>
                              <div >
                                  <Input id="Work-Redesign" value="Redesign" className="form-check-input" type="radio" name="Previous_Approved_work"
                                   onChange={this.handleChange} checked={this.state.Previous_Approved_work === 'Redesign' && 'checked' }/>
                                  <label for="Work-Redesign">Redesign</label>
                              </div>
                              <div >
                                  <Input id="Work-reference" value="Designer Reference Only" className="form-check-input" type="radio" name="Previous_Approved_work"
                                   onChange={this.handleChange} checked={this.state.Previous_Approved_work === 'Designer Reference Only' && 'checked' }/>
                                  <label for="Work-reference">Designer Reference Only</label>
                              </div>
                              {(this.state.Previous_Approved_work && this.state.Previous_Approved_work !='') &&
                              (
                                <div className="d-flex clear-selection" name="Approved-clear" onClick={this.clearSelection.bind(this,'Approved-clear')}>Clear Selection</div>
                              )}
                            </div>
                            {(this.state.Previous_Approved_work !='' && this.state.Previous_Approved_work != null) &&
                            (
                            <Row className={`uploader-text ${this.state.approvedWorkFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                              <Col sm="6">
                                <KFileUpload 
                                myid={this.state.myId} 
                                folderpath="task/approved-work" 
                                arrayname="approvedWorkFiles" 
                                storefiles={this.storeFileNames} />
                              </Col>
                              <Col sm="6">
                                  <ReactQuill 
                                    id="approvedWorkFilesText" 
                                    onChange={(e)=>this.setState({approvedWorkFilesText:e})}
                                    className={this.state.detailError? 'field-error' : ''}
                                    placeholder= "Add the file details or links" 
                                    theme="snow" 
                                    modules={this.minimodules}
                                  />
                              </Col>
                              <Col sm="12" >
                                  {(this.state.approvedWorkFiles && this.state.approvedWorkFiles.length>0) &&
                                  (
                                    <div className='reminder-options custom_radio options-items-parent d-flex'>
                                      <div className='d-flex options-items'>
                                        <Input checked={this.state.approvedWorkFilesTextOptions == 1 && 'checked'}  id="approvedWorkFilescombined" 
                                        type="radio" name="approvedWorkFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                        <Label for="approvedWorkFilescombined">Combined instructions for all attachments</Label>
                                      </div>
                                      <div className='d-flex options-items'>
                                        <Input checked={this.state.approvedWorkFilesTextOptions == 2 && 'checked'} id="approvedWorkFilesseparate" 
                                        type="radio" name="approvedWorkFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                        <Label for="approvedWorkFilesseparate">Separate instructions for attachments</Label>
                                      </div>
                                    </div>
                                  )}
                                  {(this.state.approvedWorkFiles && this.state.approvedWorkFiles.length>0 && !this.state.approvedWorkFilesChange) &&
                                  (
                                    <LightboxPop 
                                    attachments={this.state.approvedWorkFiles} 
                                    deleteitem="yes"
                                    deletefiles={this.deletefiles} 
                                    arrayname={'approvedWorkFiles'}
                                    listtype={this.state.approvedWorkFilesTextOptions == 2 ? this.state.approvedWorkFilesTextOptions : 1}
                                    taskAttachmenttextarray={this.state.approvedWorkFilesTextArray}
                                    updatefiletext={this.updateFileText}
                                    />
                                  )}
                              </Col>
                            </Row>
                            )}
                          </Col>
                    )}

                    {(this.state.templateFields.indexOf("6") !== -1 ||  this.state.templateFields.indexOf("7") !== -1) &&
                    (  
                      <>
                      <Col sm="12">
                        <div className={`website-social-bar${animationClasses}`} onClick={this.toggleNetwork}>
                          <div onClick={this.handleClick}>Website and Social Networks <MaterialIcon icon="expand_more"/></div>
                        </div>
                      </Col>
                       
                        {this.state.showNetworks &&
                        (
                          <>
                            <Col sm="6">
                                <Label>Website</Label>
                                <Input autoComplete="off" type="text" value={this.state.Website} name="Website" onChange={this.handleChange} />
                            </Col>
                            <Col sm="6">
                                <Label>Facebook</Label>
                                <Input autoComplete="off" type="text" value={this.state.Facebook} name="Facebook" onChange={this.handleChange} />
                            </Col>
                            <Col sm="6">
                                <Label>Twitter</Label>
                                <Input autoComplete="off" type="text" value={this.state.Twitter} name="Twitter" onChange={this.handleChange} />
                            </Col>
                            <Col sm="6">
                                <Label>LinkedIn</Label>
                                <Input autoComplete="off" type="text"  value={this.state.LinkedIn} name="LinkedIn" onChange={this.handleChange} />
                            </Col>
                            <Col sm="6">
                                <Label>Instagram</Label>
                                <Input autoComplete="off" type="text" value={this.state.Instagram} name="Instagram" onChange={this.handleChange} />
                            </Col>
                            <Col sm="6">
                                <Label>Pinterest</Label>
                                <Input autoComplete="off" type="text" value={this.state.Pinterest} name="Pinterest" onChange={this.handleChange} />
                            </Col>
                            <Col sm="6">
                                <Label>Other</Label>
                                <Input autoComplete="off" type="text" value={this.state.OtherMedia} name="OtherMedia" onChange={this.handleChange} />
                            </Col>
                          </>
                        )}
                      </>
                    )}
    

                  </Row>
                </div>
               
                <>
                    <div className="form-group manager-comments">
                      {(this.state.selectedTemplate === '' || this.state.selectedTemplate <=0) ?
                      (<label>Description</label>)
                      :
                      (<label>Other Instructions by Project Manager</label>)
                      }
                      <ReactQuill 
                        id="description" 
                        onChange={(e)=>this.setState({description:e})}
                        className={this.state.detailError? 'field-error' : ''}
                        placeholder= "Add Comments Here....." 
                        theme="snow" 
                        modules={this.modules}
                      />

                      {/* <Editor /> */}
                    </div>
                    {(this.state.selectedTemplate === '' || this.state.selectedTemplate <=0) &&
                    (
                      <div className="form-group">
                        <Row className={`uploader-text ${this.state.taskAttachmentTextOptions==2 && 'indv-instructions-wrap'}`}>
                          <Col sm="6">
                            <KFileUpload myid={this.state.myId} 
                              folderpath="task/attachments" 
                              arrayname="taskAttachments" 
                              storefiles={this.storeTaskFileNames} 
                            />
                          </Col>
                          <Col sm="6">
                              <ReactQuill 
                                id="taskAttachmentsText" 
                                onChange={(e)=>this.setState({taskAttachmentsText:e})}
                                className={this.state.detailError? 'field-error' : ''}
                                placeholder= "Add the file details or links" 
                                theme="snow" 
                                modules={this.minimodules}
                              />
                            </Col>
                            <Col sm="12" >
                                {(this.state.taskAttachments && this.state.taskAttachments.length>0) &&
                                (
                                  <div className='reminder-options custom_radio options-items-parent d-flex'>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.taskAttachmentTextOptions == 1 && 'checked'}  id="taskAttachmentscombined" 
                                      type="radio" name="taskAttachmentTextOptions" value="1" onClick={this.handClickUpdate} />
                                      <Label for="taskAttachmentscombined">Combined instructions for all attachments</Label>
                                    </div>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.taskAttachmentTextOptions == 2 && 'checked'} id="taskAttachmentsseparate" 
                                      type="radio" name="taskAttachmentTextOptions" value="2" onClick={this.handClickUpdate}  />
                                      <Label for="taskAttachmentsseparate">Separate instructions for attachments</Label>
                                    </div>
                                  </div>
                                )}
                                {(this.state.taskAttachments && this.state.taskAttachments.length>0 && !this.state.taskAttachmentsChange) &&
                                (
                                  <LightboxPop 
                                  attachments={this.state.taskAttachments} 
                                  deleteitem="yes"
                                  deletefiles={this.deletefiles} 
                                  arrayname={'taskAttachments'}
                                  listtype={this.state.taskAttachmentTextOptions == 2 ? this.state.taskAttachmentTextOptions : 1}
                                  taskAttachmenttextarray={this.state.taskAttachmentTextArray}
                                  updatefiletext={this.updateFileText}
                                  />
                                )}
                            </Col>
                        </Row>
                      </div>
                    )}
                    
                </>
               
              <Row>
                <Col xs={12} sm={4}>
                <div className="form-group mt-4">
                  <label>Due Date and Time</label>
                  {this.state.pastDueError &&
                  (
                    <div className='past-date'>You can not set a past time.</div>
                  )}
                  <Datetime  Placement="top"
                  className={this.state.errors.includes('dueDate')? 'field-error text_box duedate_placement' : 'text_box duedate_placement'}
                  dateFormat="DD-MM-YYYY"
                  value={this.state.dueDate}
                  onChange={this.setDueDate}
                  inputPops={{disabled: true}}
                  />
                </div>
                </Col>
               
                 
                 {((this.state.projectDetails.graphics_pack>0 && this.state.projectDetails.video_pack >0) ||
                   (this.state.projectDetails.graphics_video_pack >0) || 
                   (this.state.projectDetails.video_pack>0 && this.state.projectDetails.graphics_video_pack>0) ||
                   (this.state.projectDetails.graphics_pack>0 && this.state.projectDetails.graphics_video_pack>0)) &&
                   (
                    <Col xs={12} sm={4} className="mt-4">
                  <div className="task_type_box">
                  <label>Card Type</label>
                  <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card  Task_Type mb-2">
                  
                  
                       <FormGroup>
                              <Input name="TaskType" type="radio" value='1' id="graphics" 
                              checked={this.state.TaskType === '1' && 'checked'} onClick={this.handleChange}/>
                              <label for="graphics">Graphics</label>
                            </FormGroup>
                            <FormGroup>
                              <Input name="TaskType" type="radio" value='2' id="video" 
                              checked={this.state.TaskType === '2' && 'checked'} onClick={this.handleChange}/>
                              <label for="video">Video</label>
                          </FormGroup>

                          {(this.state.TaskType && this.state.TaskType>0) &&
                          (
                            <div className="d-flex clear-selection" name="tasktype-clear" onClick={this.clearSelection.bind(this,'tasktype-clear')}>Clear Selection</div>
                          )}
                          </div>
                          </div>
                          </Col>
                          ) }
                    {/* <label>Priority</label>
                    <select className="choose_temp" name="priority"  onChange={this.handleChange}>
                      <option value="">Select</option>
                      <option value="1">Critical</option>
                      <option value="2">Urgent</option>
                      <option value="3" >High</option>
                      <option value="4">Medium</option>
                      <option value="5">Low</option>
                    </select> */}
                
                
                <Col xs={12} sm={4}>
                  <div className="form-group priority_checkbox">
                  <FormGroup>
                    <Input type="checkbox" id="taskpriority" name='priority'
                    onClick={(e) => this.handlePriority(e)} />
                    <label for="taskpriority">Priority Card</label>
                  </FormGroup>
                  </div>
                 </Col>
              </Row>  
              {this.state.projectDetails && this.state.projectDetails.onboard_360 ===1 &&
              (
                <Row>
                  <Col>
                    <NewConnectionWith360 
                    boarded360={this.state.projectDetails.onboard_360}
                    updateCardInfo={this.updateCardInfo}
                    updateCardInfoCancel={this.updateCardInfoCancel}
                    errors={this.state.errors}
                    />
                  </Col>
                </Row>
              )}
               {/* <div className="form-group">
                  <Label htmlFor="ticket-date-field" className="ticket-date-title remind-at reminder_new_task">Remind Me </Label>
                  <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2">
                    <div>
                      <Input type="radio" name="reminderOptions" id="1" className="first-item" value="1" 
                      onChange={this.setReminderTimeOptions.bind(this)} checked={this.state.reminderOptions === '1' && 'checked' }/>
                      <label for="1">1 hour </label>
                    </div>
                    <div>
                      <Input type="radio" name="reminderOptions" id="2"  value="2" 
                      onChange={this.setReminderTimeOptions.bind(this)} checked={this.state.reminderOptions === '2' && 'checked' } />
                      <label for="2">2 hours</label>
                    </div>
                    <div>
                      <Input type="radio" name="reminderOptions" id="3" value="3" 
                      onChange={this.setReminderTimeOptions.bind(this)}  checked={this.state.reminderOptions === '3' && 'checked' } />
                      <label for="3">3 hours</label>
                    </div>
                    <div>
                      <Input type="radio" name="reminderOptions" id="5" value="5" 
                      onChange={this.setReminderTimeOptions.bind(this)} checked={this.state.reminderOptions === '5' && 'checked' } />
                      <label for="5">5 hours</label>
                    </div>
                    <div>
                      <Input type="radio" name="reminderOptions" id="6" value="24" 
                      onChange={this.setReminderTimeOptions.bind(this)}  checked={this.state.reminderOptions === '24' && 'checked' }/>
                      <label for="6">1 day</label>
                    </div>
                    <div>
                      <Input type="radio" name="reminderOptions" id="7" value="48" 
                      onChange={this.setReminderTimeOptions.bind(this)}  checked={this.state.reminderOptions === '48' && 'checked' } />
                      <label for="7">2 days</label>
                    </div>
                    {(this.state.reminderOptions && this.state.reminderOptions !='') &&
                    (
                      <div className="d-flex clear-selection" name="Reminder-clear" onClick={this.clearSelection.bind(this,'Reminder-clear')}>Clear Selection</div>
                    )}
                  </div> 
                  <div>
                    <Datetime
                      dateFormat="DD-MM-YYYY"
                      value={this.state.reminderTime}
                      onChange={this.setReminderime}
                    />
                  </div>         
                </div> */}
                {this.state.draftAssigneWarning &&
                  (
                    <div className='remove-assignee'>You cannot add an assignee, if you are saving this task as draft.</div>
                  )}
                <div className="form-group btn_sec">
                  <div className="d-flex">
                    <Button disabled={this.state.saveupdateProgress && 'disabled'} className="sd_btn" onClick={this.saveNewTask.bind(this, 2)}>Save to Drafts</Button>
                  </div>
                  <div className="d-flex">
                    <Button className="cancel_btn">Cancel</Button>
                    <Button disabled={this.state.saveupdateProgress && 'disabled'} type="submit" className={`save_btn task_save_btn  ${this.state.saveupdateProgress && ' disable-action'}`} onClick={this.saveNewTask.bind(this, 1)}>Save</Button>
                  </div>
                </div> 
            </div>
          </Col>
          </Row>
          </div>
          </>
          )}    
        </LoadingWrapper>  
       </div>
      </Row>

          
         
               
      </Container>
    </Fragment>
    )
  }
}
