import React, {useState, useEffect, useRef, useCallback} from 'react';
import ReactTags from 'react-tag-autocomplete'

export default function CanvaUsers(props)
{   
    const [tagslist, setTagsList] = useState(props.tagslist);
    const [tags, setTags] = useState(props.tags);
    const reactTags = useRef();

    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex))
      }, [tags])
    
    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag])
      }, [tags])

    useEffect(() => {
        props.settags(tags);
      },[tags]);

      function showSuggestions()
      {
        props.onfocus && props.onfocus();
      }

     

      function TagComponent({ tag, removeButtonText, onDelete }) {
        return (
          <div className={`react-tags__selected ${tag.name}`} aria-relevant="additions removals" aria-live="polite">
            <button type="button" onClick={onDelete}  class="react-tags__selected-tag" title="Click to remove tag">
              <span class="react-tags__selected-tag-name">{tag.name}</span>
            </button>
          </div>
        )
      }

    return (
      <>
        <ReactTags  className="text_box" 
        ref={reactTags}
        tags={tags}
        suggestions={tagslist}
        onDelete={onDelete}
        onAddition={onAddition}
        placeholderText="Add new user"
        onFocus={showSuggestions}
        tagComponent={TagComponent} />
       </>
    );
}