import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactExport from "react-export-excel";
import { Button} from 'reactstrap';

export default function ExcelReportLop({downloadData, selectedcountry=''}) {
    const ref = useRef()
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const dataSet1 = downloadData;

    useEffect(() => {
        const checkIfClickedOutside = e => {
           if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
            setIsMenuOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isMenuOpen])

  return (
    <div className="filter_salary_box2" ref={ref}>
        <ExcelFile filename="LOP-Details" element={<li className="list-item">Export LOP Data</li>}> 
       
        <ExcelSheet data={dataSet1} name="Employees">
            <ExcelColumn label="name" value="name"/>
            <ExcelColumn label="recipientEmail" value="recipientEmail"/>
            <ExcelColumn label="Lop Days" value="lopdays"/>
            <ExcelColumn label="Lop Amount" value="lop"/>
        </ExcelSheet>
        </ExcelFile>
    </div>
  )

}


