import React,{useState, useEffect} from "react"; 
import {Button} from "reactstrap";
import MaterialIcon from "material-icons-react";
export default function ClipboardCopyCommentLink({ copyText, copyaction }) {
    const [isCopied, setIsCopied] = useState(false);
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
    const handleCopyClick = () => {
      copyTextToClipboard(copyText)
        .then(() => {
          setIsCopied(true);
          copyaction(true);
          setTimeout(() => {
            setIsCopied(false);
            copyaction(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return (
        <span className="copy_link_box d-flex justify-content-flex-start" onClick={handleCopyClick}>
            {isCopied ? 'Copied': (<><MaterialIcon  icon="link"/> <p>Copy Link</p></>)}
        </span>
    );
  }