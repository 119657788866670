import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { urls } from "../../services/UrlList";
import axios from 'axios';

// fetchings
const paymentUsers = async (selectedMonth) => {
    return await axios.post(urls.getMontlyPayUsers, {'selectedmonth': selectedMonth});
}

const paymentInfoSoft = async (data) => {
    return await axios.post(urls.getMontlyPayUserInfoSoft, {'data': data, 'user_id': data.user_id, 'monthselected': data.monthselected});
}

const paymentInfoDeep = async (data) => {
    return await axios.post(urls.getMontlyPayUserInfoDeep, data);
}

const getSalaryMonths = async() => {
    return await axios.get(urls.getSalaryMonths);
}

//hooks

export const usePaymentUsersSoft = (selectedMonth) => {
    return useQuery(['payment-users-soft'], () =>  paymentUsers(selectedMonth),
    {
        staleTime: 0,
        enabled:false,
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}

export const usePaymentUsersDeep= (selectedMonth) => {
    console.log("deep call");
    return useQuery(['payment-users-deep'], paymentUsers(selectedMonth),
    {
        enabled:false,
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}


export const useGetPaymentInfoSoft = (user, monthselected) => {
    let data = user;
    data.monthselected = monthselected;
    return useQuery(['payment-info-soft', user.user_id+"-"+monthselected], () =>  paymentInfoSoft(data),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}

export const useGetPaymentInfoDeep = (user, monthselected) => {
    let data = user;
    data.monthselected = monthselected;
    return useQuery(['payment-info-deep', user.user_id+"-"+monthselected], () =>  paymentInfoDeep(data),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}


export const useGetSalaryMonths = () => {
    return useQuery(['salary-months'], () =>  getSalaryMonths(),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false,
        retry: 1
    })
}
