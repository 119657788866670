import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import FilterLimit from "../filter/FilterLimit";
import ClientReportFilter from "../filter/ClientReportFilter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import BasecampIcon from '../svgicon/BasecampIcon';
import TrelloIcon from '../svgicon/TrelloIcon';

export default class OverLimitReport extends Component {
  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',    
      pmPls:[], 
      showProgress: false,    
      showProgressSearch: false,
      orginalEntries:[],   
      detailEntries:[],
      board_id:'',
      showTaskPeopleId:'',
      showTaskPeople:'',
      selectedPm:'',     
      show_detail:false,
      show_detail_id:0,     
      startDate: null,
      endDate: null,                         
      showMenu:true,   
      searchingFor:0  
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Overlimit Report";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    this.reloadChecker(); 
    this.getPmPls();
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  reloadChecker = () =>
  {
    if(this.props.location.search === '?redirect=1')
    {
      this.props.history.push("/tick-report");
    }
  }  

  getPmPls = () => {
    fetch(urls.getPmPls, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(response => {
      this.setState({pmPls: response.result})
    })
    .catch(error => {
      this.setState({ showProgress: false });
    });
  };

  getOverLimitReport = (selectedPm, start_date, end_date) => {
    this.calcNumberofDays(start_date,end_date);
    const _this = this;
    setTimeout(function() {     
      _this.setState({
        orginalEntries: [],
        showProgress:true,
        startDate:start_date,
        endDate:end_date
      })
      let opts = {
      start_date: start_date,
      end_date: end_date,
      pm:selectedPm,
      searchfor: _this.state.searchingFor
      };
    console.log(opts);
    fetch(urls.overLimitReport, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
      .then(response => response.json())
      .then(response => {
        _this.setState({
         orginalEntries: response,
         showProgress: false
        });
      })
      .catch(error => {
        _this.setState({ showProgress: false });
      });
    }, 300);
    
  };
  
  searchEntriesProjectDetails = (project) =>
  {  
   if(this.state.board_id != project)
   {
    this.setState({
        detailEntries:[],
        showDetailProgress:true,
        showTaskPeopleId: 0,
        showTaskPeople: false
      });
      let opts = {
        board_id:project,
        start_date: this.state.startDate,
        end_date: this.state.endDate
      };
      this.setState({
         DetailView:false,
         board_id:project
      });
      fetch(urls.postBaseProjectReportsForOverLimit,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {
        this.setState({
          detailEntries:response['results'],
          DetailView:true,
          showDetailProgress:false
         });
      })
      .catch(error =>{
        this.setState({showDetailProgress:false});
      })
    }else
    {
        this.setState({
            DetailView:false,
            board_id:''
         });
    }
  }

  countWeekendDays( d0, d1 )
  {
    var ndays = 1 + Math.round((d1.getTime()-d0.getTime())/(24*3600*1000));
    var nsaturdays = Math.floor( (d0.getDay()+ndays) / 7 );
    return 2*nsaturdays + (d0.getDay()==0) - (d1.getDay()==6);
  }
  
  calcNumberofDays = (sdate,edate) =>
  {
    if(edate!=null && edate!='')
    {
      const date1 = new Date(sdate);
      const date2 = new Date(edate);
      const diffTime = Math.abs(date2 - date1);
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      var weekends = this.countWeekendDays(date1, date2);
      diffDays = diffDays + 1;
      this.setState({searchingFor:(diffDays - weekends) }); 
    }else
    {
      this.setState({searchingFor:1 }); 
    }  
  }

  showTaskPeople = (id)=>
  {
      if(id === this.state.showTaskPeopleId)
      {
          this.setState({
              showTaskPeopleId: id,
              showTaskPeople: !this.state.showTaskPeople
          })
      }else
      {
        this.setState({
            showTaskPeopleId: id,
            showTaskPeople: true
        })
      }
  }

  timeConvert = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
    }

  render() {

    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    let deviceWidth = window.innerWidth;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " project-report"}>
      <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
              <Card className="cl_helth_report">
                <h1 class="page-title text-center her_title">Over Limit</h1>
                <ClientReportFilter 
                  pmpls={this.state.pmPls}   
                  searchentries={this.getOverLimitReport}
                  searchrunning={this.state.showOnebyOneProgress}
                  stopactivesearch={this.stopActiveSearch} />   
                <div id="list-head" className="project-work-log client-work-log overlimit">
                <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Project Name</th>
                      <th>PM</th>
                      <th>PL</th>
                      <th>Time Spent</th>
                    </tr>
                  </thead>

                  <tbody id="date-list-contianer" className="project-work-log">
                  <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.showProgress}>
                      <motion.section
                        style={{ display:'contents' }}
                        initial="hidden"
                        animate="visible"
                        variants={list} 
                      >
                          {this.state.orginalEntries.map((entries, index) => {
                            return (
                             <>
                             {entries.board_name!= null &&
                             (
                                <motion.div variants={item} style={{ display:'contents' }}>

                     
                                      
                                      <tr onClick={this.searchEntriesProjectDetails.bind(this, entries.board_id)} className={this.state.board_id === entries.board_id && 'active_expand_table'}>
                                        <td>{entries.board_name}
                                         <span className='overlimit-packs'>
                                          G: {entries.graphics_pack}
                                         </span>
                                         <span className='overlimit-packs'>
                                          <b>V: </b>{entries.video_pack}
                                         </span>
                                         <span className='overlimit-packs'>
                                          <b>G+V: </b>{entries.graphics_video_pack}
                                         </span>
                                         <span className='overlimit-packs'>
                                          <b>C: </b>{entries.canva_graphics>0 ? entries.canva_graphics : 0}
                                         </span>
                                         <span className='overlimit-packs'>
                                          <b>CP: </b>{entries.canva_plus>0 ? entries.canva_plus : 0}
                                         </span>
                                        </td>
                                        <td>{entries.pm}</td>
                                        <td>{entries.pl}</td>
                                        <td className="text-right">{this.timeConvert(entries.total)}</td>
                                      </tr> 

                                      {(this.state.board_id === entries.board_id && this.state.showDetailProgress) &&
                                      (
                                        <LoadingWrapper  LoaderCount={5} isLoading={this.state.showDetailProgress}></LoadingWrapper>
                                      )}



                                      {this.state.detailEntries.length>0 && this.state.board_id === entries.board_id  &&
                                        (   
                                            <>
                                             <td colSpan="4" className="expand_th">
                                              <Table responsive className="theme_table expand_table">
                                                     <thead>
                                                           <tr>
                                                         
                                                             <th className="text-center text-color">Shift Day</th>
                                                             <th className="text-center text-color">Total Time</th>
                                                           
                                                           </tr>
                                                         </thead>
                                                         <tbody>
                                                {this.state.detailEntries?
                                                    (
                                                    <>
                                                    { this.state.detailEntries.map( ( entries, index ) => {
                                                    return (
                                                    <motion.div variants={item} style={{ display:'contents' }}>
                                                    
                                                            <tr onClick={this.showTaskPeople.bind(this, entries.shift_day)}>
                                                              <td className="text-center">{entries.shift_day}</td>
                                                              <td className="text-center">{entries.day_total}</td>
                                                            </tr>

                                                             
                                                            <td colSpan="2" className="expand_th">
                                                            {this.state.showTaskPeople && this.state.showTaskPeopleId === entries.shift_day &&
                                                            (
                                                                <>
                                                                    {entries.items.map((people, index)=>
                                                                    {
                                                                      var trello = people.task_name.split("##");
                                                                    return(
                                                                      <Table responsive className="theme_table inner_table">

                                                                          <tbody className="inner_expand_table">
                                                                            <tr key={index}>
                                                                              <td></td>
                                                                              
                                                                              <td className="threezero_width">{people.task_name}</td>
                                                                              <td className="threezero_width">{people.user_total}</td>
                                                                              <td className="threezero_width">
                                                                              <div className="flex_box">
                                                                              <BasecampIcon  
                                                                              projectid={people.board_id} 
                                                                              taskid={people.task_id}
                                                                              myId={this.state.myId} />
                                                                              {trello.length>1 &&
                                                                              (
                                                                                <a target="_blank" href={"https://trello.com/c/"+trello[1]}><TrelloIcon /></a>
                                                                              )}
                                                                              </div>
                                                                              </td>
                                                                             
                                                                              <td></td>

                                                                            </tr>
                                                                          </tbody>
                                                                        

                                                                      </Table>
                                                                    )}
                                                                    )}
                                                                    
                                                                </>
                                                            )}

                                                               </td>                 
                                                       
                                                    </motion.div>
                                                    )
                                                })
                                                }
                                                    </>
                                                    )
                                                    :
                                                    null
                                                }
                                                </tbody>
                                              </Table>
                                            </td>
                                            </>
                                        )}
                                  
                                  
                                
                              </motion.div>
                             )}
                            </>
                            );
                          })}
                      </motion.section>
                  </LoadingWrapper>
                  </tbody>
                </Table>
            </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Row>
    
      </Container>
      </div>
    )
  }
}
