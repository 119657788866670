import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { saveAs } from "file-saver";
import Hashids from 'hashids';

const updateSuccess = () => toast('Updated successfully');

// fetchings
const getFetchCanvaAssets = async() => {
    return await axios.get(urls.getCanvaAccountAssets);
}

const canvaAccountUpdate = async(data) =>
{
    return await axios.post(urls.updateCanvaAccount, data);
}

const canvaAccountDelete = async(data) =>
{
    return await axios.post(urls.deleteCanvaAccount, data);
}


export const useFetchCanvaAssets = () => {
    return useQuery(['canva-assets'], () => getFetchCanvaAssets(),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}

export const useCanvaAccountUpdate = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(canvaAccountUpdate, {
        onSuccess: (response) => {
            updateSuccess();
            queryClient.invalidateQueries(['canva-assets']);
        }
    });
}


export const useCanvaAccountDelete = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(canvaAccountDelete, {
        onSuccess: (response) => {
            updateSuccess();
            queryClient.invalidateQueries(['canva-assets']);
        }
    });
}