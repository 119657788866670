import React, { useState, useEffect } from "react";
import "./basecampmodule.css";
import { useGetBestDesignsLog} from "./useBoardHooks";
import { Row, Col } from "reactstrap";

export default function BestDesignsLog(props) {
  const {
    isLoading : logLoading,
    isError,
    error,
    isFetching: logFetching,
    data: bestdesignlogs,
  } = useGetBestDesignsLog(props.taskid);

  if (logLoading || logFetching) {
    return <div style={{'marginTop':'25px'}}>Loading....</div>;
  } else
    return (
      <div className="best-designs-log-wrap">
        <h6>Logs</h6>
        {bestdesignlogs?.data.logs.map((item, index)=>
        {
            return(
                <Row>
                    <Col sm="8" >
                        {item.action_log}
                    </Col>
                    <Col sm="4" >
                        {item.action_happened}
                    </Col>
                </Row> 
            )
        })}
        {bestdesignlogs?.data.logs.length===0 && "No logs found."}
      </div>
    );
}
