import React, { Component, Fragment } from "react";
import "./LoanContainer.css";
import { Container, Row, Col,Card,CardTitle } from "reactstrap";
import PageTitle from "../../page-title/PageTitle";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import Header from "../../header/Header";
import LoanDetails from "../LoanDetails/LoanDetails";
import LoanApply from "../LoanApply/LoanApply";
import LoanMakePay from "../LoanMakePay/LoanMakePay";
import LoanSummery from "../LoanSummery/LoanSummery";
import {urls} from "../../../services/UrlList";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ApplyLoan from '../LoanApply/LoanApply'
export default class LoanContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showApply: false,
      showRepay: false,
      myPrivileges: [],
      myId: "",
      loading:false,
      summary:[],
      summaryusd:[],
      refresh:false,
      showSummary:true,
      progressBar:true,
      showMenu:true,
    };
  }

  componentDidMount =() =>
  {
    this.checkUserPrivilege();
    const _this = this;
     setTimeout(function() {
      _this.getLoanSummary();
    }, 1000);   
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,       
      });
    }
  };



  showApply = () => {
    this.setState({ showRepay: false, showApply: !this.state.showApply });

  };

  hideApply = () => {
    this.setState({showSummary: !this.state.showSummary, showApply: false,});
    const _this = this;
     setTimeout(function() {
      _this.setState({showSummary: !_this.state.showSummary});
    }, 100);

  };

  showRepay = () => {
    this.setState({ showApply:false, showRepay: !this.state.showRepay });
  };


  toggleSidebar = () => {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () => {
     this.setState({showMenu: !this.state.showMenu});
  }

  refreshAction = () =>
  {
    this.setState({showSummary: !this.state.showSummary, showRepay: false,});
    const _this = this;
     setTimeout(function() {
      _this.setState({showSummary: !_this.state.showSummary});
    }, 100);
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };
  
  getLoanSummary = () =>
  {

    fetch(urls.getLoanSummary+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      }
      })
      .then(response => response.json())
      .then(response =>{
          this.setState({
              progressBar:false,
              summary:response.summary[0],
              summaryusd:response.summaryusd[0]
          })
      })
      .catch(error =>
      { 
          this.setState({progressBar:false});
      })   
  }

  render() {
    return (
      <div className="fullwidth_mobilemenu">
   <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
    <Row>
        <div className="theme_header">
        <Header {...this.props}  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />  
      
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="loan" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
      <div className="themeContentBody themefullwidthContentBoday">
      <Row>
      <Col sm={12} lg={8}>
          <Card className="themeCard log_timer_card">
                <PageTitle title="My Loans" />
                <LoanDetails
                  loansummary={this.state.summary}
                  loansummaryusd={this.state.summaryusd}
                  showApply={this.showApply}
                  showRepay={this.showRepay}
                  activeApply={this.state.showApply}
                  activeRepay={this.state.showRepay}
                  progress = {this.state.progressBar}
                />
             {this.state.showApply && (
               <LoanApply active={this.state.showApply}  myid={this.state.myId} hideaction={this.hideApply} />
             )}
             {/*this.state.showRepay && (
               <LoanMakePay active={this.state.showRepay} myid={this.state.myId} refreshaction={this.refreshAction}  />
             )*/}
             {(this.state.myId && this.state.showSummary) && (
               <LoanSummery myid={this.state.myId} refresh={this.state.refresh} />
             )}             
          </Card>
        </Col>
        <Col sm={12} lg={4}>
            <Card className="themeCard log_timer_card apply_loan_col">
                <CardTitle tag="h1" className="themeCard_title">Apply Loan</CardTitle>
                <div className="react-tabs">
               
                <LoanApply active={this.state.showApply}  myid={this.state.myId} hideaction={this.hideApply} />
               
                {/* <ApplyLoan active={this.state.showApply}  myid={this.state.myId} hideaction={this.hideApply} /> */}
                </div>
                
            </Card> 
        </Col>
      </Row>
    
      </div>
    </Row>
  </Container>
      
    </div>
    );
  }
}
