import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import "./employeeleavebalance.css"
import DataList from '../datalist/DataList';
import {urls} from "../../services/UrlList";
import LoadingBar from '../common/LoadingBar';
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import LoadingBox from '../LoadingBox/LoadingBox'
import {motion} from 'framer-motion'
import './SwitchUser.css'
import UserPickerName from "./UserPickerName";
import UserTeams from '../filter/userTeams';
import PlList from '../filter/PlList';


export default class EmployeeLeaveBalance extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      showProgress: true,    
      myPrivileges:[],
      showMenu:true,  
      allEmployees:[], 
      allOriginalEmployess:[],  
      initialLoader:true,
      showLeaveIssue:false,
      leaveIssueFetch:true,
      leaveIssues:[],
      showLeaveBalance:true,
      buttonText: 'Show Leave Days',
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Employee Leave Balance";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    setTimeout(() => {
      _this.getAllEmpLeaves(); 
    }, 100);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    if(!login.privileges.includes('am_admin') && !login.privileges.includes('leave_book_access'))
    {
      this.props.history.push("/home");
    }
    if(localStorage.getItem("user") !== null)
    {
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getAllEmpLeaves = () =>{  
    fetch(urls.getAllEmpLeaves,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {      
      
      if (response && response.length > 0) {        
        console.log(response[0].leaves);
        this.setState({
          allEmployees: response[0].leaves,  
          allOriginalEmployess:response[0].leaves,
          initialLoader:false      
        });       
      } 
    })
    .catch(error=>
    {
      this.setState({
        showProgress:false      
      });  
    })
  };
  
  onPeopleChange = (value)=>
  {
    let temp=[];
    let list=[];
    temp = this.state.allOriginalEmployess;
    if(value !='')
      {
        temp.forEach(p1=>
        {
            if(p1.name=== value)
            {
              list.push(p1);
            }
        })
        this.setState({allEmployees:list});
      }else
      {
        this.setState({allEmployees:this.state.allOriginalEmployess});
      }
  }

  showDetails = (id) =>
  {
    if(this.state.myPrivileges.includes('am_admin') || localStorage.getItem("superadmin") !== null)
      this.props.history.push("/admin/employee-leave-balance-details/"+id); 
    else
      this.props.history.push("/reports/employee-leave-balance-details/"+id); 
  }

  getUserLeaveIssue= (id, name, lgth)  =>{  
    let opts ={
      userid: id,
      username:name,
      issuespan: 7
    };
    fetch(urls.getEmployeeLeaveIssuesAll, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response => {   
      this.setState({
        leaveIssues:this.state.leaveIssues.concat(response), 
      });
     if(lgth === 1)
      {
        this.setState({
          leaveIssueFetch:false
        });
      }
    })
    .catch(error=>
    { 
      this.setState({
        showProgress:false      
      });  
      if(lgth === 1)
      {
        this.setState({
          leaveIssueFetch:false
        });
      }
    })
  };

  async findLeaveIssues () {

    let temp=[];
    temp = this.state.allOriginalEmployess;
    var lgth = temp.length;
    this.setState({
      showLeaveIssue:true,
      leaveIssueFetch:true,
    });
    temp.forEach(p1=>
    {
      this.getUserLeaveIssue(p1.id, p1.name, lgth);
      lgth--;
    });
  }

  hideLeaveIssues = ()=>
  {
    this.setState({
      showLeaveIssue:false,
      leaveIssueFetch:false,
    });
  }

  showHideDays = () =>
  {
    this.setState({
      showLeaveBalance: !this.state.showLeaveBalance
    })
  }

  onTeamChange = (teamid) =>
  {
    if(teamid>0)
    {
    let temp=[];
    let list=[];
    temp = this.state.allOriginalEmployess;
    if(teamid >0)
      {
        temp.forEach(p1=>
        {
            if(p1.user_team_id == teamid)
            {
              list.push(p1);
            }
        })
        this.setState({allEmployees:list});
      }else
      {
        this.setState({allEmployees:this.state.allOriginalEmployess});
      }
    }else
    {
      this.setState({allEmployees:this.state.allOriginalEmployess});
    }
  }

  onPlChange = (plid) =>
  {
    if(plid>0)
    {
    let temp=[];
    let list=[];
    temp = this.state.allOriginalEmployess;
    if(plid >0)
      {
        temp.forEach(p1=>
        {
            if(p1.pm_tl_id == plid)
            {
              list.push(p1);
            }
        })
        this.setState({allEmployees:list});
      }else
      {
        this.setState({allEmployees:this.state.allOriginalEmployess});
      }
    }else
    {
      this.setState({allEmployees:this.state.allOriginalEmployess});
    }
  }

  render() {

    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      // <div className="fullwidth_mobilemenu mobileflex">
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
            classname="tick-timer-header"  
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)} 
            quickactionblock={true}/>
        </div>
        <div className="SideNavbar">
          <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody themefullwidthContentBoday">
         <Row>
          <Col xs={12} md={12}>
            <Card className="cl_helth_report">
            <h1 class="page-title text-center her_title">Leave Book</h1>
            <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
              <div className="total-emp-leave">
           
                {this.state.showLeaveIssue?
                (
                <>
                    <Row className="filter-box">
                      <Col sm="12" className="d-flex justify-content-end">
                      <Button className="btn btn-green" name="legend" value="legend" onClick={this.hideLeaveIssues.bind(this)}>Show Leave Book</Button>
                      </Col> 
                    </Row>
                    <Row>
                        {this.state.leaveIssues.map((issues, index)=>
                        {
                          return(
                            <Col sm="4">
                              <Card className="all-leave-issues">
                                <span className="member" onClick={this.showDetails.bind(this, issues.uid)}>{issues.name}</span>
                                <span className="leaves-date">{issues.date}</span>
                              </Card>
                            </Col>
                          )
                        })}
                        {this.state.leaveIssueFetch &&
                        (
                          <Col sm="4">
                            <Card className="all-leave-issues">
                              <LoadingBar />
                            </Card>
                          </Col>
                        )}
                    </Row>
                  </>
                ):
                (
                <>


              <div className="filter-box Project_Work_Log" id="filter">
                <Row> 
                      <Col sm="12" lg="3">
                        <div className="people">
                        <p class="filter-label">User </p>
                        <UserPickerName onPeopleChange={this.onPeopleChange} />
                        </div>
                      </Col>
                      <Col sm="12" lg="4" className="d-flex justify-content-start lb_button_grp">
                          <Button className="btn btn-green btn-narrow btn-switch ld_btn" name="legend" value="legend" onClick={this.showHideDays.bind(this)}>{this.state.showLeaveBalance ? 'Leave Days': 'Leave Balance'}</Button>
                          {(this.state.myPrivileges.includes('am_admin') || localStorage.getItem("superadmin") !== null) &&
                          (
                            <Button className="btn btn-green btn-narrow sli_btn" name="legend" value="legend" onClick={this.findLeaveIssues.bind(this)}>Show Leave Issues</Button>
                          )}
                      </Col> 
                      <Col sm="12" lg="3" className="d-flex justify-content-between ml-auto">
                        <PlList triggerthechange={this.onPlChange} />
                        <UserTeams myid={this.state.myId} baseid={this.state.myBaseId} teamid={this.state.userTeam} usertype={this.state.showPeopleType} onteamchange={this.onTeamChange}  showall={1}/>
                      </Col>
                    </Row>
                </div>
               

                 
                <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th title="Leave Profile">Profile</th>
                      <th title="Team Holiday Country">Country</th>
                      {this.state.showLeaveBalance ?
                        (
                          <>
                              <th title="Earned Leave">EL</th>
                              <th title="Casual Leave">CL</th>
                              <th title="Sick Leave">SL</th>
                              <th title="Personal Leave">PL</th>
                              <th title="Bereavement Leave">BL</th>
                              <th title="Compensatory off">COF</th>
                          </>
                        ):

                        (
                          <>
                              <th title="Earned Leave"></th>
                              <th title="Earned Leave">Monday</th>
                              <th title="Casual Leave">Friday</th>
                              <th title="Sick Leave">Others</th>
                          </>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                
                    {this.state.allEmployees.length>0?
                    (
                        <>
                          {this.state.allEmployees.map((item, index) =>
                          {
                            return(

                        <tr key={index}>
                          <td><span className="emp-name" onClick={this.showDetails.bind(this, item.id)}>{item.name}</span></td>
                          <td>{item.employee_type}</td>
                          <td>{item.country_name}</td>
                          {this.state.showLeaveBalance ?
                                  (
                                  <>
                                  <td>
                                  {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                                        (
                                          <>
                                            {parseFloat(item.earned_leave)} / {parseFloat(item.aloc_earned_leave)}
                                          </>
                                        ):
                                        (
                                          "-"
                                        )
                                      }
                                  </td>
                                  <td>
                                  {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                                        (
                                          <>
                                            {parseFloat(item.casual_leave)} / {parseFloat(item.aloc_casual_leave)}
                                          </>
                                        ):
                                        (
                                          "-"
                                        )
                                      }
                                  </td>
                                  <td>
                                  {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                                        (
                                          <>
                                            {parseFloat(item.sick_leave)} / {parseFloat(item.aloc_sick_leave)}
                                          </>
                                        ):
                                        (
                                          "-"
                                        )
                                      }
                                  </td>
                                  <td>
                                  {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                                          (
                                              "-"
                                          ):
                                          (
                                            <>
                                            {parseFloat(item.general_leave)} / {parseFloat(item.general_aloc)}
                                            </>
                                          )
                                        }
                                  </td>
                                  <td>{parseFloat(item.bereavement_leave)} / {parseFloat(item.aloc_bereavement_leave)}</td>
                                  <td className="text-right">{parseFloat(item.compensatory_off)} / {parseFloat(item.aloc_compensatory_off)}</td>
                                  </>
                                  )
                                  :
                                  (
                                    <>
                                       <td></td>
                                      <td>{item.monday}</td>
                                          
                                      <td>{item.friday}</td>
                                           
                                         
                                      <td className="text-right">{item.others}</td>
                                  </>
                                        
                           )}
                          </tr>

                          )
                          })}
                          </>
                          ):
                        null
                       }
                       
                    </tbody>
                </Table>



                
                </>
                )
                }
              </div>
              </motion.div>
              <LoadingWrapper isLoading={this.state.initialLoader}> </LoadingWrapper>
            </Card>
          </Col>
          
        </Row>
        </div>
      </Row>
          



      </Container>
    </div>
    )
  }
}
