import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactExport from "react-export-excel";
import { Button} from 'reactstrap';

export default function ExcelReportAll({downloadData, selectedcountry=''}) {
    const ref = useRef()
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const dataSet1 = downloadData;

    useEffect(() => {
        const checkIfClickedOutside = e => {
           if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
            setIsMenuOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isMenuOpen])

  return (
    <div className="filter_salary_box2" ref={ref}>
        <ExcelFile filename="Salary-Details" element={<li className="list-item">Export All Data</li>}> 
       
        <ExcelSheet data={dataSet1} name="Employees">
            <ExcelColumn label="Name" value="name"/>
            <ExcelColumn label="Email" value="recipientEmail"/>
            <ExcelColumn label="paymentReference" value="paymentReference"/>
            <ExcelColumn label="receiverType" value="receiverType"/>
            <ExcelColumn label="amountCurrency" value="amountCurrency"/>
            <ExcelColumn label="sourceCurrency" value="sourceCurrency"/>
            <ExcelColumn label="targetCurrency" value="targetCurrency"/>
            <ExcelColumn label="Loan Cutting" value="loan_cutting"/>
            <ExcelColumn label="Salary Advance" value="salary_advance"/>
            <ExcelColumn label="HRA" value="hra"/>
            <ExcelColumn label="Other Payments" value="other_pay"/>
            <ExcelColumn label="LOP" value="lop"/>
            <ExcelColumn label="Working Days" value="workingdays"/>
            <ExcelColumn label="Present Days" value="presentdays"/>
            <ExcelColumn label="Paid Leaves" value="leavedays"/>
            <ExcelColumn label="LOP Days" value="lopdays"/>
            <ExcelColumn label="Calculated" value="hr_finalised_amount"/>
            <ExcelColumn label="HR Finalized" value="hr_finalised_amount"/>
            <ExcelColumn label="HR Note" value="hr_note"/>
            <ExcelColumn label="accountNumber" value="accountNumber"/>
            <ExcelColumn label="addressCountryCode" value="addressCountryCode"/>
            <ExcelColumn label="addressCity" value="addressCity"/>
            <ExcelColumn label="addressFirstLine" value="addressFirstLine"/>
            <ExcelColumn label="addressPostCode" value="addressPostCode"/>
        </ExcelSheet>
        </ExcelFile>
    </div>
  )

}


