import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../../services/UrlList";

class UserPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[],
      selectedUser:'',
    };  
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

   getAllUsers = () =>{  
    let opts ={    
      team_id: this.props.teamid,
      user_id: this.props.myid,
      basecamp_id:this.props.baseid,
      user_type: 1
    } 
    var fUrl = urls.getLoanPeople;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response && response.length > 0) {        
        this.setState({
          orginalUsers: response,          
        });       
      } 
    })
  };


  onChangePeople = (e) => {
    this.setState({ selectedUser:e.target.value});
    setTimeout(() => {
      this.props.peoplechange(this.state.selectedUser);
    }, 100);     
  };
  

    render() {
      return (
        <div className="people date-col">
          <select className="form-control" onChange={this.onChangePeople.bind(this)}>
          <option value="">All People</option>
          {this.state.orginalUsers.map((user, index) =>
          {
            return(
              <option value={user.id}>{user.name}</option>
            )
          })}
          </select>
        </div>
      );
    }
}

export default UserPicker;
