import React, { Component, Fragment } from "react";
import "./LoanSummery.css";
import { InputGroup, Input, Label, Card, Button,Table } from "reactstrap";
import LoanSummeryList from "./LoanSummeryList/LoanSummeryList";
import {urls} from "../../../services/UrlList";
import UserPicker from "../LoanDetails/UserPicker";
export default class LoanSummery extends Component {
  constructor(props) {
    super(props);
    this.state = {
    summarylist:[],
    summarylistFull:[],
    typeFilter:'',
    pendingLoans:[],
    selecteduser: this.props.selectedpeople,
    refreshlist:this.props.refreshlist,
    showApply:false
    };
  }

  
  componentDidMount =() =>
  {
   this.getPendingLoans();
   this.getLoanSummaryList();   
  }

  componentDidUpdate = (prevProps) =>
  {
      if(prevProps.selectedpeople != this.props.selectedpeople && this.state.selecteduser != this.props.selectedpeople )
      {
        //this.setState({selecteduser:this.props.selectedpeople });
         this.getPendingLoans();
         this.getLoanSummaryList();  
      }


      if(prevProps.refreshlist != this.props.refreshlist && this.state.refreshlist != this.props.refreshlist )
      {
        this.setState({refreshlist:this.props.refreshlist });
        this.getPendingLoans();
        this.getLoanSummaryList(); 
      }

  }

  getLoanSummaryList = () =>
  {
    if(this.props.selectedpeople >0)
    {
      var url = urls.getLoanSummaryList+this.props.selectedpeople;
    }else
    {
      var url = urls.getAdminLoanSummaryList;
    }

    fetch(url,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      }
      })
      .then(response => response.json())
      .then(response =>{
          
          this.setState({
              summarylist:response.summary,
              summarylistFull: response.summary,
          })
         
      })
      .catch(error =>
      { 
          this.setState({loading:false});
      })   
  }

  getPendingLoans = () =>
  {    
    if(this.props.selectedpeople >0)
    {
      var url = urls.getMyLoansWaiting+this.props.selectedpeople;
    }else
    {
      var url = urls.getAllLoansWaiting;
    }

    fetch(url,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      }
      })
      .then(response => response.json())
      .then(response =>{        
          this.setState({
              pendingLoans:response.summary,             
          })
         
      })
      .catch(error =>
      { 
          this.setState({loading:false});
      })   
  }

  reviewLoanApplication = (loan_id, action, loan_amount, user_id) =>
  {
      let opts ={
        admin_id: this.props.myid,
        loan_id: loan_id,
        action:action,
        loan_amount:loan_amount,
        user_id:user_id
      };
      fetch(urls.postReviewLoan,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response=> 
      {
            this.getPendingLoans();
            this.getLoanSummaryList();
            this.props.summaryupdate();
      })
      .catch(error =>
      {
          console.log("fail");
      })
  }


  reviewLoanRepayment = (repay_id, action) =>
  {
      let opts ={
        admin_id: this.props.myid,
        repay_id: repay_id,
        action:action
      };
      console.log(opts);
      fetch(urls.postReviewRepay,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response=> 
      {
            this.getPendingLoans();
            this.getLoanSummaryList();
            this.props.summaryupdate();
      })
      .catch(error =>
      {

      })
  }

filterByType=(e)=>
{  
  this.setState({typeFilter: e.target.value}); 
  if(e.target.value !=''){    
      let temp=[];
      temp = this.state.summarylistFull;     
      let list=[];

      if(e.target.value == 1)
      {
        temp.forEach(p1=>
        {            
         if(p1.credit>0) 
         {
           list.push(p1);
         }
        }) 
      }else
      {
        temp.forEach(p1=>
        {           
         if(p1.debit>0) 
         {
           list.push(p1);
         }
        }) 
      }

      this.setState({summarylist:list});   
   
  }else
  {
    this.setState({summarylist:this.state.summarylistFull});  
  }
};




  render() {
 
    return (
      <Card id="loan-summery-wrapper" className="admin-summary-list">
        
        <div className="loan_manange_summery_head">
          <h3 className="title">Transactions</h3>
       </div>
       <Table responsive className="theme_table">
         <thead>
           <tr>
             <th>User</th>
             <th>Date</th>
             <th>Description</th>
             <th>Amount</th>
             <th>
             <Input type="select" className="amount" onChange={this.filterByType.bind(this)} >
              <option value=''>Type</option>
              <option value='1'>Loan</option>
              <option value='2'>Repay</option>
            </Input>
             </th>
             <th>Status</th>
             <th>Actions</th>
           </tr>
         </thead>
   
       
       
        {this.state.typeFilter === '' && this.state.pendingLoans.length>0?
        (
          <>
            {this.state.pendingLoans.map((pending, index) =>
            {
              return(
                  <LoanSummeryList item={pending} key={index} pending='1'  processreview={this.reviewLoanApplication} />
              )
            }
            )}
          </>
        )
        :
        null
        }
        {this.state.summarylist.length>0?
        (
          <>
          { this.state.summarylist.map((item, index) =>
          {
            return(
              <LoanSummeryList item={item} key={index} processreview = {this.reviewLoanRepayment} />
            )
          })}
          </>
        ):
        null
        }       
     
        </Table>
      </Card>
    );
  }
}
