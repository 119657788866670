import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button} from 'reactstrap';
import ExcelReportCalculated from './ExcelReportCalculated';
import ExcelReportVerified from './ExcelReportVerified';
import ExcelReportAll from './ExcelReportAll';
import ExcelReportLop from './ExcelReportLop';

export default function ExcelReport({downloadData, selectedcountry=''}) {
    const ref = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = e => {
           if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
            setIsMenuOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isMenuOpen])

  return (
    <div className="filter_salary_box2" ref={ref}>
         <Button className="export_btn" onClick={() => setIsMenuOpen(oldState => !oldState)}>Export</Button>
            {isMenuOpen && (
                <ul className="list">
                   {selectedcountry!='' && selectedcountry!='All' ?
                   (
                      <ExcelReportCalculated  downloadData={downloadData} selectedcountry={selectedcountry}/>
                   ):
                   (
                    <>
                      <ExcelReportAll downloadData={downloadData} selectedcountry={selectedcountry} />
                      <ExcelReportLop downloadData={downloadData} selectedcountry={selectedcountry} />
                    </>
                   )}
                   
            </ul>
            )}
    </div>
  )
}


