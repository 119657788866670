import React,{useState, useEffect} from 'react';
import './basecampmodule.css';
import {Button, UncontrolledTooltip} from "reactstrap";
import { AccessCheckBlock } from './AccessCheck';
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'linkifyjs/react';
import ConfirmAction from '../admincomment/ConfirmAction';
import moment from "moment";
import MaterialIcon from "material-icons-react";
import { useFetchEmails, useCanvaAccountPick, useCanvaEmailFetch } from './SourceManagement/useQueryHooks';
import toast, { Toaster } from 'react-hot-toast';

export default function CanvaAccess(props)
{
    const [showCanvaEmail, setShowCanvaEmail] = useState(false);
    const [mutateLoading, setMutateLoading]= useState(false);
    const [fetchingEmail, setFetchingEmail] = useState('');

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
      }

    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
          .then(() => {
            // If successful, update the isCopied state value
            toast('Passcode copied to clipboard.');
          })
          .catch((err) => {
            console.log(err);
          });
      }

    const showCanvaBlock =()=>
    {
        setShowCanvaEmail(!showCanvaEmail);
        if(props.canvaaccesstype === 1)
         trigerCanvaAccountPick();
    }

    const trigerCanvaAccountPick = async () => {
        canvaAccountPick();
      };
    const { mutate: canvaAccountPick, data:myaccounts } =  useCanvaAccountPick();

    const {
        isLoading: emailsloading,
        isError,
        error,
        isFetching,
        data: emailsData,
        refetch: fetchEmailsList,
      } = useFetchEmails('1234',  props.email);
    
      const trigerCanvaEmailFetch = async (email) => {
        setFetchingEmail(email);
        const data1 = {
           email:email
           };
           canvaEmailFetch(data1);
      };
     const { mutate: canvaEmailFetch, data:myEmailData } =  useCanvaEmailFetch();

    const filterMessage = (msg)=>
    {
        const text = msg;
        const regex = /^(\d+)\s+(.+)$/; // regex to match the code number and the rest of the text
        const [, code, message] = text.match(regex);

        return(
            <div className='d-flex'>
                <span className='passcode'>
                    {code}
                </span>
                <span className='passcode-msg'>
                    {message}
                </span>
                <span className='passcode-copy d-flex' style={{marginLeft:'auto'}}>
                <span id={"copy1"} href="#" onClick={(e)=>handleCopyClick(code)}><MaterialIcon icon="content_copy" /></span>  
                    <UncontrolledTooltip  placement="top"  target={"copy1"} style={{maxWidth:'600px',width:'auto'}} >
                        Copy Passcode
                    </UncontrolledTooltip >
                </span>
            </div>
        )
    }

    if (emailsloading || isFetching) {
        return <div>Loading....</div>;
    }else

    return(         
        <div>
            {!showCanvaEmail ?
            (
                <Button className="show-all-work-logs" onClick={showCanvaBlock}>Canva Access</Button>
            ):
            (
                <>
                    <h6 className='canva-access-head'>Canva Access</h6>
                    {props.canvaaccesstype==1?
                    (
                        <>
                        {!isFetching && myaccounts?.data.accounts.length>0 && myaccounts?.data.accounts.map((account, index)=>
                        {
                           return(
                            <div className={`d-flex canva-fetch-block ${myaccounts?.data.accounts.length>1 && 'multiple-canva-account'}`}>
                                    <div>
                                        Canva Account: {account.canva_email}
                                    </div>
                                    {(fetchingEmail == account.canva_email && myEmailData) && 
                                    (
                                        <>
                                            <div>
                                                {myEmailData?.data.msg}
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>
                                                    {myEmailData?.data.subject && filterMessage(myEmailData?.data.subject)}
                                                </span>
                                                <span>
                                                    <MaterialIcon icon="content_copy" />
                                                </span>
                                            </div>
                                            {myEmailData?.data.expiry &&
                                            (
                                                <span>
                                                    Valid Till: {myEmailData?.data.expiry}
                                                </span>
                                            )}
                                        </>
                                    )}
                                    <div>
                                        <Button onClick={trigerCanvaEmailFetch.bind(this,account.canva_email)}>Fetch Passcode</Button>
                                    </div>
                            </div>
                           )
                        })}
                        </>
                    ):
                    (
                        <>
                            {emailsData?.data.success === 0 ?
                            (
                                <div className='d-flex canva-fetch-block'>
                                    <div>
                                        {emailsData?.data.msg}
                                    </div>
                                    <div>
                                        Canva Account: {props.email}
                                    </div>
                                    <div>
                                        <Button onClick={fetchEmailsList}>Fetch Passcode</Button>
                                    </div>
                                </div>
                            ):
                            (
                                <div className='d-flex canva-fetch-block'>
                                    <div >
                                        <span>
                                        {emailsData?.data.subject && filterMessage(emailsData?.data.subject)}
                                        </span>
                                    </div>

                                    <span>
                                        Valid Till: {emailsData?.data.expiry}
                                    </span>
                                    <div>
                                        <Button onClick={fetchEmailsList}>Refetch Passcode</Button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            
        </div>
    )
}