import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import {urls} from "../../services/UrlList";
import Dropicon from '../../images/drop_board_icon.png';
import {Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import NewBrand from "./NewBrand";
import Bcplusicon from '../../images/bc_Plus.svg';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ProjectCurrentStatus from './ProjectCurrentStatus';
import ConfirmAction from '../admincomment/ConfirmAction';
import MaterialIcon from "material-icons-react";
import EditBoard from './EditBoard';
import SideMenu from "../NewSideMenu/NewSideMenu";
import MailboxEmail from './MailboxEmail';


const notifyEditSuccessBoard = () => toast('Board updated successfully.');
const notifySuccess = () => toast('New Brand created successfully.');
const notifyError = () => toast('Something went wrong. Please try again.');
const notifyEditSuccess = () => toast('Brand updated successfully.');
const notifyDeleteSuccess = () => toast('Successfully deleted');

export default class Docandfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addmember:false ,
      orginalUsers:[],
      selectedUser:'', 
      addbrand: "",
      showMenu:true,
      dropDownOpen: '',
      myId:'',
      brands:[],
      showAddModel:false,
      editId:'',
      editName:'',
      noAccess:false,
      toggleDropActive:0,
      accessDenied:'',
      projectDetails:[],
      progressBar:true,
      showDeleteConfirmation:false,
      deletionId:0,
      editActive:false,
      showDeleteConfirmation:false,
      deletionId:0,
      showMenu:true,
      noAccessMsg:'Sorry, the project you are looking for is either not exist or don\'t have access',
    };
    this.onReceiveUpdate = this.onReceiveUpdate.bind(this);
    this.reactTags = React.createRef();
  }
//faheem code----------------------------
toggle = (brandid) => {
  this.setState({
     dropDownOpen: !this.state.dropDownOpen,
     toggleDropActive:brandid
  })
}
onReceiveUpdate(newbrand, type) {
  if(type===0)
  {
    this.setState({
        brands: this.state.brands.concat(newbrand)
      });
      notifySuccess();
  }else
  {
    this.getProjectBrands();
    notifyEditSuccess();
  }
}

//-----------------------------------------
  componentDidMount () {
    document.title = "KIMPHUB - Boards";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      _this.getProjectBrands();
    }, 1000);
  };


  componentWillUnmount() {  
    this.getProjectBrands();
  }
  

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name
      });
    }
  };


  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 
  goToHome = () =>
  {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl+"?uuid="+this.state.myId);
  }
  
  goToProjectsHome = () =>
  {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }


  getProjectBrands = () =>
  {
    const data = {
      project_id: this.props.match.params.id,
      owner: this.state.myId,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getProjectBrands, data, {
        headers: headers
      })
      .then((response) => {
          this.setState({
            accessDenied: response.data.valid === 10 ? true : false,
            brands:response.data.brands,
            projectDetails: response.data.valid !== 10 && response.data.project_details[0],
            noAccess: response.data.valid === 0? true:false,
            progressBar:false
          })
      })
      .catch((error) => {
        this.setState({
          accessDenied: true,
          brands:[],
          projectDetails:[],
          progressBar:false
        })
      })
  }

  showAddModel (id, name) {
   this.setState({
     showAddModel:!this.state.showAddModel,
     editId:id,
     editName:name,
     dropDownOpen: false,
     toggleDropActive:0
   })
  }

  resetModal = ()=>
  {
    this.setState({
      showAddModel:false,
      editId:0,
      editName:'',
      dropDownOpen: false,
      toggleDropActive:0
    })
  }

  trashBrandAsk = (brand_id)=>
  {
    this.setState({
      showDeleteConfirmation:true,
      deletionId:brand_id,
      dropDownOpen: false
    })
  }

  trashBrand =()=>
  {
    const data = {
      project_id: this.props.match.params.id,
      brand_id: this.state.deletionId,
      owner: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.trashProjectBrand, data, {
        headers: headers
      })
      .then((response) => {
          this.setState({
            brands:response.data.brands,
            noAccess: response.data.valid === 0? true:false,
          });
          notifyDeleteSuccess();
          this.closeDialogue();

      })
      .catch((error) => {
        this.setState({
          noAccess: true
        })
        notifyError();
      })
  }

  closeDialogue = () => {
    this.setState({
      showDeleteConfirmation: false,
      deletionId:0,
      dropDownOpen: false
    });
  };

  showBrandDetails = (id) =>
  {
    this.props.history.push("/brand/"+this.props.match.params.id+"/"+id+"?uuid="+this.state.myId);
  }

  enableForEditing = ()=>
  {
    this.setState({
      editActive:true,
      editProject:this.state.projectDetails,
      display:true
    })
  }

  editBoard =(project) =>
  {
    this.setState({projectDetails:[]});
    this.setState({projectDetails: project, editActive:false});
    notifyEditSuccessBoard();
  }
  toggleDisplay = () => {
    this.setState({
      editActive:false
    });
  }

  

  closeDialogue = () => {
    this.setState({
      showDeleteConfirmation: false,
      hideshowbox:true
    });
  };

  render() {
    return (
      <div className="fullwidth_mobilemenu">

      <Container fluid className="top-bar-inactive bc_module_container">
     
        <div className="theme_header">
        <Header {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
           <div className="SideNavbar">
           <SideMenu  
            activeitem="project" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
           </div>
          
     
        </div>
    <div className="themeContentBody themefullwidthContentBoday">
       <LoadingWrapper isLoading={this.state.progressBar}>
            {this.state.accessDenied ?
            (
              <>
                <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
              </>
            ):
            (
            <>
              <Row>
                <div className="bc_module">
                  <Col sm="12">
                    <Toaster position="top-right" containerClassName="notifier"
                          toastOptions={{
                          // Define default options
                          className: '',
                        duration: 5000,
                        style: {
                          background: '#363636',
                          color: '#fff',
                        },}}
                      />
                    <div className="bc_Board_wrapper">
                      <h2 class="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
                        {(this.state.projectDetails.board_name && this.state.projectDetails.board_name.length>0) &&
                        (
                          <ProjectCurrentStatus 
                          graphics={this.state.projectDetails.graphics_pack}
                          video={this.state.projectDetails.video_pack}
                          graphics_video={this.state.projectDetails.graphics_video_pack}
                          canva_graphics={this.state.projectDetails.canva_graphics}
                          canva_plus={this.state.projectDetails.canva_plus}
                          whitelabel={this.state.projectDetails.white_label}
                          clientstatus={this.state.projectDetails.client_status} />
                        )}
                      </h2>
                      <p class="board_discrption">{this.state.projectDetails.short_description}</p>
                      <MailboxEmail canva_access={this.state.projectDetails.canva_access} />
                      <h2 className="section_title subtitle">Docs and Files</h2>
                      <div className="page_header_wrap">
                          <Row>
                          <Col sm={6}>
                          {AccessCheckBlock('add_edit_brands') &&
                          (<Button className="addbtn_text popu_add_btn" onClick={this.showAddModel.bind(this,0,'')}>
                            <span className="bc_plusicon">
                              <img src={Bcplusicon} alt="back" />
                            </span> Add Brand  
                          </Button>)
                          }
                          {this.state.showAddModel &&
                          (
                            <NewBrand 
                            showmodal={this.state.showAddModel}
                            cancelModal={this.resetModal}
                            editid={this.state.editId}
                            editname={this.state.editName}
                            onReceive={this.onReceiveUpdate} 
                            projectid={this.props.match.params.id} 
                            myid={this.state.myId}
                            project={this.state.projectDetails}
                            />
                          )}
                          </Col>
                         <Col sm={6}>
                          <div className="search-box">
                              {AccessCheckBlock('edit_project') &&
                                (<a title="Edit Board" className="edit_board_detail_icon" onClick={this.enableForEditing}><MaterialIcon icon="edit"  /></a>)
                              }
                              {AccessCheckBlock('delete_project') &&
                              (<a  title="Delete Board" className="edit_board_detail_icon" onClick={()=>this.deleteProjectAsk(this.state.projectDetails.board_id)}><MaterialIcon icon="delete"  /></a>)
                              }
                              <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this,'/boards'+"?uuid="+this.state.myId)} > <MaterialIcon icon="fact_check"  /> </a>
                              
                              <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a> 
                          </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="breadcrumb_boards">
                        <Breadcrumb>
                          <BreadcrumbItem><a href={"/boards"+"?uuid="+this.state.myId}>Boards</a></BreadcrumbItem>
                          <BreadcrumbItem><a href={'/boardsdetails/'+this.props.match.params.id+"?uuid="+this.state.myId} >Board Details</a></BreadcrumbItem>
                          <BreadcrumbItem active>Doc and Files</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                  </Col>
                  <Col sm="12">
                      <div className="brands_wrapper">
                      {this.state.brands && this.state.brands.length>0 
                        ? this.state.brands.map((brand) => (
                            <div  className="brands_box card" key={brand.brand_id}>
                              {(AccessCheckBlock('add_edit_brands') || AccessCheckBlock('delete_brands')) &&
                              (<div className="dropdownboard" id="brand_drop">
                                <div className="quq_acton_drodown">
                                    <button className="quick_btn" onClick={this.toggle.bind(this,brand.brand_id)} ><img src={Dropicon} alt="back" />{this.state.dropDownValue}</button>
                                    { this.state.dropDownOpen && this.state.toggleDropActive === brand.brand_id &&
                                    (
                                      <div className="acton_btns"> 
                                        {AccessCheckBlock('add_edit_brands') &&
                                        (<div onClick={this.showAddModel.bind(this,brand.brand_id,brand.brand_name )} dropDownValue="Allotments">Edit</div>)
                                        }
                                        {AccessCheckBlock('delete_brands') &&
                                        (<div onClick={this.trashBrandAsk.bind(this, brand.brand_id)} dropDownValue="GeoAMS">Delete!</div>)
                                        }
                                      </div>
                                      )}
                                  </div>
                              </div>)
                              }
                              <span className="brand-name" onClick={this.showBrandDetails.bind(this,brand.brand_id)} >{brand.brand_name}</span>
                            </div>
                          ))
                        : null}
                      </div>
                    </Col>
                  </div>
                </Row>
            </>
            )}
            </LoadingWrapper>
      </div>
      {this.state.showDeleteConfirmation && (
      <ConfirmAction
        purpose="change"
        closeDialogue={this.closeDialogue}
        show={this.state.showDeleteConfirmation}
        note="*This action cannot be undone, are you sure?"
        content="You are about to delete this asset."
        handledeletion={this.trashBrand}
        resolveid=""
        resolveStatus=""
        adminid=""
        confirmBoxAction="deletebrand"
      />
      )}
       {this.state.editActive &&
      (
        <>
          <div className="overlay_bc" onClick={this.toggleDisplay}></div> 
          <EditBoard userid={this.state.myId} editboard={this.editBoard} project={this.state.projectDetails} />
        </>
      )}
      {this.state.showDeleteConfirmation ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showDeleteConfirmation}
            note="*This action cannot be undone, are you sure?"
            content="You are about to delete this brand."
            handledeletion={this.trashBrand}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deletebrand"
          />
        ) : null}
  </Container>
    </div> 
    )
  }
}
