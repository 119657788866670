import React, { Component, Fragment, useState, useEffect, useCallback, useRef } from 'react';
import Header from '../../header/Header';
import './canvaStyles.css'
import Bcplusicon from '../../../images/bc_Plus.svg';
import CalanderIcon from '../../../images/date-icon.png';
import {urls} from "../../../services/UrlList";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-date-picker';
import { useHistory } from "react-router";
import MaterialIcon from "material-icons-react";
import ReactTags from 'react-tag-autocomplete'
import { useFetchCanvaAssets } from './useCanvaHooks';
import CanvaUsers from './CanvaUsers';
import SingleAccounts from './SingleAccounts';
import toast, { Toaster } from 'react-hot-toast';

export default function CanvaAccounts(props) {
    const [myId, setMyId] = useState(0);
    const [myPrivileges, setMyPrivileges] = useState([]);
    const [showMenu, setShowMenu] = useState(true); 
    const [tags, setTags] = useState([{"id":1,"name":"Tibin Mathew"},{"id":126,"name":"Faheem pp"}])
    const [newAccounts, setNewAccounts] = useState([]);

    const {
        isLoading: assetsLoading,
        data: assetData,
      } = useFetchCanvaAssets();


    const onDelete = useCallback((tagIndex) => {
      setTags(tags.filter((_, i) => i !== tagIndex))
    }, [tags])
  
    const onAddition = useCallback((newTag) => {
      setTags([...tags, newTag])
    }, [tags])


    useEffect(()=>
    {
        checkUserPrivilege();
    },[])

    useEffect(()=>
    {
        clearItems();
    },[assetData])
    const history = useHistory();
    const checkUserPrivilege = () =>
    {
        var login = JSON.parse( localStorage.getItem("user") );
        if(localStorage.getItem("user") !== null)
        {
            setMyId(login.uid);
            setMyPrivileges(login.privileges);
        }
        if(!login.privileges.includes('i_am_admin'))
        {
            history.push({ pathname: "/home" });
        }
    }

   const signOut = () => {
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            history.push({ pathname: "/" });
          })
          .catch(error => {
            localStorage.clear();
            history.push({ pathname: "/" });  
              fetch(urls.logmeout)
                .then(response => response.json())
                .then(response => {
                  localStorage.clear();
                  history.push({ pathname: "/" });
                })
                .catch(error => {
                  localStorage.clear();
                  history.push({ pathname: "/" });
                });
           
          });
      };
  
    function toggleSidebar()
    {
        setShowMenu(!showMenu);
    }

    function hideMenu()
    {
        setShowMenu(!showMenu);
    }

    function goToHome()
    {
        history.push({ pathname: "/home" });
    }

  
    function goToProjectsHome()
    {
        //this.props.history.push("/boards");
        history.push({ pathname: '/boards' });
    }

    function breadNavigation(murl)
    {
        console.log(murl);
        history.push({ pathname: murl });
    }

    const setNewMembers = ()=>
    {
        let opts={
            board_name:null,
            canva_account:'',
            users:null,
            expiry:null
        }

        let marray =[...newAccounts];
        marray.unshift(opts);
        setNewAccounts(marray);
    }

    const clearItems =()=>
    {
        setNewAccounts([]);
    }
    return (
      // <div className="fullwidth_mobilemenu mobileflex">
      <div className="fullwidth_mobilemenu">
        <Container fluid className={(myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
        <Toaster position="top-right" containerClassName="notifier"
            toastOptions={{
              className: '',
              duration: 3000,
              style: {
                background: '#363636',
                color: '#fff',
              },}}
        />
        <Row>
          <div className="theme_header">
          <Header {...props}  
              classname="tick-timer-header"  
              logout={signOut.bind(this)} 
              onmenuaction={toggleSidebar.bind(this)} 
              quickactionblock={false}/>
          </div>
          <div className="SideNavbar">
            <SideMenu activeitem="admin" showmenu={showMenu} hidemenu={hideMenu.bind(this)} />
          </div>
        <div className="themeContentBody themefullwidthContentBoday canvaAccountpage">
         <Row>
          <Col xs={12} md={12}>
            <Card className="cl_helth_report">
              <div className="sectiontitile">
                <h1>Canva Accounts</h1>
                <button className='addbtn_text' onClick={setNewMembers}>
                  <span className="bc_plusicon"> <img src={Bcplusicon} alt="back" /></span>Add New
                </button>
              </div>
             
              <Table responsive className="theme_table" id='filter'>
              <thead>
                <tr>
                  <th>Board</th>
                  <th>Canva Account</th>
                  <th>Users</th>
                  <th>Expiry</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {assetsLoading ?
              (
                <tr>
                    <td colSpan={5}>
                        Loading...
                    </td>
                </tr>
              ):
              (
                <>
                    {newAccounts?.length>0 &&
                    (
                        <>
                            {newAccounts.map((newaccount, index)=>
                            {
                                return(
                                    <SingleAccounts 
                                        key={index}
                                        userslist={assetData?.data.users}
                                        users={newaccount?.users}
                                        account={newaccount}
                                        boardslist={assetData?.data.boards}
                                    />
                                )
                            })}
                        </>
                    )}
                    {assetData?.data.canva_accounts.map((account, index)=>
                    {
                        return(
                           <SingleAccounts 
                            key={account.id}
                            userslist={assetData?.data.users}
                            users={account?.users}
                            account={account}
                            boardslist={assetData?.data.boards}
                           />
                        )
                    })}
                </>
              )}
               </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        </div>
      </Row>
      </Container>
    </div>
    );
  }

