import React, { useState, useEffect, Fragment } from 'react';
import './salarytable.css';
import { FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import MaterialIcon from "material-icons-react";
import {useGetPaymentInfoSoft} from "./useQueryHooks";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { urls } from "../../services/UrlList";
import axios from 'axios';

export default function SalaryTableItemSoft({updateValues,user, monthselected, handleverification,selectedcountry,checkall}) {
  
  const [changeUpdate, setChangeUpdate] = useState(false)
  const [member, setMember] = useState(user);
  const [verifyaction, setVerifyAction] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [isChecked, setIsChecked] = useState(checkall);

  const [toggleFinalize, setToggleFinalize] = React.useState(false);
  const [hrFinalized, setHrFinalized] = React.useState(""); 
  
  const [toggleLoan, setToggleLoan] = useState(false);
  const [loanAmount, setLoanAmount] = useState(user.loan_cutting); 

  const [toggleAdvance, setToggleAdvance] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState(user.salary_advance); 

  const [toggleOtherPay, setToggleOtherPay] = useState(false);
  const [otherAmount, setOtherAmount] = useState(user.other_pay); 

  const [hrNote, setHrNote] = useState(user.hr_note);

  const { isLoading, isError, error, data, isFetching} = useGetPaymentInfoSoft(user, monthselected);
  useEffect(()=>
  {
    if(!isLoading)
    {
        let info = data.data;
        setMember({...member, ...info});
    }
  },[data])

  function toggleInput() {
    setToggleFinalize(true);
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        updateSalary();
    }
  }

  const handleNoteChange = (e)=>
  {
    setHrNote(e.target.value);
  }
  
  function updateNotes ()
  {
    let membertemp = member;
    membertemp.hr_note = hrNote;
    setMember(membertemp);

      const data = {
          user_id: member.user_id,
          hr_note:hrNote,
          monthselected:monthselected
        };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin':'*',
        }
        axios.post(urls.updateSalaryHrNotes, data, {
            headers: headers
          })
          .then((response) => {
            setIsShown(!isShown);
          })
          .catch((error) => {
            setIsShown(!isShown);
          })
  }

  function updateSalary ()
  {
    const data = {
        user_id: member.user_id,
        hr_finalised_amount:hrFinalized,
        monthselected:monthselected,
        member: member
    };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateSalaryHrFinalized, data, {
        headers: headers
      })
      .then((response) => {
       let membertemp = {...member};
        membertemp.hr_finalised_amount = hrFinalized;
        membertemp.loan_cutting = response.data.loan_cutting;
        membertemp.calculatedpay = response.data.calculatedpay;
        membertemp.hr_finalised_amount = response.data.calculatedpay;
        membertemp.salary_advance = response.data.salary_advance;
        membertemp.other_pay = response.data.other_pay;
        setMember(membertemp);
        setToggleFinalize(false);
        setToggleLoan(false);
        setToggleAdvance(false);
        setToggleOtherPay(false);
      })
      .catch((error) => {
        setToggleFinalize(false);
        setToggleLoan(false);
        setToggleAdvance(false);
        setToggleOtherPay(false);
      })
      updateValues();
  }


  function handleChange(event) {
    let membertemp = member;
    membertemp.hr_finalised_amount = event.target.value;
    setMember(membertemp);
    setHrFinalized(event.target.value);
  }

  const handleClicknote = event => {
    setIsShown(current => !current);
  };

  const handleClick = () => {
    const updatedState = member;
    if (updatedState.other == '0') {
      updatedState.other = '1';
    } else {
      updatedState.other = '0';
    }
    setChangeUpdate(!changeUpdate);
  };
 
  const calculatePay = (memdata) =>
  {
    let salary = memdata.amount;
    let singledaypay = salary/parseFloat(memdata.workingdays);
    let payment = 0, deduction=0;
    if(memdata.newjoinee == 0 && parseFloat(memdata.presentdays)>0)  // old employee
    {
         deduction = parseFloat(memdata.lopdays) * singledaypay;
         //payment = salary - deduction;
         payment = (parseFloat(memdata.presentdays) + parseFloat(memdata.leavedays)) * singledaypay;
    }else if(memdata.newjoinee == 1 && parseFloat(memdata.presentdays)>0)  // new joinee
    {
         payment = (parseFloat(memdata.presentdays) + parseFloat(memdata.leavedays)) * singledaypay;
    }else if(memdata.presentdays == 0 && memdata.leavedays == 0)  // non paying staff
    {
         payment = 0;
    }else if(parseFloat(memdata.presentdays) == 0 && parseFloat(memdata.leavedays) >0 )  // still paying staff due to leave applied
    {
        payment = parseFloat(memdata.leavedays) * singledaypay;
    }
    return Math.round((payment + Number.EPSILON) * 100) / 100;
  }

  const hrVerification = () =>
  {
    setVerifyAction(false);
    const data = {
        user_id: member.user_id,
        hr_finalised_amount: Math.round(member.hr_finalised_amount >0 ? member.hr_finalised_amount : member.calculatedpay),
        hr_verification: member.hr_verification == 0 ? 1 : 0,
        monthselected:monthselected
    };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateSalaryHrVerification, data, {
        headers: headers
      })
      .then((response) => {
        let membertemp = member;
        membertemp.hr_verification = member.hr_verification == 0 ? 1 : 0;
        membertemp.hr_finalised_amount = Math.round(member.hr_finalised_amount> 0 ? member.hr_finalised_amount : member.calculatedpay); 
        setMember(membertemp);
        setVerifyAction(true);
        handleverification();
      })
      .catch((error) => {
        setVerifyAction(true);
      })
  }

  function toggleLoanInput() {
    setToggleLoan(true);
  }

  const _handleLoanKeyDown = (e) => {
    if (e.key === 'Enter') {
       updateSalary();
    }
  }

  function handleLoanChange(event) {
    let membertemp = member;
    membertemp.loan_cutting = event.target.value;
    setMember(membertemp);
    setLoanAmount(event.target.value);
  }

  function toggleAdvanceInput() {
    setToggleAdvance(true);
  }

  const _handleAdvanceKeyDown = (e) => {
    if (e.key === 'Enter') {
       updateSalary();
    }
  }

  function handleAdvanceChange(event) {
    let membertemp = member;
    membertemp.salary_advance = event.target.value;
    setMember(membertemp);
    setAdvanceAmount(event.target.value);
  }


  function toggleOtherPayInput() {
    setToggleOtherPay(true);
  }

  const _handleOtherPayKeyDown = (e) => {
    if (e.key === 'Enter') {
       updateSalary();
    }
  }

  function handleOtherPayChange(event) {
    let membertemp = member;
    membertemp.other_pay = event.target.value;
    setMember(membertemp);
    setAdvanceAmount(event.target.value);
  }
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Fragment key={user.user_id}>
    {(isLoading  || isFetching)?
    (
        <tr>
            <LoadingWrapper isLoading="true" />
        </tr>
    ):
    (
      <tr>
      <td  style={{ cursor: "pointer" }}  className={`payee-name ${member.newjoinee == 1 && 'new-joinee '} ${(parseFloat(member.presentdays) == 0 && parseFloat(member.leavedays) == 0) && 'non-paying '} ${(parseFloat(member.presentdays) == 0 && parseFloat(member.leavedays) >0) && 'still-paying '} `}>
        <span className='d-flex'>
          <span className='salary-all-check-data'>
          {selectedcountry!='' && selectedcountry!='All' &&
          (
            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
          )}
          </span>
          <span onClick={handleClick} >{member.name}</span>
        </span>
      </td>
      <td>{member.targetCurrency} {member.previous_amount}</td>
      <td>{member.targetCurrency} {member.amount}</td>
      <td id={"loan"+user.user_id} className={` ${((member.loan_credit - member.loan_debit>0) && member.loan_cutting<1 ) && 'loan-alert'  } `}>
        {!toggleLoan ? (
          <p onClick={toggleLoanInput}>
            <span >{member.loan_cutting}</span>
            {member.loan_credit- member.loan_debit >0 &&
            (
              <UncontrolledTooltip  placement="top"  target={"loan"+user.user_id} style={{maxWidth:'600px',width:'auto'}} >
                {`Balance: ${member.loan_credit- member.loan_debit}`}
              </UncontrolledTooltip >
            )}
          </p>
        ) : (
          <input type="text" className="hrf_input" value={member.loan_cutting}
          onChange={handleLoanChange} 
          onKeyDown={_handleLoanKeyDown} />
        )}

      </td>
      <td>
        {!toggleAdvance ? (
            <p onClick={toggleAdvanceInput}>
              {member.salary_advance}
            </p>
          ) : (
            <input type="text" className="hrf_input" value={member.salary_advance}
            onChange={handleAdvanceChange} 
            onKeyDown={_handleAdvanceKeyDown} />
          )}
      </td>
      <td id={"lop"+user.user_id} >
        {member.lop}
        <UncontrolledTooltip  placement="top"  target={"lop"+user.user_id} style={{maxWidth:'600px',width:'auto'}} >
          {`LOP Days: ${parseFloat(member.lopdays)}`}
        </UncontrolledTooltip >
      </td>
      <td>{member.hra}</td>
      <td>
        {!toggleOtherPay ? (
            <p onClick={toggleOtherPayInput}>
              {member.other_pay}
            </p>
          ) : (
            <input type="text" className="hrf_input" value={member.other_pay}
            onChange={handleOtherPayChange} 
            onKeyDown={_handleOtherPayKeyDown} />
          )}
      </td>
      <td className={`${(member.calculatedpay>=100000 && member.targetCurrency==='INR') && 'alert-notice'  }  ${(member.calculatedpay>=1000 && member.targetCurrency==='USD') && 'alert-notice'  } `} >{member.targetCurrency} {member.calculatedpay}</td>
      <td style={{width:"140px"}} className={`${(member.calculatedpay>=100000 && member.targetCurrency==='INR') && 'alert-notice'  }  ${(member.calculatedpay>=1000 && member.targetCurrency==='USD') && 'alert-notice'  } `}>
      {!toggleFinalize ? (
        <>
         <p onClick={toggleInput} id={"hrnote1"+user.user_id}>
          {member.targetCurrency} {member.hr_finalised_amount == '0' ? member.calculatedpay :  member.hr_finalised_amount}
         </p>
         {member.hr_note && member.hr_note!='' &&
         (
           <UncontrolledTooltip  placement="top"  target={"hrnote1"+user.user_id} style={{maxWidth:'600px',width:'auto'}} >
               {member.hr_note && member.hr_note!='' ?  member.hr_note : ''}
           </UncontrolledTooltip >
         )}
         </>
      ) : (
        <input type="text" className="hrf_input" value={member.hr_finalised_amount === '0.00' ? member.calculatedpay :  member.hr_finalised_amount}
         onChange={handleChange} 
         onKeyDown={_handleKeyDown} />
      )}
      </td>
      <td>
        <>
        {verifyaction &&
        (
          <>
            {member.hr_verification === 1 ? 
            (<span className='hr_verified' onClick={hrVerification}>Verified</span>)
            :
            (<span className='verifyRight' onClick={hrVerification}>Verify</span>)}
              </>
            )}
        </>
      </td>

      {(member.other && member.other == 1) ? (
          // <tr>
          //   <td colSpan={15}>
          <div className="more_salary_details">
              <div onClick={handleClick}  style={{ cursor: "pointer" }} className='top-name'>{member.name}</div>
              <div style={{position:'relative'}} id={"hrnote"+user.user_id} >
                  <h3>HR Note</h3>
                  <span onClick={handleClicknote}  >
                      {member.hr_note === '' ? "..." : <MaterialIcon icon="visibility"  /> }
                    </span> 
                    {member.hr_note && member.hr_note!='' &&
                    (
                      <UncontrolledTooltip  placement="top"  target={"hrnote"+user.user_id} style={{maxWidth:'600px',width:'auto'}} >
                          {member.hr_note && member.hr_note!='' ?  member.hr_note : ''}
                      </UncontrolledTooltip >
                    )}
                   
                    {isShown && (
                    
                    <div className="add_hr_note">
                        <FormGroup>
                          <Input type="textarea" name="text" value={hrNote}  onChange={handleNoteChange}  />
                      </FormGroup>
                      <Button className="update_btn" onClick={updateNotes}>Update</Button>
                      <Button className="close_btn" onClick={handleClicknote}>close</Button>
                    </div>
                  )}
               </div>
               <div>
                  <h3>Working days</h3>
                  <span>{parseFloat(member.workingdays)}</span>
              </div>
              <div>
                  <h3>Present days</h3>
                  <span>{parseFloat(member.presentdays)}</span>
              </div>
              <div>
                  <h3>Leaves</h3>
                  <span>{parseFloat(member.leavedays)}</span>
              </div>
              <div>
                  <h3>LOP Days</h3>
                  <span>{parseFloat(member.lopdays)}</span>
              </div>
              <div>
                  <h3>Country</h3>
                  <span>{member.addressCountryCode}</span>
              </div>
               <div>
                  <h3>Bank Code</h3>
                  <span>{member.bankCode}</span>
              </div>
              <div>
                  <h3>Branch Code</h3>
                  <span>{member.branchCode}</span>
              </div>
              <div>
                  <h3>Acc.Num</h3>
                  <span>{member.accountNumber}</span>
              </div>
              {/* <div>
                  <h3>Loan Cutting</h3>
                  <span>---</span>
              </div> */}
              {/* <div>
                  <h3>Amount - Source - Target</h3>
                  {member.amountCurrency} - {member.sourceCurrency} - {member.targetCurrency}
              </div> */}
          </div>

          //   </td>
          // </tr>
      ) : null}
    </tr>
    )}
  </Fragment>
  )

}


