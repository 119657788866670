import React, { Component, Fragment, useState, useEffect, useCallback, useRef } from 'react';
import Header from '../../header/Header';
import './canvaStyles.css'
import Bcplusicon from '../../../images/bc_Plus.svg';
import CalanderIcon from '../../../images/date-icon.png';
import {urls} from "../../../services/UrlList";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-date-picker';
import { useHistory } from "react-router";
import MaterialIcon from "material-icons-react";
import ReactTags from 'react-tag-autocomplete'
import { useCanvaAccountUpdate, useCanvaAccountDelete } from './useCanvaHooks';
import CanvaUsers from './CanvaUsers';
import { Spinner } from "reactstrap";
import ConfirmAction from '../../admincomment/ConfirmAction';

export default function SingleAccounts(props) {
    const [tags, setTags] = useState(props.users!=null ? JSON.parse(props.users):[])
    const [accountEmail, setAccountEmail] = useState(props.account.canva_email);
    const [selectedBoardObj, setSelectedBoardObj] = useState(props.account.board_name!=null ? JSON.parse(props.account.board_name):[]);
    const [selectedBoardId, setSelectedBoardId] = useState(props.account.board_id);
    const [expiryDate, setExpiryDate] = useState(props.account.expiry!=null? new Date(props.account.expiry):'');
    const [updateProgress, setUpdateProgress]=useState(false);
    const [deleteProgress, setDeleteProgress]=useState(false);
    const [showAccountDelete, setShowAccountDelete] = useState(false);
   
    const setAssignees = (newtags)=>
    {
        setTags(newtags);
    }

    const onChangeClient = (e) => {
        var size = Object.keys(e).length;
        if(size>0)
        {
          let opts ={
            id:e[0].id,
            name:e[0].name
          }
          var result = [];
          result.push(opts);
          setSelectedBoardObj(result);
          setSelectedBoardId(e[0].id);
         
        }else
        {
            setSelectedBoardObj([]);
            setSelectedBoardId();
        }
      };

      const trigerCanvaAccountUpdate = async () => {
        setUpdateProgress(true);
        const data1 = {
         id: props.account.id,
         canva_email: accountEmail,
         board_id: selectedBoardId,
         board_name: selectedBoardObj,
         users: tags,
         expiry: expiryDate
        };
        canvaAccountUpdate(data1);
        setTimeout(() => {
            setUpdateProgress(false);
          }, 1000);
       
      };
    const { mutate: canvaAccountUpdate } =  useCanvaAccountUpdate();


    const trigerCanvaAccountDelete = async () => {
        setDeleteProgress(true);
        const data1 = {
         id: props.account.id
        };
        canvaAccountDelete(data1);
        setTimeout(() => {
            setDeleteProgress(false);
            setShowAccountDelete(false);
          }, 1000);
       
      };
    const { mutate: canvaAccountDelete } =  useCanvaAccountDelete();

    const closeAccountDialogue=()=>
    {
      setShowAccountDelete(false);
    }

    return (
      <>
        <tr key={props.key}>
        <td> 
            <div className="people date-col">
            <Typeahead
                id="board-picker"
                labelKey="name"
                onChange={onChangeClient}
                options={props.boardslist}
                placeholder="Choose board..."
                selected={selectedBoardObj}
                />
            </div>
        </td>
        <td>
            <Input className='inputAccount' placeholder='email address' onChange={(e)=>setAccountEmail(e.target.value)} value={accountEmail} />
        </td>
        <td>
        <div className="people date-col">
            {/* <ReactTags
                tags={JSON.parse(account?.users)}
                suggestions={assetData?.data.users}
                onDelete={onDelete}
                onAddition={onAddition}
                placeholderText="Add new user"
            /> */}
           <CanvaUsers 
            tagslist={props.userslist}
            settags={setAssignees.bind(this)}
            tags={tags}
            />
        </div>
        </td>
        <td>
        <div className="start-date datepicker-col date_picker_filter">
        <DatePicker
        format="dd-MM-y"
         value={expiryDate}
         onChange={(e)=>setExpiryDate(e)}
        className="dateonlyPicker" 
        calendarIcon={<img className="calander-icon" src={CalanderIcon} width="18" height="19" alt="calander-icon" />}
        /></div>
        </td>
        <td>
            <button className='delectIconbtn' 
            onClick={()=>{setShowAccountDelete(true);  } }
            >
            {deleteProgress ? 
              <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
              :
              <MaterialIcon icon="delete"  />
              }  
                
            </button>
            <button className='saveBtn' onClick={trigerCanvaAccountUpdate}>
              {updateProgress ? 
              <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
              :'Save'
              }  
            </button>
        </td>
        </tr>
        {showAccountDelete ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeAccountDialogue}
          show={showAccountDelete}
          note="*This action cannot be undone, are you sure?"
          content="You are about to delete this linked account"
          handlecanvaaccountdeletion={trigerCanvaAccountDelete}
          confirmBoxAction="deletecanvaaccount"
        />
      ) : null}
        </>
    );
  }

