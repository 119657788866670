import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactExport from "react-export-excel";
import { Button} from 'reactstrap';

export default function ExcelReportCalculated({downloadData, selectedcountry=''}) {
    const ref = useRef()
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const dataSet1 = downloadData;
    

    
    useEffect(() => {
        const checkIfClickedOutside = e => {
           if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
            setIsMenuOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isMenuOpen])

  return (
    <div className="filter_salary_box2" ref={ref}>
       {selectedcountry=== 'LK' ?
            (
                <>
                    <ExcelFile filename={`Salary-Details-${selectedcountry}`} element={<li className="list-item">Export for Wise</li>}> 
                        <ExcelSheet data={dataSet1} name="Employees">
                            <ExcelColumn label="name" value="name"/>
                            <ExcelColumn label="recipientEmail" value="recipientEmail"/>
                            <ExcelColumn label="paymentReference" value="paymentReference"/>
                            <ExcelColumn label="receiverType" value="receiverType"/>
                            <ExcelColumn label="amountCurrency" value="amountCurrency"/>
                            <ExcelColumn label="amount" value="hr_finalised_amount"/>
                            <ExcelColumn label="sourceCurrency" value="sourceCurrency"/>
                            <ExcelColumn label="targetCurrency" value="targetCurrency"/>
                            <ExcelColumn label="bankCode" value="bankCode"/>
                            <ExcelColumn label="branchCode" value="branchCode"/>
                            <ExcelColumn label="accountNumber" value="accountNumber"/>
                            <ExcelColumn label="addressCountryCode" value="addressCountryCode"/>
                            <ExcelColumn label="addressCity" value="addressCity"/>
                            <ExcelColumn label="addressFirstLine" value="addressFirstLine"/>
                            <ExcelColumn label="addressPostCode" value="addressPostCode"/>
                        </ExcelSheet>
                    </ExcelFile>
                </>
            ):
            (
                <>
                {selectedcountry=== 'IN' ?
                (
                    <ExcelFile filename={`Salary-Details-${selectedcountry}`} element={<li className="list-item">Export for Wise</li>}> 
                        <ExcelSheet data={dataSet1} name="Employees">
                            <ExcelColumn label="name" value="name"/>
                            <ExcelColumn label="recipientEmail" value="recipientEmail"/>
                            <ExcelColumn label="paymentReference" value="paymentReference"/>
                            <ExcelColumn label="receiverType" value="receiverType"/>
                            <ExcelColumn label="amountCurrency" value="amountCurrency"/>
                            <ExcelColumn label="amount" value="hr_finalised_amount"/>
                            <ExcelColumn label="sourceCurrency" value="sourceCurrency"/>
                            <ExcelColumn label="targetCurrency" value="targetCurrency"/>
                            <ExcelColumn label="ifscCode" value="bankCode"/>
                            <ExcelColumn label="accountNumber" value="accountNumber"/>
                            <ExcelColumn label="addressCountryCode" value="addressCountryCode"/>
                            <ExcelColumn label="addressCity" value="addressCity"/>
                            <ExcelColumn label="addressFirstLine" value="addressFirstLine"/>
                            <ExcelColumn label="addressPostCode" value="addressPostCode"/>
                        </ExcelSheet>
                    </ExcelFile>
                ):
                (
                    <>
                        {selectedcountry=== 'ID' ?
                        (
                            <ExcelFile filename={`Salary-Details-${selectedcountry}`} element={<li className="list-item">Export for Wise</li>}> 
                                <ExcelSheet data={dataSet1} name="Employees">
                                    <ExcelColumn label="name" value="name"/>
                                    <ExcelColumn label="recipientEmail" value="recipientEmail"/>
                                    <ExcelColumn label="paymentReference" value="paymentReference"/>
                                    <ExcelColumn label="receiverType" value="receiverType"/>
                                    <ExcelColumn label="amountCurrency" value="amountCurrency"/>
                                    <ExcelColumn label="amount" value="hr_finalised_amount"/>
                                    <ExcelColumn label="sourceCurrency" value="sourceCurrency"/>
                                    <ExcelColumn label="targetCurrency" value="targetCurrency"/>
                                    <ExcelColumn label="bankCode" value="bankCode"/>
                                    <ExcelColumn label="accountNumber" value="accountNumber"/>
                                    <ExcelColumn label="addressCountryCode" value="addressCountryCode"/>
                                    <ExcelColumn label="addressCity" value="addressCity"/>
                                    <ExcelColumn label="addressFirstLine" value="addressFirstLine"/>
                                    <ExcelColumn label="addressPostCode" value="addressPostCode"/>
                                </ExcelSheet>
                            </ExcelFile>

                        ):
                        (
                            <>
                                <ExcelFile filename={`Salary-Details-${selectedcountry}`} element={<li className="list-item">Export for Wise</li>}> 
                                    <ExcelSheet data={dataSet1} name="Employees">
                                        <ExcelColumn label="name" value="name"/>
                                        <ExcelColumn label="recipientEmail" value="recipientEmail"/>
                                        <ExcelColumn label="paymentReference" value="paymentReference"/>
                                        <ExcelColumn label="receiverType" value="receiverType"/>
                                        <ExcelColumn label="amountCurrency" value="amountCurrency"/>
                                        <ExcelColumn label="amount" value="hr_finalised_amount"/>
                                        <ExcelColumn label="sourceCurrency" value="sourceCurrency"/>
                                        <ExcelColumn label="targetCurrency" value="targetCurrency"/>
                                        <ExcelColumn label="bankCode" value="bankCode"/>
                                        <ExcelColumn label="branchCode" value="branchCode"/>
                                        <ExcelColumn label="accountNumber" value="accountNumber"/>
                                        <ExcelColumn label="addressCountryCode" value="addressCountryCode"/>
                                        <ExcelColumn label="addressCity" value="addressCity"/>
                                        <ExcelColumn label="addressFirstLine" value="addressFirstLine"/>
                                        <ExcelColumn label="addressPostCode" value="addressPostCode"/>
                                    </ExcelSheet>
                                </ExcelFile>

                            </>
                        )}
                    </>
                )}
                </>
            )
            }
    </div>
  )

}


