import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { saveAs } from "file-saver";
import Hashids from 'hashids';


const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );

const getBoardCollectionMaster = async(taskid) => {
    return await axios.get(urls.getBoardCollectionMaster);
}

const updateBoardCollection = async(data) => {
    return await axios.post(urls.updateBoardCollections, data);
}


//hooks
export const useGetBoardCollectionMaster = () =>{
    return useQuery(['board-collections'], () => getBoardCollectionMaster(),
    {
        staleTime: 120000
    })
}

export const useUpdateBoardCollection = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateBoardCollection,{
        onSuccess:(response)=>{
            queryClient.invalidateQueries(['board-collections']);
            
        }
    })
}