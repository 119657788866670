import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Hashids from 'hashids';
import { ta } from "date-fns/locale";

const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );


// fetchings
const getBoardApprovedFiles = async(boardID) => {
    return await axios.post(urls.boardApprovedFiles, {'boardID': boardID});
}

const getTaskClientRating = async(taskID) => {
    return await axios.post(urls.taskClientRating, {'taskID': taskID});
}

const updateBestDesigns = async(data) =>
{
    return await axios.post(urls.updateBestTaskDesigns, data);
}

const getBestDesignsLog = async(taskID) =>
{
    return await axios.post(urls.getTaskbestdesignlog, {'taskID': taskID});
}


export const useGetBoardApprovedFiles = (boardID)=>{
    return useQuery(['board-approved-files-', boardID], () =>  getBoardApprovedFiles(boardID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}


export const useGetTaskClientRating = (taskID)=>{
    return useQuery(['task-rating-', taskID],  ()=>getTaskClientRating(taskID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}



export const useUpdateBestDesigns = (data) => {
    const queryClient = useQueryClient();
    return useMutation(updateBestDesigns, {
        onSuccess: (response) => {
            if(response.data.success == '1')
            {
                toast('Added to best designs successfully.');
            }else if(response.data.success == '2')
            {
                toast('Removed from best designs successfully.');
            }else if(response.data.success == '3')
            {
                toast('sorry, you don\'t have privilege to perfom this action.');
            }
            queryClient.invalidateQueries(['task-rating-', response.data.taskID]);
        }
    });
}


export const useGetBestDesignsLog = (taskID)=>{
    return useQuery(['best-designs-log-',taskID], ()=>getBestDesignsLog(taskID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

