import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import { urls } from "../../services/UrlList";
import axios from 'axios';
import {Container, Row, Col, Input, Button, Label, Breadcrumb, BreadcrumbItem,
  Modal, ModalHeader, ModalBody, FormGroup} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import Bcplusicon from '../../images/bc_Plus.svg';
import moment from "moment";
import AccessDenied from "./AccessDenied";
import toast, { Toaster } from 'react-hot-toast';
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ProjectCurrentStatus from './ProjectCurrentStatus';
import MaterialIcon from "material-icons-react";
import NewTaskList from './NewListTask';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TaskGroupActions from './TaskGroupActions'
import CopyList from './CopyList';
import CopyGroup from './CopyGroup';
import ConfirmAction from '../admincomment/ConfirmAction';
import SideMenu from "../NewSideMenu/NewSideMenu";
import InternalTaskGroupAction from "./InternalTaskGroupAction"
import MoveTodo from './MoveTodo';


const notifyError = () => toast('Something went wrong, please try again.');
const notifyStatus = () => toast('Card status updated');
const notifyList = () => toast('List created successfully');
const notifyGroup = () => toast('Group created successfully');
var ataski = 0;
export default class TasklistbcGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideId:[],
      display: false,
      addmember: false,
      tasksActive: [],
      tasksCompleted: [],
      tasksDraft: [],
      tasksArchived: [],
      tasksAvailable: false,
      projectDetails: [],
      currentView: 1,
      taskStatus: 1,
      myPrivileges: [],
      projectId: this.props.match.params.bid,
      myId: 0,
      myBaseId: 0,
      myName: '',
      accessDenied: false,
      progressBar: true,
      taskActiveUpdateProgress: false,
      taskCompleteUpdateProgress: false,
      modal: false,
      listmodal: false,
      todoModal: false,
      taskgroup: '',
      taskList: '',
      oldList:'',
      oldGroup:'',
      taskGroup: [],
      taskLists: [],
      taskListGroup: [],
      activeList: 0,
      activListGroup: [],
      showList:true,
      listEditId:0,
      groupEditId:0,
      listCopyModal:false,
      listCopyId:'',
      listCopyName:'',
      groupCopyModal:false,
      groupCopyId:'',
      groupCopyName:'',
      showDeleteConfirmation:false,
      deleteListId:0,
      deleteListName:'',
      deleteGroupName:'',
      nonLinkedTasks:false,
      showMenu:true,
      open:false,
      dropdownOpen: false,
      moveTaskModal:false,
      moveTaskId:0,
      updationId:0
    };
  
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.reactTags = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.grouppoptoggle = this.grouppoptoggle.bind(this);
    this.listtoggle = this.listtoggle.bind(this);
    this.todotoggle = this.todotoggle.bind(this);
    this.showHide = this.showHide.bind(this)
    this.toggletask = this.toggletask.bind(this);



    // const element = document.querySelector("#internal_task_list_wrapper");

    // element.addEventListener('wheel', (event) => {
    //   event.preventDefault();

    //   element.scrollBy({
    //     left: event.deltaY < 0 ? -30 : 30,
        
    //   });
    // });

  }

  componentDidMount() {
    document.title = "KIMPHUB - Boards";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    this.getTaskList();
    const _this = this;
    setTimeout(()=>
    {
      document.addEventListener("wheel", _this.trackScrolling);
    }, 300);
  };

  trackScrolling = ()=>
  {
    const element = document.querySelector(".horizontal-tracer");
    const element2 = document.querySelector(".add-card-ex-task");
    element.addEventListener('wheel', (event) => {
      //event.preventDefault();
      element2.scrollBy({
        left: event.deltaY < 0 ? -2 : 2
      });
    });

  }

  toggletask() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }


  toggle(e) {
    this.setState({
      modal: !this.state.modal,
      activeList: e.target.value,
      taskgroup:'', 
      groupEditId: 0
    });
  }

  grouppoptoggle(listid) {
    console.log(listid);
    this.setState({
      modal: !this.state.modal,
      activeList: listid,
      taskgroup:'', 
      groupEditId: 0
    });
  }

  todotoggle(listid, groups, selectedGroupid) {
    this.setState({
      todoModal: !this.state.todoModal,
      activListGroup: groups,
      activeList: listid,
      selectedGroupid: selectedGroupid
    });
  }

  grouptoggle = () => {
    this.setState({
      modal: !this.state.modal,
      activeList: 0
    });
  }

  listtoggle() {
    this.setState({
      listmodal: !this.state.listmodal,
      taskList:'', 
      listEditId: 0
    });
  }

  toggleDisplay() {
    this.setState({
      display: !this.state.display
    });
  }
  togglememberDisplay() {
    this.setState({
      addmember: !this.state.addmember
    });
  }
  handleChangeEditor(value) {
    this.setState({ ticketDetails: value })
  }




  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone: login.my_timezone
      });
    }
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });

      });
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }


  convertTimeFormat = (time) => {
    var timeString = time;
    var H = +timeString.substr(0, 2);
    var h = (H % 12) || 12;
    var ampm = H < 12 ? "AM" : "PM";
    timeString = h + timeString.substr(2, 3) + ampm;
    return timeString;
  }

  getTaskList = async () => {
    const data = {
      project_id: this.props.match.params.bid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskListGroup, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          accessDenied: response.data.success === 10 ? true : false,
          progressBar: false
        })
        let listgroups = this.reArrangeTasks(response.data);
        this.setState({ taskListGroup: listgroups, 
          projectDetails: response.data.project_details[0],
          taskActiveUpdateProgress: false,
          taskCompleteUpdateProgress: false});
      })
      .catch((error) => {
        this.setState({
          progressBar: false,
          accessDenied: true
        })
      })
  };


  reArrangeTasks = (data) => {
    let lists = data.tasks_list;
    let groups = data.tasks_group;
    let tasks = data.tasks;
    if(lists.length<=0 && groups.length<=0 && tasks.length>0)
      this.setState({nonLinkedTasks:true});
    else
      this.setState({nonLinkedTasks:false});
    let temp = [];
    let temp1 = [];
    var l = 0; var g = 0; var t = 0;
    lists.forEach(list =>   // Lists 
    {
      temp[l] = [];
      temp[l] = list;
      let temp1 = [];
      var g = 0;
      temp[l].tasks = [];
      groups.forEach(group =>  // Groups
      {
        if (list.id === group.task_list_id) {
          temp1[g] = [];
          temp1[g] = group;
          temp1[g]['tasks'] = [];
          temp1[g]['tasks_completed'] = [];
        }
        //let temp2=[];
        //var t=0;
        tasks.forEach(task =>  // group tasks 
        {
          if (task.task_list_id === list.id && task.task_group_id === group.id) {
            // if(task.status==1)
            // {
              temp1[g]['tasks'].push(task);
            // }else
            // {
            //   temp1[g]['tasks_completed'].push(task);
            // }
          }
        })
        g = g + 1;
      })
      temp[l]['group'] = temp1.filter(val => val);
      tasks.forEach(task =>  // list tasks 
      {
        if (task.task_list_id === list.id && task.task_group_id === null) {
          temp[l].tasks.push(task);
        }
      })
      l = l + 1;
    })
    return temp;
  }



  goToHome = () => {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  goToProjectsHome = () => {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }

  breadNavigation = (murl) => {
    this.props.history.push(murl+"?uuid="+this.state.myId);
  }

  updateTaskStatus = async (task_id, tid, action) => {
    this.setState({
      taskActiveUpdateProgress: action == 1 ? true : false,
      taskCompleteUpdateProgress: action == 2 ? true : false,
      updationId: task_id
    })
    const data = {
      project_id: this.props.match.params.bid,
      project_name: this.state.projectDetails.board_name,
      task_id: tid,
      tid: task_id,
      creator: this.state.myId,
      status: action,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskStatus, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          //taskListGroup:[],
         // taskActiveUpdateProgress: false,
         // taskCompleteUpdateProgress: false,
        })
        this.getTaskList();
        notifyStatus();
      })
      .catch((error) => {
        notifyError();
        this.setState({
          taskActiveUpdateProgress: false,
          taskCompleteUpdateProgress: false,
        })
      })
  }

  
  handleChangeListGroup = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };


  addNewTaskList = () => {
    this.setState({ errors: '' });
    if (this.state.taskList === '') {
      this.setState({ errors: 'listtitle' });
      return false;
    }
    const data = {
      project_id: this.props.match.params.bid,
      list_name: this.state.taskList,
      listid: this.state.listEditId,
      nonlinkedtasks: this.state.nonLinkedTasks? 1 : 0,
      project_name: this.state.projectDetails.board_name,
      oldList: this.state.oldList
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.addNewTaskList, data, {
      headers: headers
    })
      .then((response) => {
        this.listtoggle();
        notifyList();
        this.getTaskList();
      })
      .catch((error) => {
        notifyError();
      })
  }

  addNewTaskGroup = () => {
    this.setState({ errors: '' });
    if (this.state.taskgroup === '') {
      this.setState({ errors: 'grouptitle' });
      return false;
    }
    const data = {
      project_id: this.props.match.params.bid,
      group_name: this.state.taskgroup,
      list_id: this.state.activeList,
      groupid: this.state.groupEditId,
      project_name: this.state.projectDetails.board_name,
      old_group_name: this.state.oldGroup
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.addNewTaskGroup, data, {
      headers: headers
    })
      .then((response) => {
        this.grouptoggle();
        notifyGroup();
        this.getTaskList();
      })
      .catch((error) => {
        notifyError();
      })
  }

  closetodotoggle = () => {
    this.setState({
      todoModal: !this.state.todoModal,
      activListGroup: '',
      activeList: ''
    });
    this.getTaskList();
  }

  // List
  getListItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",padding: 2 * 2,margin: `0 0 2px 0`,borderRadius: `8px`,
    background: isDragging ? "#ced6e0" : "",
    ...draggableStyle
  });

  getListDropAreaStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",padding: 2 * 2,margin: `0 0 2px 0`,borderRadius: `8px`,
    width: this.state.taskListGroup.length*400+60,
    background: isDragging ? "#ededed" : "",
    ...draggableStyle
  });

  // Group
  getGroupItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",padding: 2 * 2,margin: `0 0 2px 0`,borderRadius: `3px`,
    background: isDragging ? "#95afc0" : "",
    ...draggableStyle
  });

  getGroupDropAreaStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",padding: 2 * 2,margin: `0 0 2px 0`,borderRadius: `3px`,
    background: isDragging ? "#535c68" : "",
    ...draggableStyle
  });

   // Task
   getTaskItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",padding: 2 * 2,margin: `0 0 2px 0`,borderRadius: `8px`,
    background: isDragging ? "#EDF4FA" : "",
    ...draggableStyle
  });

  getTaskDropAreaStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",padding: 2 * 2,margin: `0 0 2px 0`,borderRadius: `3px`,
    background: isDragging ? "#ededed" : "",
    ...draggableStyle
  });

  handleDrop = async (droppedItem) => {
    if (droppedItem.type == "tasks") {
      if (!droppedItem.destination) return;
      console.log(droppedItem.source.droppableId);
      let sitem = droppedItem.source.droppableId.split('-');
      if (sitem.length > 2)   // group tasks 
      {
        let listid = sitem[1];
        let groupid = sitem[2];
        var updatedList = [...this.state.taskListGroup];
        let [removed] = await updatedList[listid].group[groupid].tasks.splice(droppedItem.source.index, 1);
        if(removed == undefined )
        {
          console.log('entered here: '+updatedList[listid].group[groupid].tasks.length);

          let rindex = 0;
          if(updatedList[listid].group[groupid].tasks_completed.length>0)
          {
           //rindex = droppedItem.source.index - (updatedList[listid].group[groupid].tasks_completed.length);
            rindex = droppedItem.source.index - 1;
          }
          // else
          // {
          //   console.log('qqq');
          //   rindex = droppedItem.source.index - (updatedList[listid].group[groupid].tasks_completed.length);
          // }
          [removed] = await updatedList[listid].group[groupid].tasks_completed.splice(rindex, 1);
        }
        let ditem = droppedItem.destination.droppableId.split('-');
        
        let dlistid = ditem[1];
        if(ditem[2] && ditem[2]>=0)
        {
          let dgroupid = ditem[2];
          removed.task_group_id = updatedList[dlistid].group[dgroupid].id;
          removed.task_list_id = updatedList[dlistid].id;

          await updatedList[dlistid].group[dgroupid].tasks.splice(droppedItem.destination.index, 0, removed);
          let newdestinationtasks = updatedList[dlistid].group[dgroupid].tasks;
          this.updateOrderDB([], newdestinationtasks,[]);
        }else
        {
          removed.task_group_id = null;
          removed.task_list_id = updatedList[dlistid].id;
          await updatedList[dlistid].tasks.splice(droppedItem.destination.index, 0, removed);
          let newdestinationtasks = updatedList[dlistid].tasks;
          this.updateOrderDB([], newdestinationtasks,[]);
        }
      } else   // list tasks 
      {
        let listid = sitem[1];

        var updatedList = [...this.state.taskListGroup];
        let [removed] = updatedList[listid].tasks.splice(droppedItem.source.index, 1);
        let ditem = droppedItem.destination.droppableId.split('-');
        let dlistid = ditem[1];
        let dgroupid = ditem[2];
        if(dgroupid>=0)
        {
          removed.task_group_id = updatedList[dlistid].group[dgroupid].id;
          removed.task_list_id = updatedList[dlistid].id;
          updatedList[dlistid].group[dgroupid].tasks.splice(droppedItem.destination.index, 0, removed);
          let newdestinationtasks = updatedList[dlistid].group[dgroupid].tasks;
          this.updateOrderDB([], newdestinationtasks,[]);
        }else
        {
          removed.task_group_id = null;
          removed.task_list_id = updatedList[dlistid].id;
          updatedList[dlistid].tasks.splice(droppedItem.destination.index, 0, removed);
          let newdestinationtasks = updatedList[dlistid].tasks;
          this.updateOrderDB([], newdestinationtasks,[]);
        }
      }
      this.setState({showList:false})
      this.setState({ taskListGroup: updatedList,showList:true });
    }
    else if (droppedItem.type == "groups") {  // groups
      if (!droppedItem.destination) return;
      let sitem = droppedItem.source.droppableId.split('-');
      if (sitem.length > 0)   
      {
        let listid = sitem[1];
        var updatedList = [...this.state.taskListGroup];
        let [removed] = await updatedList[listid].group.splice(droppedItem.source.index, 1);
        let ditem = droppedItem.destination.droppableId.split('-');
        let dlistid = ditem[1];
        removed.task_list_id = updatedList[dlistid].id;
        await updatedList[dlistid].group.splice(droppedItem.destination.index, 0, removed);

        this.setState({showList:false})
        this.setState({ taskListGroup: updatedList, showList:true});
        let newdestinationgroups = updatedList[dlistid].group;
        this.updateOrderDB([], [],newdestinationgroups);
      } 

     } 
     else {     // Listings
      const items = this.reorder(
        this.state.taskListGroup,
        droppedItem.source.index,
        droppedItem.destination.index
      );
      this.setState({showList:false})
      this.setState({ taskListGroup: items,showList:true });
      this.updateOrderDB(items, [], []);
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  updateOrderDB = (listnames, grouptaks, groups) =>
  {
    const data = {
      project_id: this.props.match.params.bid,
      listdata: listnames,
      grouptaks: grouptaks,
      groups: groups
    };
    //return false;
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updatetTaskListOrder, data, {
      headers: headers
    })
      .then((response) => {
      })
      .catch((error) => {
        notifyError();
      })

  }

  showEditOptions = (type,editid, edittext) =>
  {
    console.log(type);
    if(type == 'list')
    {
    this.setState({taskList:edittext, oldList: edittext, listEditId: editid,listmodal: !this.state.listmodal});
    }else if(type == 'group')
    {
    this.setState({taskgroup:edittext, oldGroup: edittext, groupEditId: editid, modal: !this.state.modal,});
    }
  }

  listCopyToggle = (listid, listname) =>
  {
    this.setState({listCopyModal: !this.state.listCopyModal, listCopyId:listid, listCopyName:listname});
  }

  groupCopyToggle = (groupid, groupname) =>
  {
    this.setState({groupCopyModal: !this.state.groupCopyModal, groupCopyId:groupid, groupCopyName:groupname});
  }

  moveTaskToggle = (taskid) =>
  {
    this.setState({moveTaskModal: !this.state.moveTaskModal, moveTaskId:taskid})
  }

  moveTaskUpdate = () =>
  {
    this.getTaskList();
    this.setState({moveTaskModal:false, moveTaskId:0})
  }

  redirecToBoard = (bid)=>
  {
    this.setState({
      groupCopyModal: false, 
      groupCopyId:'', 
      groupCopyName:'',
      listCopyModal: false, 
      listCopyId:'', 
      listCopyName:''
    });
    window.open(urls.notificationUrl+"/internal-task-list/"+bid+"?uuid="+this.state.myId, "_blank");
  }

  deleteListAsk = (listid, listname)=>
  {
    this.setState({showDeleteConfirmation:true, deleteListId:listid, deleteListName: listname});
  }

  deleteGroupAsk = (groupid, groupname) =>
  {
    this.setState({showGroupDeleteConfirmation:true, deleteGroupId:groupid, deleteGroupName: groupname});
  }

  closeDialogue = ()=>
  {
    this.setState({showDeleteConfirmation:false, showGroupDeleteConfirmation:false})
  }

  handleDeleteConfirm = ()=>
  {
    const data = {
     list_id: this.state.deleteListId,
     list_name: this.state.deleteListName,
     project_id: this.props.match.params.bid,
     project_name: this.state.projectDetails.board_name,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.deleteTaskList, data, {
      headers: headers
    })
      .then((response) => {
        this.closeDialogue();
        this.getTaskList();
      })
      .catch((error) => {
        notifyError();
      })
  }

  handleGroupDeleteConfirm = ()=>
  {
    const data = {
     group_id: this.state.deleteGroupId,
     group_name: this.state.deleteGroupName,
     project_id: this.props.match.params.bid,
     project_name: this.state.projectDetails.board_name,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.deleteTaskGroup, data, {
      headers: headers
    })
      .then((response) => {
        this.closeDialogue();
        this.getTaskList();
      })
      .catch((error) => {
        notifyError();
      })
  }

  showHide(id){
    let ids=[...this.state.hideId];
    if(ids.includes(id)) 
    {
      var index = ids.indexOf(id);
      ids.splice(index, 1); 
    }else
    {
      ids.push(id);
    }
    this.setState({hideId:ids})
}
  



  render() {

    return (
      <div className="fullwidth_mobilemenu special_full_width">
        <Container fluid className="top-bar-inactive bc_module_container dsmm-tasks">
          <Row>
            <div className="theme_header">
              <Header {...this.props} className="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={false} />
                <div className="SideNavbar">
                  <SideMenu  
                  activeitem="project" 
                  showmenu={this.state.showMenu} 
                  hidemenu={this.hideMenu.bind(this)}  /> 
                </div>
            </div>
            <Toaster position="top-right" containerClassName="notifier"
              toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                  background: '#363636',
                  color: '#fff',
                },
              }}
            />
            <div className="themeContentBody themefullwidthContentBoday internal_task_wrapper">
              <Row>
                <LoadingWrapper isLoading={this.state.progressBar}>
                  {this.state.accessDenied ?
                    (
                      <>
                        <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
                      </>
                    ) :
                    (
                      <>
                        <Col sm="12">
                          <div className="bc_Board_wrapper">
                            {/* <h2 className="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
                              <ProjectCurrentStatus
                                graphics={this.state.projectDetails.graphics_pack}
                                video={this.state.projectDetails.video_pack}
                                graphics_video={this.state.projectDetails.graphics_video_pack}
                                whitelabel={this.state.projectDetails.white_label}
                                clientstatus={this.state.projectDetails.client_status} />
                            </h2>
                            <p className="board_discrption">{this.state.projectDetails.short_description}</p>
                            <h2 className="section_title subtitle">Cards</h2> */}
                            <div className="page_header_wrap">
                              <Row>
                                <Col sm={6}>
                                  {AccessCheckBlock('add_new_task') &&
                                    (
                                      <>
                                        <button className="addbtn_text" onClick={this.listtoggle}>
                                          <span className="bc_plusicon">
                                            <img src={Bcplusicon} alt="back" />
                                          </span>Add New Card List
                                        </button>
                                      </>
                                    )
                                  }
                                </Col>
                                <Col sm={6}>
                                  <div className="search-box">
                                    <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this, '/boards')} > <MaterialIcon icon="fact_check" /> </a>
                                    <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="breadcrumb_boards">
                            <Breadcrumb>
                              <BreadcrumbItem><a onClick={this.breadNavigation.bind(this, '/boards')}>Boards</a></BreadcrumbItem>
                              <BreadcrumbItem><a onClick={this.breadNavigation.bind(this, '/boardsdetails/' + this.props.match.params.bid)}>Board Details</a></BreadcrumbItem>
                              <BreadcrumbItem active>{this.state.projectDetails.board_name}</BreadcrumbItem>
                              <BreadcrumbItem active>Cards</BreadcrumbItem>
                            </Breadcrumb>
                          </div>
                        </Col>
                        
                        <div className="bc_module add-card-ex-task" id="add-card-ex-task"> 
                        {/* <HorizontalScroll style={{ height:'calc(100vh - 350px)' }}> */}
                          <Col sm="12">
                            <div className="task_list_header">
                              <div className="task_sort_btns">
                                {/* <Button className="draft_btn" onClick={this.getTaskList.bind(this, 1)}>View Active</Button>
                                {AccessCheckBlock('view_draft_tasks') &&
                                  (<Button className="draft_btn" onClick={this.getTaskList.bind(this, 2)}>View Drafts</Button>)
                                } */}
                                {/* {AccessCheckBlock('view_archive_tasks') &&
                                  (<Button className="arch_btn">View Archive</Button>)
                                } */}
                              </div>
                              {/* <div className="search_task_box">
                                <div className="search_bar search">
                                  <Input className="search__text" type="text" name="" placeholder="Search" />
                                  <a href="#" className="search__button"><img src={Serachicon} alt="back" /></a>
                                </div>

                              </div> */}
                            </div>

                            <DragDropContext onDragEnd={this.handleDrop}>
                              <Droppable droppableId="list-container" type="taskList" direction="horizontal">
                                {(provided, snapshot) => (
                                  <div
                                    className="list-container tasklist-container internal_task_list_wrapper"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={this.getListDropAreaStyle(snapshot.isDraggingOver)}
                                    id="internal_task_list_wrapper"
                                  >
                                    {this.state.showList && this.state.taskListGroup.map((list, index) => (
                                      <Draggable key={index} draggableId={String(index)} index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            className="item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            style={this.getListItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <Droppable droppableId={"grouptaskitems-" + index} type="tasks">
                                              {(provided, snapshot) => (
                                                <div
                                                  className="group-task-items"
                                                  {...provided.droppableProps}
                                                  ref={provided.innerRef}
                                                  style={this.getTaskDropAreaStyle(snapshot.isDraggingOver)}
                                                  >

                                                <div className="d-flex align-items-center intask_list_titile">
                                                  <div className="d-flex align-items-center">
                                                 <span className="droppable_icon"><MaterialIcon icon="drag_handle"/></span>
                                                 <h6 className='task-list-name'>{list.list_name}</h6>
                                                 </div>
                                                 <div className="d-flex right_box">
                                                 {AccessCheckBlock('add_new_task') &&
                                                  (
                                                  <div className="taskgroup_btn_footer">
                                                    <Button className="draft_btn adtod_btn" title="Add Card" onClick={this.todotoggle.bind(this, list.id, list.group,0)}><MaterialIcon icon="post_add"/> </Button>
                                                    <Button className="draft_btn adtod_btn addgroup_btn" title='Add Group' value={list.id} onClick={this.grouppoptoggle.bind(this, list.id)}><MaterialIcon icon="queue"/></Button>
                                                  </div>
                                                  )}
                                                 {AccessCheckBlock('add_new_task') &&
                                                 (
                                                 <div className="d-flex">
                                                  <TaskGroupActions 
                                                  showedit={this.showEditOptions}
                                                  editid={list.id}
                                                  edittext={list.list_name}
                                                  type={"list"}
                                                  copytoggle={this.listCopyToggle.bind(this,list.id,list.list_name)}
                                                  deletetoggle={this.deleteListAsk}
                                                   />
                                                  </div>
                                                  )}
                                                </div>
                                                </div>
                                                <div >
                                                <Droppable droppableId={"groupnameitems-"+index+"-"+list.id} type="groups">
                                                  {(provided, snapshot) => (
                                                    <div
                                                      className="group-name-items"
                                                      {...provided.droppableProps}
                                                      ref={provided.innerRef}
                                                      style={this.getGroupDropAreaStyle(snapshot.isDraggingOver)}
                                                      >
                                                  
                                                  {list.group && list.group.length > 0 &&
                                                    (
                                                      <>
                                                        {list.group.map((grp, iindex) => {
                                                          return (
                                                            <Draggable key={iindex} draggableId={grp.group_name + '-' + String(grp.task_list_id)} index={iindex}>
                                                            {(provided, snapshot) => (
                                                              <div
                                                                className="grop_task_list"
                                                                ref={provided.innerRef}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                                {...provided.draggableProps}
                                                                style={this.getGroupItemStyle(
                                                                  snapshot.isDragging,
                                                                  provided.draggableProps.style
                                                                )}
                                                              >
                                                                <div className="d-flex align-items-center intask_group_titile">
                                                                    <div className="d-flex align-items-center">
                                                                      <span className="droppable_icon d-flex"><MaterialIcon icon="drag_handle"/></span>
                                                                      <h6 className="list-group-name">{grp.group_name}</h6>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                    <div className='add-todo-bar'><span onClick={this.todotoggle.bind(this, list.id, list.group, grp.id)}>Add a Card</span></div>
                                                                    {AccessCheckBlock('add_new_task') &&
                                                                    (
                                                                    <div className="d-flex">
                                                                      <TaskGroupActions
                                                                      showedit={this.showEditOptions}
                                                                      editid={grp.id}
                                                                      edittext={grp.group_name}
                                                                      type={"group"}
                                                                      copytoggle={this.groupCopyToggle.bind(this, grp.id, grp.group_name)}
                                                                      deletetoggle={this.deleteGroupAsk}
                                                                      />
                                                                    </div>
                                                                    )}
                                                                   
                                                                    
                                                                    <span  className={this.state.hideId.includes(grp.id) === true && 'expand_icon_active'}  onClick={this.showHide.bind(this,grp.id)}>
                                                                      {this.state.showHide ? 
                                                                      (
                                                                      <span className="toggle_btn_int d-flex"></span>
                                                                      ): 
                                                                      (
                                                                        <span className="toggle_btn_int d-flex"></span>
                                                                      )}
                                                                    </span>
                                                                    </div>
                                                                </div> 
                                                            
                                                              <Droppable droppableId={"grouptaskitems-" + index + "-" + iindex} type="tasks">
                                                                {(provided, snapshot) => (
                                                                  <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                    style={this.getTaskDropAreaStyle(snapshot.isDraggingOver)}
                                                                    className={`group-task-items ${this.state.hideId.includes(grp.id) && 'hidethislist'} `}
                                                                    >
                                                                    {grp.tasks && grp.tasks.length > 0 &&
                                                                      (
                                                                        <div>
                                                                          {grp.tasks.map((gtask, gtindex) => {
                                                                            ataski = gtindex;
                                                                            return (
                                                                              <Draggable key={gtindex} draggableId={grp.group_name + '-' + String(gtask.id)} index={gtindex}>
                                                                                {(provided, snapshot) => (
                                                                                  <div
                                                                                    className="item-container1"
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.dragHandleProps}
                                                                                    {...provided.draggableProps}
                                                                                    style={this.getTaskItemStyle(
                                                                                      snapshot.isDragging,
                                                                                      provided.draggableProps.style
                                                                                    )}
                                                                                  >
                                                                                    <div className="task_box" >
                                                                                     <a href={"/task/detail/"+this.state.projectId+"/"+gtask.tid_cpt+"?uuid="+this.state.myId}>
                                                                                      <div className="task_titile">
                                                                                            <span>{gtask.task_name}</span>
                                                                                          <div className="d-flex">
                                                                                            <div className="priority_badges">
                                                                                              {JSON.parse(gtask.task_label_list).length > 0 &&
                                                                                                (
                                                                                                  <div className="priority_badges">
                                                                                                    {JSON.parse(gtask.task_label_list).map((label, index) => {
                                                                                                      return (
                                                                                                        <span className={`badge_text urgent_badge ${label.name.toLowerCase()}`}>{label.name}</span>
                                                                                                      )
                                                                                                    })}
                                                                                                  </div>
                                                                                                )}
                                                                                            </div>
                                                                                            {gtask.task_priority === 1 &&
                                                                                              (
                                                                                                <span className="priority-list-task">Priority</span>
                                                                                              )
                                                                                            }
                                                                                            {gtask.task_approval_waiting !== 0 &&
                                                                                              (
                                                                                                <span className="priority-list-task tba">TBA</span>
                                                                                              )
                                                                                            }
                                                                                          </div>
                                                                                        </div>
                                                                                      <div className="duedate_time">
                                                                                      <MaterialIcon icon="calendar_today" title="Due Date"/>
                                                                                        <span className="task-items">
                                                                                          {gtask.due_date && gtask.due_date !== '0000-00-00 00:00:00' ? 
                                                                                            (
                                                                                              moment((new Date(gtask.due_date)).getTime()).tz(this.state.myTimezone).format('DD MMM YYYY h:mm A')
                                                                                            ):
                                                                                            (
                                                                                              '---'
                                                                                            )}
                                                                                        </span>
                                                                                      </div>
                                                                                      <div className="assign_person duedate_time">
                                                                                      <MaterialIcon title="Assigned To" icon="shortcut"/>
                                                                                        <span className="task-items">
                                                                                          {JSON.parse(gtask.task_assignee_list).length > 0 ?
                                                                                            (
                                                                                              <div >
                                                                                                {JSON.parse(gtask.task_assignee_list).map((assignee, lindex) => {
                                                                                                  return (
                                                                                                    <span className="task-items" key={lindex}>
                                                                                                      {assignee.name}
                                                                                                    </span>
                                                                                                  )
                                                                                                })}
                                                                                              </div>
                                                                                            ):
                                                                                            (
                                                                                              '---'
                                                                                            )}
                                                                                        </span>
                                                                                      </div>
                                                                                      </a>
                                                                                      {/* <div className="assigned_person duedate_time">
                                                                                        <MaterialIcon title="Assigned By" icon="person"/> 
                                                                                        <span className="task-items">{gtask.name} </span>
                                                                                      </div> */}
                                                                                      <div className="task-status d-flex">
                                                                                        {(AccessCheckBlock('update_task_status')) &&
                                                                                        (
                                                                                          <span className="task-items">
                                                                                           {this.state.updationId === gtask.id && (this.state.taskActiveUpdateProgress || this.state.taskCompleteUpdateProgress) ?
                                                                                            (
                                                                                              <LoadingWrapper isLoading={true}></LoadingWrapper>
                                                                                            ):
                                                                                            (
                                                                                                <>
                                                                                                  {gtask.status===1 ?
                                                                                                  (
                                                                                                    <Input className="update-input taskgrup_chekbox"  value={gtask.tid_cpt} 
                                                                                                    type="checkbox" name="TaskStatus" onClick={()=>this.updateTaskStatus(gtask.id,gtask.tid_cpt,'2')} />
                                                                                                  ):
                                                                                                  (
                                                                                                    <Input className="update-input taskgrup_chekbox" checked={gtask.status==2 && 'checked'} value={gtask.tid_cpt} 
                                                                                                    type="checkbox" name="TaskStatus" onClick={()=>this.updateTaskStatus(gtask.id,gtask.tid_cpt,'1')} />
                                                                                                  )} 
                                                                                                </>
                                                                                            )}
                                                                                                
                                                                                          </span>
                                                                                        )}
                                                                                      </div>
                                                                                      {AccessCheckBlock('add_new_task') &&
                                                                                      (
                                                                                      <div className="d-flex dot-actions">
                                                                                        <InternalTaskGroupAction
                                                                                        showedit={this.showEditOptions}
                                                                                        type={"group"}
                                                                                        copytoggle={this.moveTaskToggle.bind(this,gtask.id)}
                                                                                        deletetoggle={this.deleteGroupAsk}
                                                                                        />
                                                                                      </div>
                                                                                      )}
                                                                                    </div>

                                                                                  </div>
                                                                                )}
                                                                              </Draggable>
             
                                                                            )
                                                                          })}

                                                                        </div>
                                                                      )}
                                                                      {(grp.tasks && grp.tasks.length>0) && (grp.tasks_completed && grp.tasks_completed.length>0) &&
                                                                      (
                                                                        <div className='border-line'>&nbsp;</div>
                                                                      )}
                                                                      {grp.tasks_completed && grp.tasks_completed.length > 0 &&
                                                                      (
                                                                        <div>
                                                                          {grp.tasks_completed.map((gtask, gtcindex) => {
                                                                            return (
                                                                              <Draggable key={gtcindex} draggableId={grp.group_name + '-' + String(gtask.id)} index={gtcindex+(ataski+1)}>
                                                                                {(provided, snapshot) => (
                                                                                  <div
                                                                                    className="item-container1"
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.dragHandleProps}
                                                                                    {...provided.draggableProps}
                                                                                    style={this.getTaskItemStyle(
                                                                                      snapshot.isDragging,
                                                                                      provided.draggableProps.style
                                                                                    )}
                                                                                  >
                                                                                    <div className="task_box" >
                                                                                     <a href={"/task/detail/"+this.state.projectId+"/"+gtask.tid_cpt+"?uuid="+this.state.myId}>
                                                                                      <div className="task_titile">
                                                                                            <span>{gtask.task_name}</span>
                                                                                          <div className="d-flex">
                                                                                            <div className="priority_badges">
                                                                                              {JSON.parse(gtask.task_label_list).length > 0 &&
                                                                                                (
                                                                                                  <div className="priority_badges">
                                                                                                    {JSON.parse(gtask.task_label_list).map((label, index) => {
                                                                                                      return (
                                                                                                        <span className={`badge_text urgent_badge ${label.name.toLowerCase()}`} >{label.name}</span>
                                                                                                      )
                                                                                                    })}
                                                                                                  </div>
                                                                                                )}
                                                                                            </div>
                                                                                            {gtask.task_priority === 1 &&
                                                                                              (
                                                                                                <span className="priority-list-task">Priority</span>
                                                                                              )
                                                                                            }
                                                                                            {gtask.task_approval_waiting !== 0 &&
                                                                                              (
                                                                                                <span className="priority-list-task tba">TBA</span>
                                                                                              )
                                                                                            }
                                                                                          </div>
                                                                                        </div>
                                                                                      <div className="duedate_time">
                                                                                      <MaterialIcon icon="calendar_today" title="Due Date"/>
                                                                                        <span className="task-items">
                                                                                          {gtask.due_date && gtask.due_date !== '0000-00-00 00:00:00' ? 
                                                                                            (
                                                                                              moment((new Date(gtask.due_date)).getTime()).tz(this.state.myTimezone).format('DD MMM YYYY h:mm A')
                                                                                            ):
                                                                                            (
                                                                                              '---'
                                                                                            )}
                                                                                        </span>
                                                                                      </div>
                                                                                      <div className="assign_person duedate_time">
                                                                                      <MaterialIcon title="Assigned To" icon="shortcut"/>
                                                                                        <span className="task-items">
                                                                                          {JSON.parse(gtask.task_assignee_list).length > 0 ?
                                                                                            (
                                                                                              <div >
                                                                                                {JSON.parse(gtask.task_assignee_list).map((assignee, lindex) => {
                                                                                                  return (
                                                                                                    <span className="task-items" key={lindex}>
                                                                                                      {assignee.name}
                                                                                                    </span>
                                                                                                  )
                                                                                                })}
                                                                                              </div>
                                                                                            ):
                                                                                            (
                                                                                              '---'
                                                                                            )}
                                                                                        </span>
                                                                                      </div>
                                                                                      </a>
                                                                                      {/* <div className="assigned_person duedate_time">
                                                                                        <MaterialIcon title="Assigned By" icon="person"/> 
                                                                                        <span className="task-items">{gtask.name} </span>
                                                                                      </div> */}
                                                                                      <div className="task-status d-flex">
                                                                                        {(AccessCheckBlock('update_task_status')) &&
                                                                                        (
                                                                                          <span className="task-items">

                                                                                            {this.state.updationId === gtask.id && (this.state.taskActiveUpdateProgress || this.state.taskCompleteUpdateProgress) ?
                                                                                            (
                                                                                              <LoadingWrapper isLoading={true}></LoadingWrapper>
                                                                                            ):
                                                                                            (
                                                                                                <>
                                                                                                  {gtask.status===1 ?
                                                                                                  (
                                                                                                    <Input className="update-input taskgrup_chekbox"  value={gtask.tid_cpt} 
                                                                                                    type="checkbox" name="TaskStatus" onClick={()=>this.updateTaskStatus(gtask.id,gtask.tid_cpt,'2')} />
                                                                                                  ):
                                                                                                  (
                                                                                                    <Input className="update-input taskgrup_chekbox" checked={gtask.status==2 && 'checked'} value={gtask.tid_cpt} 
                                                                                                    type="checkbox" name="TaskStatus" onClick={()=>this.updateTaskStatus(gtask.id,gtask.tid_cpt,'1')} />
                                                                                                  )} 
                                                                                                </>
                                                                                            )}     
                                                                                          </span>
                                                                                        )}
                                                                                      </div>
                                                                                      {AccessCheckBlock('add_new_task') &&
                                                                                      (
                                                                                      <div className="d-flex dot-actions">
                                                                                        <InternalTaskGroupAction
                                                                                        showedit={this.showEditOptions}
                                                                                        type={"group"}
                                                                                        copytoggle={this.moveTaskToggle.bind(this,gtask.id)}
                                                                                        deletetoggle={this.deleteGroupAsk}
                                                                                        />
                                                                                      </div>
                                                                                      )}
                                                                                    </div>

                                                                                  </div>
                                                                                )}
                                                                              </Draggable>

                                                                            )
                                                                          })}

                                                                        </div>
                                                                      )}
                                                                    {provided.placeholder}

                                                                  </div>
                                                                )}
                                                              </Droppable>
                                                            </div>
                                                             )}
                                                            </Draggable>
                                                          )
                                                        })}
                                                      </>
                                                    )}
                                                  {provided.placeholder}
                                                  </div>
                                                  )}
                                                  </Droppable>
                                                
                                                  {list.tasks && list.tasks.length > 0 &&
                                                    (
                                                      <div className="list-task-items">
                                                        {list.tasks.map((ltask, lindex) => {
                                                          return (
                                                            
                                                              <Draggable key={lindex} draggableId={list.id + String(lindex) + "-" + index} index={lindex}>
                                                                {(provided, snapshot) => (
                                                                  <div
                                                                    className="item-container1 task_box_items"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                    style={this.getTaskItemStyle(
                                                                      snapshot.isDragging,
                                                                      provided.draggableProps.style
                                                                    )}
                                                                  >
                                                                    <div className="task_box" >
                                                                    <a href={"/task/detail/"+this.state.projectId+"/"+ltask.tid_cpt+"?uuid="+this.state.myId}>
                                                                    <div className="task_titile">
                                                                      <span>{ltask.task_name}</span>
                                                                      <div className="d-flex">
                                                                        <div className="priority_badges">
                                                                          {JSON.parse(ltask.task_label_list).length > 0 &&
                                                                            (
                                                                              <div className="priority_badges">
                                                                                {JSON.parse(ltask.task_label_list).map((label, index) => {
                                                                                  return (
                                                                                    <span className={`badge_text urgent_badge ${label.name.toLowerCase()}`} title="Urgent">{label.name}</span>
                                                                                  )
                                                                                })}
                                                                              </div>
                                                                            )}
                                                                        </div>
                                                                        {ltask.task_priority === 1 &&
                                                                          (
                                                                            <span className="priority-list-task">Priority</span>
                                                                          )
                                                                        }
                                                                        {ltask.task_approval_waiting !== 0 &&
                                                                          (
                                                                            <span className="priority-list-task tba">TBA</span>
                                                                          )
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                    
                                                                    <div className="duedate_time">
                                                                    <MaterialIcon icon="calendar_today" title="Due Date" />
                                                                      <span className="task-items">
                                                                        {ltask.due_date && ltask.due_date !== '0000-00-00 00:00:00' ?
                                                                          (
                                                                            moment((new Date(ltask.due_date)).getTime()).tz(this.state.myTimezone).format('DD MMM YYYY h:mm A')
                                                                          ):
                                                                          (
                                                                            '---'
                                                                          )}
                                                                      </span>
                                                                    </div>
                                                                    <div className="assign_person duedate_time">
                                                                      <MaterialIcon title="Assigned To" icon="shortcut"/>
                                                                      <span className="task-items">
                                                                        {JSON.parse(ltask.task_assignee_list).length > 0 ?
                                                                          (
                                                                            <div >
                                                                              {JSON.parse(ltask.task_assignee_list).map((assignee, index) => {
                                                                                return (
                                                                                  <span className="task-items">
                                                                                    {assignee.name}
                                                                                  </span>
                                                                                )
                                                                              })}
                                                                            </div>
                                                                          ):
                                                                          (
                                                                            '---'
                                                                          )}
                                                                      </span>
                                                                    </div>
                                                                    </a>
                                                                    {/* <div className="assigned_person duedate_time">
                                                                      <MaterialIcon title="Assigned By" icon="person"/> 
                                                                      <span className="task-items">{ltask.name} </span>
                                                                    </div> */}
                                                                    <div className="task-status d-flex">
                                                                      <span className="task-items">
                                                                       {this.state.updationId === ltask.id && (this.state.taskActiveUpdateProgress || this.state.taskCompleteUpdateProgress) ?
                                                                        (
                                                                          <LoadingWrapper isLoading={true}></LoadingWrapper>
                                                                        ):
                                                                        (
                                                                            <>
                                                                              {ltask.status===1 ?
                                                                              (
                                                                                <Input className="update-input taskgrup_chekbox"  value={ltask.tid_cpt} 
                                                                                type="checkbox" name="TaskStatus" onClick={()=>this.updateTaskStatus(ltask.id,ltask.tid_cpt,'2')} />
                                                                              ):
                                                                              (
                                                                                <Input className="update-input taskgrup_chekbox" checked={ltask.status==2 && 'checked'} value={ltask.tid_cpt} 
                                                                                type="checkbox" name="TaskStatus" onClick={()=>this.updateTaskStatus(ltask.id,ltask.tid_cpt,'1')} />
                                                                              )} 
                                                                            </>
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                    {AccessCheckBlock('add_new_task') &&
                                                                    (
                                                                    <div className="d-flex dot-actions">
                                                                      <InternalTaskGroupAction
                                                                      showedit={this.showEditOptions}
                                                                      type={"group"}
                                                                      copytoggle={this.moveTaskToggle.bind(this, ltask.id)}
                                                                      deletetoggle={this.deleteGroupAsk}
                                                                      />
                                                                    </div>
                                                                    )}


                                                                    </div>  
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                           



                                                          )
                                                        })}
                                                      </div>
                                                    )}
                                                  </div>
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </Col>
                          {/* </HorizontalScroll> */}
                          {this.state.taskListGroup && this.state.taskListGroup.length>0 &&
                          (
                            <div className='horizontal-tracer' style={{ width: this.state.taskListGroup.length*400+60 }}>
                              <span></span>
                            </div>
                          )}
                        </div>
                        
                      </>
                    )}
                </LoadingWrapper> 
              </Row>
            </div>
          </Row>

        </Container>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className="addtodo_wrapper">
          <ModalHeader value="1" toggle={this.toggle}>
            {this.state.groupEditId>0 ? 'Update Group' : 'Add Group'}
            <Button className="clase_btn_cp" value="1" onClick={this.toggle}><MaterialIcon icon="close" /> </Button></ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} md={12}>
                <FormGroup>
                  <Label>Group Name</Label>
                  <Input type="text" name="taskgroup" placeholder="Enter Group Name…"
                   onChange={this.handleChangeListGroup} 
                   value={this.state.taskgroup} />
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <Button className="save_btn" onClick={this.addNewTaskGroup} >
                {this.state.groupEditId>0 ? 'Update' : 'Create Group'}</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.listmodal} toggle={this.listtoggle} className="addtodo_wrapper">
          <ModalHeader toggle={this.listtoggle}> {this.state.listEditId>0 ? 'Update List' : 'Add List'} <Button className="clase_btn_cp" onClick={this.listtoggle}><MaterialIcon icon="close" /> </Button></ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} md={12}>
                <FormGroup>
                  <Label>List Name</Label>
                  <Input type="text" name="taskList" placeholder="Enter List Name…"
                  onChange={this.handleChangeListGroup}
                  value={this.state.taskList} />
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <Button className="save_btn" onClick={this.addNewTaskList}>
                  {this.state.listEditId>0 ? 'Update' : 'Create List'}</Button>
              </Col>
              {this.state.nonLinkedTasks &&
              (
                <Col xs={12} md={12}>
                  <span className='nonlist-tasks'><strong>Note: </strong> There are non linked tasks in this board, all tasks will be added automatically to the first list you create. You can move those tasks to your preferred lists and groups later.</span>
                </Col>
              )}
            </Row>
          </ModalBody>
        </Modal>
       
        <Modal isOpen={this.state.todoModal} toggle={this.todotoggle} className="add_task_list_wrapper addtodo_wrapper internal_task_popup">
          <ModalHeader toggle={this.todotoggle}><Button className="clase_btn_cp" onClick={this.todotoggle}><MaterialIcon icon="close" /> </Button></ModalHeader>
          <ModalBody>
            {this.state.activeList && this.state.activeList > 0 &&
              (
                <NewTaskList
                  bid={this.state.projectId}
                  project_name = {this.state.projectDetails.board_name}
                  activelist={this.state.activeList}
                  groups={this.state.activListGroup}
                  selectedGroupid={this.state.selectedGroupid}
                  closeaddpop={this.closetodotoggle} 
                  closecancel={this.todotoggle} />
              )}
          </ModalBody>
        </Modal>
       
        <Modal isOpen={this.state.listCopyModal} toggle={this.listCopyToggle} className="addtodo_wrapper copy_list_wrapper">
          <ModalHeader toggle={this.listCopyToggle}>Copy List<Button className="clase_btn_cp" onClick={this.listCopyToggle}><MaterialIcon icon="close" /> </Button></ModalHeader>
          <ModalBody>
            <CopyList 
            listid={this.state.listCopyId} 
            listname={this.state.listCopyName}
            closeanddirect={this.redirecToBoard}   />
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.groupCopyModal} toggle={this.groupCopyToggle} className="addtodo_wrapper copy_list_wrapper">
          <ModalHeader toggle={this.groupCopyToggle}>Copy Group<Button className="clase_btn_cp" onClick={this.groupCopyToggle}><MaterialIcon icon="close" /> </Button></ModalHeader>
          <ModalBody>
            <CopyGroup 
            groupid={this.state.groupCopyId} 
            groupname={this.state.groupCopyName}
            closeanddirect={this.redirecToBoard}  />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.moveTaskModal} toggle={this.moveTaskToggle} className="addtodo_wrapper movetodo_pop copy_list_wrapper">
          <ModalHeader toggle={this.moveTaskToggle}>Move a Card
            <Button className="clase_btn_cp" onClick={this.moveTaskToggle}>
                <MaterialIcon icon="close" /> 
            </Button>
          </ModalHeader>
          <ModalBody>
              <MoveTodo 
              tasklistgroup={this.state.taskListGroup} 
              taskid={this.state.moveTaskId}
              onmoveupdate={this.moveTaskUpdate} />
          </ModalBody>
        
        </Modal>
        {this.state.showDeleteConfirmation && (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showDeleteConfirmation}
            note="*This action cannot be undone, are you sure?"
            content="You are about to delete a list and it's associated groups and tasks."
            handledeletion={this.handleDeleteConfirm}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deletelist"
          />
        )}
         {this.state.showGroupDeleteConfirmation && (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showGroupDeleteConfirmation}
            note="*This action cannot be undone, are you sure?"
            content="You are about to delete a group and it's associated tasks."
            handledeletion={this.handleGroupDeleteConfirm}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deletegroup"
          />
        )}
      </div>
    )
  }
}
