import React, { Component, Fragment } from "react";
import { urls } from "../../services/UrlList";

export const onTeamChange = (teamid) =>
{
  return teamid;
}


export const getPMTeamLeads = async () => {
    try {
      const response = await fetch(urls.getPMTeamLeads, {
        headers: {
          'Access-Control-Allow-origin': '*',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data[0].pmtls;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };