import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import { urls } from "../../services/UrlList";
import "./basecampmodule.css";
import Header from "../header/Header";
import Homebackicon from "../../images/bc_homeicon.svg";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledTooltip,
} from "reactstrap";
import { AccessCheck, AccessCheckBlock } from "./AccessCheck";
import axios from "axios";
import { useHistory } from "react-router";
import AccessDenied from "./AccessDenied";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import ProjectCurrentStatus from "./ProjectCurrentStatus";
import toast, { Toaster } from "react-hot-toast";
import MaterialIcon from "material-icons-react";
import SideMenu from "../NewSideMenu/NewSideMenu";
import MailboxEmail from "./MailboxEmail";
import { useGetBoardApprovedFiles } from "./useBoardHooks";
import Lightbox from "react-lightbox-component";
import "react-lightbox-component/build/css/index.css";
import { Typeahead } from "react-bootstrap-typeahead";
const optionstask = [
  "Task one",
  "Task two",
  "Task three",
  "Task four",
  "Task five",
];

export default function BoardApprovedFiles(props) {
  const notifySuccess = () => toast("Brand predefined informations updated.");
  const notifyError = () => toast("Something went wrong. Please try again.");
  const [myId, setmyId] = useState(0);
  const [accessdenied, setAccessDenied] = useState(false);
  const [projectdetails, setProjectDetails] = useState([]);
  const [progressbar, setProgressBar] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [opencount, setOpenCount] = useState(0);
  const [typeFiles, setTypeFiles] = useState([]);
  const [filesDataImages, setFilesDataImages] = useState([]);
  const [filesDataFiles, setFilesDataFiles] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [taskFilters, setTaskFilters] = useState([]);
  const [brandFilters, setBrandFilters] = useState([]);

  const taskCategoryRef = useRef(null);
  const taskFileRef = useRef(null);
  const brandFileRef = useRef(null);

  const { isLoading: approvedLoading, data: approvedData } =
    useGetBoardApprovedFiles(props.match.params.pid);

  const history = useHistory();
  useEffect(() => {
    AccessCheck("access_projects_management_module");
  }, []);

  useEffect(() => {
    getProjectDetails();
  }, [myId]);

  const checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user") !== null) {
      setmyId(login.uid);
    }
  };

  function getProjectDetails() {
    const data = {
      project_id: props.match.params.pid,
      owner: myId,
    };
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-origin": "*",
    };
    axios
      .post(urls.getProjectDetails, data, {
        headers: headers,
      })
      .then((response) => {
        response.data.success !== 10 &&
          setProjectDetails(response.data.project_details[0]);
        setProgressBar(false);
      })
      .catch((error) => {
        setAccessDenied(true);
        setProjectDetails([]);
      });
  }

  function toggleSidebar() {
    setShowMenu(!showMenu);
  }

  function hideMenu() {
    setShowMenu(!showMenu);
  }

  function goToHome() {
    //this.props.history.push("/home");
    history.push({ pathname: "/home" });
  }

  function goToProjectsHome() {
    //this.props.history.push("/boards");
    history.push({ pathname: "/boards" });
  }

  function breadNavigation(murl) {
    console.log(murl);
    history.push({ pathname: murl });
  }

  const showFilesCategories = () => {
    setTypeFiles([]);
    setFilesDataImages([]);
    setFilesDataFiles([]);
    setActiveCategory("");
    taskCategoryRef.current && taskCategoryRef.current.clear();
    taskFileRef.current && taskFileRef.current.clear();
    brandFileRef.current && brandFileRef.current.clear();

  };

  useEffect(() => {
    if (approvedData?.data.files.length > 0 && typeFiles.length === 0) {
      setCategoryFilters(
        approvedData?.data.designTypes
          .filter((designtype) => designtype.type_name !== null)
          .map((designtype) => designtype.type_name)
      );

      const uniqueTasks = [];
      approvedData.data.files.forEach((task) => {
        if (
          !uniqueTasks.some((uniqueTask) => uniqueTask.task_id === task.task_id)
        ) {
          uniqueTasks.push(task);
        }
      });
      setTaskFilters(uniqueTasks);
      setBrandFilters(approvedData?.data.brands);
    }
  }, [approvedData]);

  const showFiles = (filterType, filterValue) => {
    setActiveCategory(filterType === 'type' ? filterValue.type_name : '');
    let fileslist = [...approvedData.data.files];
    let temp = [];
    let tempimg = [];
    let tempfiles = [];
    fileslist.forEach((p1) => {
      if (
        (filterType === 'type' && p1.design_type === filterValue.type_id) ||
        (filterType === 'task' && p1.task_id === filterValue[0].task_id) ||
        (filterType === 'brand' && p1.brand_id === filterValue[0].brand_id)
      ) {
        temp.push(p1);
  
        if (
          p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == "jpg" ||
          p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == "jpeg" ||
          p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == "png" ||
          p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == "gif" ||
          p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == "ico"
        ) {
          let temp1 = p1;
          temp1.src = `${urls.s3Url}${p1.file_location}/${p1.filename}`;
          temp1.sfile = p1;
          temp1.tile = p1.filename;
          temp1.filename = p1.filename;
          temp1.description = p1.filename;
          tempimg.push(temp1);
        } else if (p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == "pdf") {
          let temp2 = p1;
          tempfiles.push(temp2);
        }
      }
    });
  
    if (filterType === 'type') {
      taskFileRef.current && taskFileRef.current.clear();
      brandFileRef.current && brandFileRef.current.clear();
    } else if (filterType === 'task') {
      taskCategoryRef.current && taskCategoryRef.current.clear();
      brandFileRef.current && brandFileRef.current.clear();
    } else if (filterType === 'brand') {
      taskCategoryRef.current && taskCategoryRef.current.clear();
      taskFileRef.current && taskFileRef.current.clear();
    }
  
    setTypeFiles(temp);
    setFilesDataImages(tempimg);
    setFilesDataFiles(tempfiles);
  };

  return (
    <Fragment>
      <div className="fullwidth_mobilemenu">
        <Container fluid className="top-bar-inactive bc_module_container">
          <Row>
            <Toaster
              position="top-right"
              containerClassName="notifier"
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },
              }}
            />
            <div className="theme_header">
              <Header
                ncount={opencount}
                {...props}
                onmenuaction={toggleSidebar.bind(this)}
                className="tick-timer-header"
                quickactionblock={false}
              />
               <SideMenu  
                activeitem="project" 
                showmenu={showMenu} 
                hidemenu={hideMenu.bind(this)}  /> 
            </div>
            <div className="themeContentBody themefullwidthContentBoday">
              <LoadingWrapper isLoading={progressbar}>
                {accessdenied ? (
                  <>
                    <AccessDenied
                      gotohome={goToHome}
                      gotoprojectshome={goToProjectsHome}
                    />
                  </>
                ) : (
                  <>
                    <Col sm="12">
                      <div className="bc_Board_wrapper">
                        <h2 class="section_title d-flex align-items-center justify-content-center">
                          {projectdetails.board_name}
                          <ProjectCurrentStatus
                            graphics={projectdetails.graphics_pack}
                            video={projectdetails.video_pack}
                            graphics_video={projectdetails.graphics_video_pack}
                            canva_graphics={projectdetails.canva_graphics}
                            canva_plus={projectdetails.canva_plus}
                            whitelabel={projectdetails.white_label}
                            clientstatus={projectdetails.client_status}
                          />
                        </h2>
                        <p class="board_discrption">
                          {projectdetails.short_description}
                        </p>
                        <MailboxEmail
                          canva_access={projectdetails.canva_access}
                        />
                        <h2 className="section_title subtitle">
                          Best Designs
                        </h2>
                        <div className="page_header_wrap">
                          <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                              <div className="search-box approved-searchbox">
                                <div className="approved-serach-group">

                                  <Typeahead
                                    ref={taskCategoryRef}
                                    className="seacrhfilter"
                                    id="user-picker"
                                    labelKey="type_name"
                                    options={categoryFilters}
                                    placeholder="Design Category"
                                    selected=""
                                    onChange={(selected) => {
                                      if (
                                        typeof selected[0] !== "undefined" &&
                                        selected[0] != ""
                                      ) {
                                        const selectedItem =
                                          approvedData?.data.designTypes.filter(
                                            (designtype) =>
                                              designtype.type_name ===
                                              selected[0]
                                          );

                                        if (selectedItem.length === 1) {
                                          showFiles('type', selectedItem[0]);
                                        }
                                      }
                                    }}
                                  />

                                  <Typeahead
                                    ref={brandFileRef}
                                    className="seacrhfilter"
                                    id="brand-picker"
                                    labelKey="brand_name"
                                    options={brandFilters}
                                    placeholder="Brand"
                                    selected=""
                                    onChange={(selected) => {
                                      showFiles('brand', selected);
                                    }}
                                  />

                                  <Typeahead
                                    ref={taskFileRef}
                                    className="seacrhfilter"
                                    id="task-picker"
                                    labelKey="task_name"
                                    options={taskFilters}
                                    placeholder="Card Title"
                                    selected=""
                                    onChange={(selected) => {
                                      showFiles( 'task', selected);
                                    }}
                                  />
                                </div>
                                <div className="d-flex">
                                  <a
                                    className="edit_board_detail_icon"
                                    onClick={() => breadNavigation("/boards")}
                                  >
                                    {" "}
                                    <MaterialIcon icon="fact_check" />{" "}
                                  </a>

                                  <a
                                    className="home_button_box"
                                    onClick={goToHome}
                                  >
                                    <img src={Homebackicon} alt="back" />
                                  </a>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="breadcrumb_boards">
                        <Breadcrumb>
                          <BreadcrumbItem>
                            <a onClick={() => breadNavigation("/boards")}>
                              Boards
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a
                              onClick={() =>
                                breadNavigation(
                                  "/boardsdetails/" + props.match.params.pid
                                )
                              }
                            >
                              Board Details
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a onClick={() => showFilesCategories()}>
                              Best Designs
                            </a>
                          </BreadcrumbItem>
                          {activeCategory && activeCategory != "" && (
                            <BreadcrumbItem active>
                              {activeCategory}
                            </BreadcrumbItem>
                          )}
                        </Breadcrumb>
                      </div>
                    </Col>
                    <Col>
                      {approvedData?.data.files.length > 0 &&
                        typeFiles.length === 0 && (
                          <div className="brands_wrapper">
                            {approvedData?.data.designTypes.map(
                              (designtype, index) => {
                                return (
                                  <div
                                    key={designtype.type_id}
                                    className="folder_box card"
                                    onClick={showFiles.bind(this,'type', designtype)}
                                  >
                                    <span className="folder_name">
                                      {designtype.type_name != null
                                        ? designtype.type_name
                                        : "Others"}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}

                      {approvedData?.data.files.length > 0 &&
                        typeFiles.length > 0 && (
                          <div className="board-approved-files">
                            <Lightbox
                              images={filesDataImages}
                              renderImageFunc={(
                                idx,
                                image,
                                toggleLightbox,
                                width,
                                height
                              ) => {
                                return (
                                  <div className="board-approved-files-list d-flex">
                                    <img
                                      key={idx}
                                      src={`${urls.s3Url}${image.file_location}/${image.filename}`}
                                      className="img-circle"
                                      style={{ width: width, height: height }}
                                      onClick={toggleLightbox.bind(null, idx)}
                                    />
                                    <div className="file-info">
                                      <span
                                        className="filename"
                                        id={"tools" + idx}
                                        href="#"
                                      >
                                        {image.filename}
                                      </span>
                                      <span className="taskname taskname-stripped"
                                       id={"taskname" + idx} href="#">
                                        {image.task_name}
                                      </span>
                                      <span className="taskname">
                                        {image.brand_name}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"tools" + idx}
                                        style={{
                                          maxWidth: "600px",
                                          width: "auto",
                                        }}
                                      >
                                        {image.filename}
                                      </UncontrolledTooltip>
                                      
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"taskname" + idx}
                                        style={{
                                          maxWidth: "600px",
                                          width: "auto",
                                        }}
                                      >
                                        {image.task_name}
                                      </UncontrolledTooltip>

                                      <span className="filesize">
                                        {image.file_size}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <div className="board-approved-files-list-view">
                              {filesDataFiles.map((sfile, index) => {
                                return (
                                  <div
                                    className="board-approved-files-list"
                                    key={index}
                                  >
                                    <a style={{'display':'flex', 'gap':'10px'}} target="_blank" href={`${urls.s3Url}${sfile.file_location}/${sfile.filename}`}>
                                    <span className="fileicon-size">
                                      <span className="attachment-type">
                                        <span className="attachment-bg bg-small attach_other">
                                          {sfile.filename.split(".").pop()}
                                        </span>
                                      </span>
                                    </span>
                                    <div className="file-info">
                                      <span
                                        className="filename"
                                        id={"files" + index}
                                        href="#"
                                      >
                                        {sfile.filename}
                                      </span>
                                      <span className="taskname taskname-stripped"
                                      id={"tasknamefile" + index} href="#" >
                                        {sfile.task_name}
                                      </span>
                                      <span className="taskname">
                                        {sfile.brand_name}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"files" + index}
                                        style={{
                                          maxWidth: "600px",
                                          width: "auto",
                                        }}
                                      >
                                        {sfile.filename}
                                      </UncontrolledTooltip>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"tasknamefile" + index}
                                        style={{
                                          maxWidth: "600px",
                                          width: "auto",
                                        }}>
                                        {sfile.task_name}
                                      </UncontrolledTooltip>
                                      <span className="filesize">
                                        {sfile.file_size}
                                      </span>
                                    </div>
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                    </Col>
                  </>
                )}
              </LoadingWrapper>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
