import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { useGetRevsionApproved, useFileApprovalRequest, usePublishedDownloasAsZip } from "./useQueryHooks";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import { urls } from "../../../services/UrlList";
import ConfirmAction from "../../admincomment/ConfirmAction";

export default function ApprovedFiles(props) {
  const [showRemovalConfirm, setShowRemovalConfirm] = useState(false);
  const [removalFile, setRemovalFile] = useState('');
  const [downloadingFolder, setDownloadingFolder] = useState('');

  const handleApproveUpdate = (file) => {
    setRemovalFile(file);
    setShowRemovalConfirm(true);
  };

  const handleApprovalRequest = () => {
    const data2 = {
      revisionid:removalFile.revision_folder_id,
      taskid: removalFile.task_id,
      fileid: removalFile.id,
      action: 0
    };
    handleApprovalRequestAction(data2);
    closeRemovalDialogue();
  };
  const { isLoading: approavlLoading, mutate: handleApprovalRequestAction } = useFileApprovalRequest();

  const closeRemovalDialogue = () => {setShowRemovalConfirm(false);setRemovalFile('')};


  const downloadPublishedFolderData = (folder, files) => {
    setDownloadingFolder(folder);
    const data1 = {
      currentFolderName: folder,
      taskid: props.taskid,
      Files: files
    };
    downloadPublishedFolder(data1);
  };
  const { mutate: downloadPublishedFolder, isLoading: mutateLoading, } = usePublishedDownloasAsZip();


  const {
    isLoading: approvedLoading,
    data: approvedData,
  } = useGetRevsionApproved(props.taskid);

  if (approvedLoading) {
    return <>{"Loading"}</>;
  }


  return (
    <Row className="uploaded-versions sfm_hub_wrapper w-100 d-flex">
      {(approvedData?.data.structure && approvedData?.data.success === 1) && (
       <div className="approved-files-wrapper w-100">
            {approvedData?.data.structure.map((folderitem, index)=>
            {
                return(
                    <div key={index} className="approved-folder-files">
                        <div className="approved-folder"><span>{folderitem.folder}</span> 
                        <button className="downlaod_btn" onClick={()=>downloadPublishedFolderData(folderitem.folder, folderitem.items)}>
                          {mutateLoading && downloadingFolder === folderitem.folder ?
                          (
                            <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                          ):
                          (
                            <MaterialIcon icon="download" />
                          )}

                          {/* <MaterialIcon icon="download_file"/> */}
                        </button>
                        </div>
                        {folderitem.items.map((fileitem, fkey)=>
                        {
                            return(
                                <Card key={fileitem.id}>
                                    <span className="d-flex file-name">
                                        <span className="fileicon-size">
                                            <a
                                            href={`${urls.s3Url}${fileitem.file_location}/${fileitem.filename}`}
                                            target="_blank"
                                            className="attachment-bg bg-small"
                                            >
                                            <span className="attachment-type">
                                                {fileitem.filename.split(".").pop()}
                                            </span>
                                            </a>
                                            <span className="fileiconsize">
                                            {fileitem.file_size}
                                            </span>
                                        </span>
                                        <div className="file_detail_so">
                                            <h3>{fileitem.filename}</h3>
                                            <p>{fileitem.updated_on}</p> 
                                        </div>
                                        {props.canseeapproved &&
                                        (
                                          <>
                                              <div id={"unapproval"+index} className="unapprove-client" onClick={()=>handleApproveUpdate(fileitem)}>
                                                <MaterialIcon icon="close"/>
                                              </div>
                                              <UncontrolledTooltip  placement="top"  target={"unapproval"+index} style={{maxWidth:'600px',width:'auto'}} >
                                                {'Unapprove this file'}
                                              </UncontrolledTooltip >
                                           </>
                                        )}
                                    </span>
                                </Card>
                            )
                        })}
                    </div>
                )
            })}
       </div>
      )}
       {showRemovalConfirm ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeRemovalDialogue}
          show={showRemovalConfirm}
          note="*Are you sure?"
          content= {"You are about to mark this file as unapproved."}
          handlevfileunapproval={handleApprovalRequest}
          confirmBoxAction="unapprovefileslist"
        />
      ) : null}
    </Row>
  );
}
