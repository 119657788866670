import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg';
import Serachicon from '../../images/bc_search_icon.svg';
import Closeicon from '../../images/close-icon.png';
import Starfill from '../../images/star_fav_fill.svg';
import Staroutline from '../../images/star_outline.svg';
import Dropicon from '../../images/drop_board_icon.png';
import Bcplusicon from '../../images/bc_Plus.svg';
import User1 from '../../images/users/user1.png';
import User2 from '../../images/users/user2.png';
import User3 from '../../images/users/user3.png';
import User4 from '../../images/users/user4.png';
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';
import {Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NewBoard from './NewBoard';
import EditBoard from './EditBoard';
import SingleBoard from './SingleBoard';
import toast, { Toaster } from 'react-hot-toast';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import axios from 'axios';
import FilterSearch from "./FilterSearch";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ConfirmAction from '../admincomment/ConfirmAction';
import SideMenu from "../NewSideMenu/NewSideMenu";

const notifySuccess = () => toast('New board created successfully.');
const notifyError = () => toast('Something went wrong. Please try again.');
const notifyEditSuccess = () => toast('Board updated successfully.');
const notifyDeleteSuccess = () => toast('Successfully deleted');

export default class Bcboards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      showDeleteConfirmation:false,
      deletionId:0,
      addmember:false ,
      orginalUsers:[],
      selectedUser:'',
      dropDownOpen: '',
      myPrivileges: '',
      myId: '',
      myBaseId: '',
      myName: '',
      fullProjects:[],
      orgProjects:[],
      favouriteProjects:[],
      normalProjects:[],
      searchFound:false,
      nothingFound:'',
      listMsg:'',
      msgShow:false,
      callChange:false,
      editActive:false,
      editId:'',
      editProject:[],
      progressBar:true,
      openNCount:0,
      textValue:'',
      showMenu:true,
    };
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.togglememberDisplay = this.togglememberDisplay.bind(this);
    this.reactTags = React.createRef();
   
  }

//faheem code----------------------------
toggle = () => {
  this.setState({
     dropDownOpen: !this.state.dropDownOpen,
  })
}

notificationChecker = () =>
  {
    const {myProp, match: {params}} = this.props; 
    const _this = this;
    var channel = myProp.subscribe('Hub-Tasks');
    channel.bind('hub-event', function(data) {
      if(data.id === myProp.connection.socket_id)
      {
          _this.setState({
          openNCount: _this.state.openNCount +1
        })
      }
    });
    
  }

toggleDisplay() {
  this.setState({
    display: !this.state.display,
    editActive:false,
    editId:'',
    editProject:[],
  });
}
togglememberDisplay() {
  this.setState({
    addmember: !this.state.addmember
  });
}




//-----------------------------------------
  // isBottom(el){
  //   return el.getBoundingClientRect().bottom <= window.innerHeight;
  // }

  componentDidMount () {
    document.title = "KIMPHUB - Boards";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    this.getFullUsers();
    //this.notificationChecker();
    const _this = this;
      setTimeout(()=>
      {
        _this.getMyBoards();
       // _this.testRq();
        //document.addEventListener("scroll", _this.trackScrolling);
      }, 1000);
    
  };

  componentWillUnmount() {  
    this.getMyBoards();
  }
  
  componentDidUpdate(prevState) {
    if (prevState.fullProjects !== this.state.fullProjects && this.state.callChange === true ) {
        this.categoriseProjects(this.state.fullProjects);
        this.setState({callChange:false});
    }
  }
  getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),          
        });            
    })
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name
      });
    }
  };

  


  // renderContentDevice = () => {
  //   if (isMobile && !this.state.myPrivileges.includes('am_admin')) {
  //       this.setState({isMobileView:1});
  //   } 
  //   if (isMobile) {
  //     this.setState({showMenu:false});
  //   }  
  //};

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  addNewBoard = () =>
  {
    this.setState({display:false});
    notifySuccess();
    this.getMyBoards();
  }

  editandSaveBoard = () =>
  {
    this.setState({display:false});
    notifySuccess();
    this.getMyBoards();
  }

  getMyBoards = ()=>
  {
    const data = {
      user_id: this.state.myId,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getMyprojects, data, {
        headers: headers
      })
      .then((response) => {
        //console.log(response.data.projects);
         this.setState({
           orgProjects: response.data.projects,
           fullProjects:response.data.projects,
           nothingFound: response.data.projects.length<=0 ? 'No matching boards found':'',
           listMsg: response.data.msg,
           msgShow:response.data.projects.length<=0 ? true : false,
           progressBar:false,
         });
         if(response.data.projects.length>0)
         {
           this.categoriseProjects(response.data.projects);
         }
      })
      .catch((error) => {
        this.setState({
          progressBar:false
        });
        notifyError();
      })
  }

  categoriseProjects =(newprojects)=>
  {
      let projects = newprojects;
      let temp=[];
      let favorites=[];
      let normal = [];
      var loopcount = 0;
      projects.forEach(p1 => {
          if(p1.is_favourite == 1)
          {
            favorites.push(p1);
          }else
          {
            normal.push(p1);
          }
          loopcount++;
          if(loopcount === projects.length)
          {
            this.setState({
              favouriteProjects: favorites,
              normalProjects: normal
            });
          }
      });
      
  }
 
  updateProjectMembers =(project,pid, type, members, newmember, action)=>
  {
    const data = {
      actmember: newmember,
      owner: this.state.myId,
      members: JSON.stringify(members),
      action: action,
      board_id: pid,
      bid_cpt:project.bid_cpt,
      board_name: project.board_name
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.editBoard, data, {
        headers: headers
      })
      .then((response) => {
          if(type === 1)
            var projectstoupdate = [...this.state.favouriteProjects];
          else
            var projectstoupdate = [...this.state.normalProjects];
          
            var itemkey = projectstoupdate.map(function (item) { return item.board_id; }).indexOf(pid); // (2D) finding the matching key providing value
          
          projectstoupdate[itemkey].board_access_stringified = JSON.stringify(members);
          if(type===1)
            this.setState({favouriteProjects:projectstoupdate});
          else 
            this.setState({normalProjects:projectstoupdate});
      })
      .catch((error) => {
        notifyError();
      })
  }

  updateMyFavourites = (pid, favstatus)=>
  {
  
    const data = {
      owner: this.state.myId,
      board_id: pid,
      favourite_status: favstatus === 1? 0 : 1
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.markProjectFavourite, data, {
        headers: headers
      })
      .then((response) => {
          console.log("hi");
          var projectstoupdate = [...this.state.fullProjects];
          var itemkey = projectstoupdate.map(function (item) { return item.board_id; }).indexOf(pid); // (2D) finding the matching key providing value
          if(favstatus==0)
            projectstoupdate[itemkey].is_favourite = 1;
          else
            projectstoupdate[itemkey].is_favourite = 0;
          this.setState({fullProjects:projectstoupdate, callChange:true});
      })
      .catch((error) => {
        this.setState({fullProjects:this.state.orgProjects, callChange:true});
        //notifyError();
      })
  }

  deleteProject = () => 
  {
    const data = {
      owner: this.state.myId,
      project_id: this.state.deletionId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.deleteKProject, data, {
        headers: headers
      })
      .then((response) => {
          var projectstoupdate = [...this.state.normalProjects];
          var itemkey = projectstoupdate.map(function (item) { return item.board_id; }).indexOf(this.state.deletionId); // (2D) finding the matching key providing value
          projectstoupdate.splice(itemkey, 1);
          this.setState({normalProjects:projectstoupdate, callChange:true,
            showDeleteConfirmation: false, deletionId:0});
          notifyDeleteSuccess();
      })
      .catch((error) => {
        notifyError();
        this.setState({showDeleteConfirmation: false, deletionId:0});
      })
  }

  enableForEditing = (pid, project)=>
  {
    this.setState({
      editActive:true,
      editId:pid,
      editProject:project,
      display:true
    })
  }

  editBoard = (project)=>
  {
    var projectstoupdate = [...this.state.fullProjects];
    var itemkey = projectstoupdate.map(function (item) { return item.board_id; }).indexOf(project.board_id); // (2D) finding the matching key providing value
    //projectstoupdate.splice(itemkey, 1);
    projectstoupdate[itemkey] = project;
    this.setState({fullProjects:projectstoupdate, callChange:true});

    if(this.state.fullProjects.length>0)
    {
      this.setState({normalProjects:[], favouriteProjects:[]});
      this.categoriseProjects(projectstoupdate);
    }

    this.setState({
      editActive:false,
      editId:'',
      editProject:'',
      display:false
    })
    notifyEditSuccess();
  }

  showProjectDetails = (id) =>
  {
    //this.props.history.push("/boardsdetails/"+id);
    window.open("/boardsdetails/"+id+"?uuid="+this.state.myId,'_blank');
  }
  
  showProjectTasks = (id, type, view) =>
  {
    //this.props.history.push("/task-list/"+id);
    if(type==1)
      window.open("/task-list/"+id+"?uuid="+this.state.myId,'_blank');
    else
    {
      if(view==0)
      {
        window.open("/internal-task-list/"+id+"?uuid="+this.state.myId,'_blank');
      }else
      {
        window.open("/internal-task-grid/"+id+"?uuid="+this.state.myId,'_blank');
      }
    }

  }

  showProjectDocs = (id) =>
  {
    window.open("/docandfiles/"+id+"?uuid="+this.state.myId,'_blank' );
  }

  goToHome = () =>
  {
    this.props.history.push("/home");
  }
  closePopup = () => {
    this.props.closeaction();
  };

  updateWhiteLabels = (pid, labelstatus)=>
  {
    const data = {
      owner: this.state.myId,
      board_id: pid,
      white_label: labelstatus === 1? 0 : 1
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.whiteLabelProjects, data, {
        headers: headers
      })
      .then((response) => {
          var projectstoupdate = [...this.state.fullProjects];
          var itemkey = projectstoupdate.map(function (item) { return item.board_id; }).indexOf(pid); // (2D) finding the matching key providing value
          if(labelstatus == 0)
            projectstoupdate[itemkey].white_label = 1;
          else
            projectstoupdate[itemkey].white_label = 0;
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({fullProjects:projectstoupdate, callChange:true});
              notifyEditSuccess();
            }, 100);
          
      })
      .catch((error) => {
        notifyError();
      })
  }

  updateTrialLabels = (pid, labelstatus, newstatus)=>
  {
    const data = {
      owner: this.state.myId,
      board_id: pid,
      client_status: labelstatus === newstatus ? 0 : newstatus
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.trialLabelProjects, data, {
        headers: headers
      })
      .then((response) => {
          var projectstoupdate = [...this.state.fullProjects];
          var itemkey = projectstoupdate.map(function (item) { return item.board_id; }).indexOf(pid); // (2D) finding the matching key providing value
          if(newstatus >0 )
            projectstoupdate[itemkey].client_status = labelstatus === newstatus ? 0 : newstatus;
          else
            projectstoupdate[itemkey].client_status = 0;
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({fullProjects:projectstoupdate, callChange:true});
              notifyEditSuccess();
            }, 300);
          
      })
      .catch((error) => {
        notifyError();
      })
  }

  deleteProjectAsk = async (pid) =>
  {
    this.setState({
      showDeleteConfirmation:true,
      deletionId:pid
    })
  }

  closeDialogue = () => {
    this.setState({
      showDeleteConfirmation: false,
      hideshowbox:true
    });
  };
  resetSearch = () => {
    this.setState({
      textValue: '', 
    });
    this.setState({
      fullProjects:this.state.orgProjects,
      progressBar:false,
      searchFound:false
    });
    if(this.state.orgProjects.length>0)
    {
      this.categoriseProjects(this.state.orgProjects);
    }
    
  }
  searchUsingName =  async (e) =>
  {
    this.setState({
      textValue: e.target.value,
    });

    if(e.target.value.length>2)
    {
        const data = {
            skey: e.target.value,
            user_id: this.state.myId
        };
     
       

        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin':'*',
        }
        await axios.post(urls.searchProjectsUsingName, data, {  // searching projects 
            headers: headers
            })
            .then((response) => {
              this.setState({
                normalProjects:response.data.projects,
                nothingFound: response.data.projects.length<=0 ? 'No matching boards found':'',
                favouriteProjects:[],
                listMsg: response.data.msg,
                msgShow:response.data.projects.length<=0 ? true : false,
                progressBar:false,
                searchFound:true
              });
            })
            .catch((error) => {
              this.setState({
                progressBar:false,
                searchFound:false
              });
            })
    }else
    {
      this.setState({
        fullProjects:this.state.orgProjects,
        progressBar:false,
        searchFound:false
      });
      if(this.state.orgProjects.length>0)
      {
        this.categoriseProjects(this.state.orgProjects);
      }
    }
  }

//   componentDidMount() {
//     const body = document.querySelector('body');
//     body.classList.add('fullwidth_mobilemenu');
//   }

// componentWillUnmount() {
//    const body = document.querySelector('body');
//         body.classList.remove('fullwidth_mobilemenu');
//     }

  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className="top-bar-inactive bc_module_container">
      <Row>
        <div className="theme_header">
        <Header
            ncount={this.state.openNCount}
            {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
            <div className="SideNavbar">
            <SideMenu  
            activeitem="project" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
            </div>
          
        </div>
       <div className="themeContentBody themefullwidthContentBoday">
        <Row>
        <Col sm="12">
            <div className="bc_Board_wrapper">
            <h2 className="section_title">Boards</h2>
            <div className="page_header_wrap">
            <Row>
          
           
              <Col sm={6}>
            
              {AccessCheckBlock('create_new_project') && 
                (
                 
                  <button className="addbtn_text" onClick={this.toggleDisplay}><span className="bc_plusicon"><img src={Bcplusicon} alt="back" /></span> Create New Board</button>
                )}
                {this.state.display && 
                
                    (
                      <>
                       <div className="overlay_bc" onClick={this.toggleDisplay}></div> 
                        {this.state.editActive ?
                        (
                          <EditBoard userid={this.state.myId} editboard={this.editBoard} project={this.state.editProject} />
                        ):
                        (
                          <NewBoard userid={this.state.myId} addnewboard={this.addNewBoard}
                          closepopup={this.toggleDisplay}
                          /> 
                        )}
                      </>
                    )
                  }

              </Col>
              
              <Col sm={6}>
              <div className="search-box">
                      {/* {AccessCheckBlock('use_projects_search_addon') &&
                      (<FilterSearch />)
                      } */}
                      <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                  </div>
              
              </Col>
            
            
            
            </Row>
            </div>

            <div className="page_header_wrap" style={{ display:'none' }}>
                {AccessCheckBlock('create_new_project') &&
                (
                  <button className="addbtn_text" onClick={this.toggleDisplay}><span className="bc_plusicon"><img src={Bcplusicon} alt="back" /></span> Create New Project</button>
                )}
                {this.state.display && 
                    (
                      <>
                        {this.state.editActive ?
                        (
                          <EditBoard userid={this.state.myId} editboard={this.editBoard} project={this.state.editProject} />
                        ):
                        (
                          <NewBoard userid={this.state.myId} addnewboard={this.addNewBoard} /> 
                        )}
                      </>
                    )
                  }
                  
                  <div className="search-box">
                      {AccessCheckBlock('use_projects_search_addon') &&
                      (<FilterSearch />)
                      }
                      <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                  </div>
              
            </div>
          </div>
         </Col>
         <Col sm="12" className="spacer">
          <Toaster position="top-right" containerClassName="notifier"
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },}}
            />
        </Col>
        <LoadingWrapper isLoading={this.state.progressBar}>
        
          <div className="board_header">
          
           <Col sm={6}>
            {this.state.favouriteProjects && this.state.favouriteProjects.length>0 &&
            (<h4 className="boxtitile">Favourites</h4>)
            }
            </Col>
            <Col sm={6}>
            <div className="board_search_box">
                 <Input value={this.state.textValue}  placeholder="Search Boards"  className="search_input"
                 onChange={(e) => this.searchUsingName(e)}/>
                 {this.state.textValue && <Button className="reset_btn" onClick={() =>this.resetSearch()}>reset</Button> }
               </div>
            </Col>
            
            </div>
          
        {this.state.favouriteProjects && this.state.favouriteProjects.length>0 &&
        (
          <Col sm="12">
           
           <div className="fav_borads_wrapper">
          
              
             {this.state.favouriteProjects.map((project, index)=>
              {
                return(
                  <SingleBoard 
                  myid={this.state.myId}
                  fullusers={this.state.orginalUsers} 
                  key={project.bid_cpt} 
                  project={project}
                  updatemembers={this.updateProjectMembers}
                  updatemyfavourites={this.updateMyFavourites}
                  updatewhitelabels={this.updateWhiteLabels}
                  updatetriallabels={this.updateTrialLabels}
                  enableforediting={this.enableForEditing}
                  editboard={this.editandSaveBoard}
                  deleteproject={this.deleteProjectAsk}
                  showproject={this.showProjectDetails}
                  showtasks={this.showProjectTasks}
                  showdocs={this.showProjectDocs}
                  type={1}
                  />
                )
              })}
           </div>
          </Col>
        )
        }
        
        {this.state.normalProjects && this.state.normalProjects.length>0 &&
        (
        <>
          <Col sm="12">
            <div className="board_header">
              <h4 className="boxtitile">
              {this.state.searchFound ? 
              (
                'Search Results'
              ):
              (
                'Recently Visited'
              )}
              </h4>
            </div>
          </Col>
          <Col sm="12">
            <div className="board_list_wrapper">
                {this.state.normalProjects.map((project, index)=>
                {
                  return(
                    <SingleBoard 
                    myid={this.state.myId}
                    fullusers={this.state.orginalUsers} 
                    key={project.bid_cpt} 
                    project={project} 
                    updatemembers={this.updateProjectMembers}
                    updatemyfavourites={this.updateMyFavourites}
                    updatewhitelabels={this.updateWhiteLabels}
                    updatetriallabels={this.updateTrialLabels}
                    enableforediting={this.enableForEditing}
                    editboard={this.editandSaveBoard}
                    deleteproject={this.deleteProjectAsk}
                    showproject={this.showProjectDetails}
                    showtasks={this.showProjectTasks}
                    showdocs={this.showProjectDocs}
                    type={2} />
                  )
                })}
              </div>
          </Col>
         </>
        )}
        {this.state.nothingFound!='' &&
        (
          <span className="no-boards">{this.state.nothingFound}</span>
        )}
        </LoadingWrapper>
        </Row>
       </div>
      </Row>
    
      {this.state.addmember && 
       <div className="add_member">
         <span className="close_thisbox" onClick={this.togglememberDisplay}><img src={Closeicon} alt="close" /></span>
         <h4 className="boxtitile">Add New Member</h4>
         <div className="people select_member">
          <Typeahead
          id="user-picker"
          labelKey="name"
          onInputChange={this.handleInputChange}
          onChange={this.onChangePeople}
          options={this.state.orginalUsers}
          placeholder="Type New Member"
          selected=''
        />
        </div>
        <div className="member_list_table">
           <div className="added_member_prev">
             <div className="member_detail">
               <span className="user_avatar"><img src={User1} alt="back" /></span>
               <span className="user_bc_name">Abhishake Dey</span>
               <span className="jobrole">UI/UX Designer </span>
             </div>
             <Button className="remove_this">Remove</Button>
           </div>
           <div className="added_member_prev">
             <div className="member_detail">
               <span className="user_avatar"><img src={User2} alt="back" /></span>
               <span className="user_bc_name">Faheem</span>
               <span className="jobrole">Front End Developer</span>
             </div>
             <Button className="remove_this">Remove</Button>
           </div>
           <div className="added_member_prev">
             <div className="member_detail">
               <span className="user_avatar"><img src={User3} alt="back" /></span>
               <span className="user_bc_name">Tibin</span>
               <span className="jobrole">Back End Developer </span>
             </div>
             <Button className="remove_this">Remove</Button>
           </div>
        </div>

       </div>
      }
      {this.state.showDeleteConfirmation ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showDeleteConfirmation}
            note="*This action cannot be undone, are you sure?"
            content="You are about to delete this project."
            handledeletion={this.deleteProject}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deleteproject"
          />
        ) : null}
      </Container>
      </div> 
    )
  }
}
