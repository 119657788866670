import React, { Component, Fragment } from 'react';
import './tickets.css'
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, CardTitle} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import ReactDOM from 'react-dom'
import ReactTags from 'react-tag-autocomplete'
import FullUserPicker from "./FullUserPicker";
import DatePickerTick from "./DateSelector";
import moment from "moment";
import DateTimePicker from 'react-datetime-picker';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import CalenderIcon from '../../images/date-icon.png'
import MaterialIcon from "material-icons-react";
import SideMenu from "../NewSideMenu/NewSideMenu";
import TicketFileUpload from './TicketFileUpload';
import TicketLightboxPop from './TicketLightboxPop';


export default class NewTicket extends Component {
  fileObj = []; 
  fileArray = [];
  fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      myTimezone:'',
      showProgress: true,
      ticketDate: new Date(),
      minDate: new Date(),
      
      ticketDueDate:'',
      files: [],
      topics:[],
      status:[],
      issueTypes:[],
      availableAssignees:[],
      allPms:[],
      allPls:[],
      relatedTo:'',
      issueType:'',
      responsibleBy:'',
      tassignees:[],
      tparticipants:[],
      incidentDate:'',
      relatedArray:['22', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '42', '43', '44','46','47', '48', '49','50','51','53','54','55','56'],
      tassigneesnames:'',
      ticketStatus:'1',
      ticketSubject:'',
      ticketDetails:'',
      folderName:'',
      uploadingFiles:false,
      subjectError:false,
      detailError:false,
      relatedError:false,
      assignedError:false,
      fileTypeError:false,
      clientError:false,
      trelloError:false,
      basecampError:false,
      issueTypeError:false,
      quickActionBlock:true,
      clientName:'',
      basecampURL:'',
      trelloURL:'',
      ticketStatusText:'New',
      tags: [],
      showMenu: true, 
      setTags:[],
      clients:[],
      reminderTime:'',
      commentAttachments: '',
      commentAttachmentsChange: false,
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true,
      }
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.reactTags = React.createRef();
  }

  isBottom(el){
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount = () => {
    document.title = "HUB - Ticket System";
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      _this.renderContentViewOwnership();
      _this.getTicketAssets();
    }, 500);
  };

  componentDidUpdate(prevState) {
    if (prevState.fileTypeError !== this.state.fileTypeError && this.state.fileTypeError === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ fileTypeError: false });
      }, 5000);
    }
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));             
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };

  renderContentViewOwnership = () =>
  { 
    if (this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets') ) {
      if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'full'});
      }else if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'partial'});
      }
    }else
    {
      this.props.history.push("/home");
    }
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  getTicketAssets = ()=>
  {
      fetch(urls.getTicketAssets,{
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type' : 'application/json'
          }
      })
      .then(response => response.json())
      .then(response=>
      {
        this.setState({
          topics: response['topics'],
          status: response['status'],
          tags: response['tags'],
          issueTypes: response['issue_types'],
          availableAssignees: response['available-assignees'],
          allPms: response['available-pms'],
          allPls: response['available-pmstls'],
          folderName: response['cfolder'],
          clients:response['clients']
        })
      })
      .catch((error)=>
      {

      })
  }

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 
  handleChangeEditor(value) {
    this.setState({ ticketDetails: value })
  }

  uploadMultipleFiles(e) {
    this.setState({
      uploadingFiles:true
    })
    this.fileObj.push(e.target.files)
    for (let i = 0; i < this.fileObj[0].length; i++)
    {
      var ftpe = this.fileObj[0][i].type;
      var fsize = this.fileObj[0][i].size;
      if((ftpe == 'image/jpeg' || ftpe == 'image/jpeg' || ftpe == 'image/png' ||  
      ftpe == 'image/gif' || ftpe == 'application/pdf') &&  fsize < 2050000)
      {
        const data = new FormData();
        data.append('file', this.fileObj[0][i]);
        data.append('filename', this.fileObj[0][i].name);
        data.append('user_id', this.state.myId);
        axios.post(urls.uploadTicketFiles, data, { 
        // receive two    parameter endpoint url ,form data
        })
        .then(res => { 
            this.setState({
              uploadingFiles:false
            })
        })
        this.fileNamesArray.push(urls.storageUrl+"storage/tickets/"+this.state.folderName+"-"+this.state.myId+"/"+this.fileObj[0][i].name);
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
      }else
      {
        this.setState({fileTypeError:true});
        break;
      }
    }
    this.fileObj = [];
    this.setState({ files: this.fileNamesArray })
}
handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
};

showDashboard =() =>
  {
    this.props.history.push("/home");
  }

  showTicketList = () =>
  {
    this.props.history.push("/tickets");
  }


onTdateChange = (date) => 
  {
    this.setState({ ticketDate: date });
  }

  onTDuedateChange = (date) => 
  {
    this.setState({ ticketDueDate: date });
  }

  handleClick = e => {
    var array = [...this.state.tassignees];
    if (e.target.checked) {
      array.push(e.target.value)
    } else {
      var array = [...this.state.tassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
    this.setState({ tassignees: array });
    const _this = this;
    setTimeout(() => {
      _this.showAssigneeNames();
    }, 100);
   
  };

  showAssigneeNames = () =>
  {
    var tempassginees = '';
    let temp = this.state.tassignees.map(Number);
    this.state.availableAssignees.forEach(assignee => {
      if(temp.includes(assignee.id))
      {
        tempassginees += assignee.name +', ';
      }
    });
    this.setState({
      tassigneesnames: tempassginees.slice(0, -2)
    })
  }

  createTicket = () =>
  {

    this.setState({
      subjectError:false,
      detailError:false,
      relatedError:false,
      assignedError:false
    });

    if(this.state.relatedTo == 22)
    {
      if(this.state.clientName === '')
      {
        this.setState({clientError:true});
        return false;
      }else if(this.state.trelloURL === '')
      {
        this.setState({trelloError:true});
        return false;
      } 
      /*else if(this.state.basecampURL === '')
      {
        this.setState({basecampError:true});
        return false;
      } */
    }

    if(this.state.relatedTo === '')
    {
        this.setState({relatedError:true});
        return false;
    }else if(this.state.ticketSubject=='')
    {
        this.setState({subjectError:true});
        return false;
    }else if(this.state.ticketDetails === '')
    {
        this.setState({detailError:true});
        return false;
    }else if(this.state.responsibleBy.length>0)
    {
      if(this.state.issueType=='')
      {
        this.setState({issueTypeError:true});
        return false;
      }
    }
    
    /*else if(this.state.tassignees.length === 0)
    {
        this.setState({assignedError:true});
        return false;
    }*/

    let opts ={
      ticket_subject: this.state.ticketSubject,
      ticket_details: this.state.ticketDetails,
      ticket_topic: this.state.relatedTo,
      ticket_status: this.state.ticketStatus,
      ticket_assignees: this.state.tassignees,
      ticket_participants: this.state.tparticipants,
      ticket_tags: this.state.setTags,
      issue_type: this.state.issueType,
      responsible_by: this.state.responsibleBy,
      due_date:this.state.ticketDueDate,
      date_created: this.state.ticketDate,
      //ticket_attachments: this.state.files,
      ticket_attachments: this.state.commentAttachments,
      user_id:this.state.myId,
      username: this.state.myName,
      client_name: this.state.clientName,
      basecamp_url: this.state.basecampURL,
      trello_url: this.state.trelloURL,
      issue_incident_date: this.state.incidentDate,
      reminderTime:this.state.reminderTime,
      timezone: this.state.myTimezone
    }

    fetch(urls.newHubTicket,{
			headers:{
				'Access-Control-Allow-origin':'*',
				'Content-Type':'application/json'
			},
			method: "post",
        	body: JSON.stringify(opts)
		})
		.then(response => response.json())
		.then(response =>
		{
      this.props.history.push("/ticket/"+response.success);
		})
		.catch(error =>
		{
			console.log(error);
		})
  }

  onDelete (i) {
    const tassignees = this.state.tassignees.slice(0)
    tassignees.splice(i, 1)
    this.setState({ tassignees })
  }
 
  onAddition (tag) {
    

    const tassignees = this.state.tassignees.slice(0)
    tassignees.splice(0, 1)
    this.setState({ tassignees });
    
    const _this = this;
    setTimeout(() => {
      const tassignees1 = [].concat(_this.state.tassignees, tag)
      _this.setState({ tassignees: tassignees1 })
    }, 200);


  }

  onDeleteParticipant (i) {
    const tparticipants = this.state.tparticipants.slice(0)
    tparticipants.splice(i, 1)
    this.setState({ tparticipants })
  }
 
  onAdditionParticipant (tag) {
    const tparticipants = [].concat(this.state.tparticipants, tag)
    this.setState({ tparticipants })
  }

  onAdditionParticipantAllPms = () =>{
    const tparticipants = [].concat(this.state.tparticipants, this.state.allPms)
    this.setState({ tparticipants})
  }

  onAdditionParticipantAllPls = () =>{
    const tparticipants = [].concat(this.state.tparticipants, this.state.allPls)
    this.setState({ tparticipants})
  }

  onDeleteTag (i) {
    const setTags = this.state.setTags.slice(0)
    setTags.splice(i, 1)
    this.setState({ setTags })
  }
 
  onAdditionTag (tag) {
    const setTags = [].concat(this.state.setTags, tag)
    this.setState({ setTags })
  }




  removePreviewImg = (url) =>
  {
    var index = this.fileArray.indexOf(url); // 1
    this.fileArray.splice(index, 1);
    this.fileNamesArray.splice(index, 1);
    this.setState({imgagePreviews: this.fileArray, files: this.fileNamesArray});
  }


  updateTicketStatus = (e) =>
  {
        this.setState({
          ticketStatus:e.target.value
        })
        this.state.status.forEach(tstatus => {
          if(tstatus.id == e.target.value)
          {
            this.setState({
              ticketStatusText: tstatus.status
            })
          }
        });
  }


  updateTicketIssue = (e) =>
  {
    console.log("pto");
        this.setState({
          issueType:e.target.value
        })
        this.state.status.forEach(tstatus => {
          if(tstatus.id == e.target.value)
          {
            this.setState({
              issueType: tstatus.id
            })
          }
        });
  }


  onPeopleChange = (userid) =>
  {        
   this.setState({responsibleBy: userid});
  }

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("YYYY-MM-DD");
    this.setState({incidentDate:momentString});
  };

  setReminderime = (e)=>
  {
    this.setState({
      reminderTime:e
    })
  }

  setReminderTimeOptions = (e)=>
  {
    if(e.target.value>0)
    {
      this.setState({
        reminderTime:new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }

  onChangeClient = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ clientName: e[0].client_name});
    }
  };


  storeCommentFileNames = async (arrayname, item) => {
    let temp = [...this.state.commentAttachments];
    let filename= item.path+item.file_name;
    await this.setState({ commentAttachments: []});
    console.log(filename);
    temp.push(filename);
    await this.setState({ commentAttachments: temp});
  }


  deleteCommentFileNames = async (filename, arrayname) => {
    this.setState({ commentAttachmentsChange: true });
    var assetupdate = [...this.state.commentAttachments];
    this.setState({ commentAttachments: []});
    
    var itemkey = assetupdate.map(function (item) { return item; }).indexOf(filename);
    assetupdate.splice(itemkey, 1);
    const _this = this;
    setTimeout(() => {
      _this.setState({ commentAttachments: assetupdate,commentAttachmentsChange: false });
    }, 500);
  }
  

  render() {
    return (
      <div className="fullwidth_mobilemenu">
<Container fluid className={this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive'}>
      <Row>
      <div className="theme_header">
      <Header {...this.props}  
        classname="tick-timer-header" 
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={this.state.quickActionBlock} />
         <div className="SideNavbar">
           <SideMenu  
            activeitem="tickets" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
       </div>
      </div>

       <div className="themeContentBody themefullwidthContentBoday">
        <Row className="mobile_reverse">
            <Col sm={12} lg={4}>
              <Card className="ticket-sidebar-wrapper">
                 <div className="ticket_header_left"> 
                  <CardTitle tag="h1"><Button onClick={this.showTicketList} className="balck_btn"> <MaterialIcon icon="arrow_back"/></Button>Create New Tickets</CardTitle>
                </div>
                <div className="ticket_detail_left">
                      <div className="row-1 post_date">
                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Assigned To</Label>
                        <ReactTags
                        ref={this.reactTags}
                        tags={this.state.tassignees}
                        suggestions={this.state.availableAssignees}
                        onDelete={this.onDelete.bind(this)}
                        onAddition={this.onAddition.bind(this)}
                        placeholderText="Add only one" />
                      </div>
                      <div className="row-1 post_date participant-fields">
                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Participants</Label>
                        <ReactTags
                        ref={this.reactTags}
                        tags={this.state.tparticipants}
                        suggestions={this.state.availableAssignees}
                        onDelete={this.onDeleteParticipant.bind(this)}
                        onAddition={this.onAdditionParticipant.bind(this)}
                        placeholderText="Type To Add" />
                        <Button title="Add all Project Leads to Participants list" className="btn btn-green add-multiple-members  btn btn-secondary" name="Add-all-pms" onClick={this.onAdditionParticipantAllPls}>All PL +</Button>
                        <Button title="Add all Project Managers to Participants list" className="btn btn-green add-multiple-members btn btn-secondary" name="Add-all-pms" onClick={this.onAdditionParticipantAllPms}>All PM +</Button>
                      </div>
                      <div className="row-1 post_date">
                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Labels</Label>
                        <ReactTags
                        ref={this.reactTags}
                        tags={this.state.setTags}
                        suggestions={this.state.tags}
                        onDelete={this.onDeleteTag.bind(this)}
                        onAddition={this.onAdditionTag.bind(this)}
                        placeholderText="Add Labels" />
                      </div>

                      {this.state.relatedArray.includes(this.state.relatedTo) &&
                      (
                      <>
                      <div className="row-1 post_date ticket_task_issue_type">
                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Issue Type</Label>
                        {/* <Input type="select" id="issueType" 
                          className={this.state.issueTypeError? 'field-error relates-area' : 'relates-area'}
                          onChange={this.handleChange}  name="issueType" >
                              <option value="">Select</option>
                              {this.state.issueTypes.map((topic, index)=>
                              {
                                return(
                                  <option key={index} value={topic.id}
                                  selected={this.state.issueType  == topic.id? 'selected':''}
                                  >{topic.issue_name}</option>
                                )
                              })}
                            </Input> */}
                           <div className="assign-group ulchekbox">
                              {this.state.issueTypes.map((topic, index)=>
                                {
                                  return(
                                    <div key={index} className="d-flex justify-content-between status-items rbstyle ch-item">
                                      <Input id={`issue-${topic.id}`} name="issueType" 
                                      value={topic.id} type="radio" 
                                      checked= {this.state.issueType == topic.id? 'checked': ''} 
                                      onChange={this.updateTicketIssue}
                                      />
                                      <Label for={`issue-${topic.id}`} >{topic.issue_name}</Label>
                                  </div>
                                  )
                                })}
                            </div>
                      </div>
                      {this.state.issueType>0 &&
                      (
                        <div className="row-1 post_date responsible-person">
                            <Label htmlFor="ticket-date-field" className="ticket-date-title">Responsible Person</Label>
                            <FullUserPicker 
                                  myid={this.state.myId} 
                                  baseid={this.state.myBaseId} 
                                  teamid={0} 
                                  selected=""
                                  type="responsible" 
                                  usertype={this.state.showPeopleType} 
                                  onPeopleChange={this.onPeopleChange.bind(this)} />
                        </div>
                      )}
                      </>
                    )}
                        <div className="row-1 post_date ticket-status-box">
                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Status</Label>
                        <div className="ticket-text-status issue_dis_input  read-field  d-flex justify-content-between">
                          <span>{this.state.ticketStatusText}</span>
                          
                        </div>
                        <div className="assign-group ulchekbox">
                          {this.state.status.map((stat, index)=>
                            {
                              return(
                                <div className="d-flex justify-content-between status-items rbstyle ch-item">
                                    <Input id={stat.id} name="ticketStatus" 
                                    value={stat.id} type="radio" 
                                    checked= {this.state.ticketStatus == stat.id? 'checked': ''} 
                                    onChange={this.updateTicketStatus}
                                    />
                                    <Label for={stat.id} >{stat.status}</Label>
                                </div>
                              )
                            })}
                        </div>
                        <div className="row-1 post_date due_date_field">
                          <Label htmlFor="ticket-date-field" className="ticket-date-title">Due Date
                            <span className="optional-label"> (optional)</span>
                          </Label>
                            <DateTimePicker
                               minDate ={this.state.minDate}
                              calendarIcon={
                              <img
                              className="calender-icon"
                              src={CalenderIcon}
                              width="12"
                              height="12"
                              alt="CalenderIcon"
                              />
                              }
                              name='ticketDate'
                              onChange={this.onTDuedateChange}
                              value={this.state.ticketDueDate}
                              format="dd-MM-y"
                            />

                            
                        </div>  
                      </div>
                </div>
              </Card>
              <Card className="ticket-sidebar-wrapper ticket-reminder">
           
                          <Label htmlFor="ticket-date-field" className="ticket-date-title">Remind Me About This Ticket</Label>
                          <div className="reminder-options d-flex">
                          <FormGroup check>
                             <Label check>
                                <Input type="radio" name="reminderOptions" className="first-item" value="1" onChange={this.setReminderTimeOptions.bind(this)}/>
                                1 hour 
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" name="reminderOptions" value="2" onChange={this.setReminderTimeOptions.bind(this)} />2 hours
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" name="reminderOptions" value="3" onChange={this.setReminderTimeOptions.bind(this)} />3 hours
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" name="reminderOptions" value="5" onChange={this.setReminderTimeOptions.bind(this)} />5 hours
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" name="reminderOptions" value="24" onChange={this.setReminderTimeOptions.bind(this)} />1 day
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" name="reminderOptions" value="48" onChange={this.setReminderTimeOptions.bind(this)} />2 days 
                             </Label>
                          </FormGroup>
                          </div>
                          <Datetime
                            dateFormat="DD-MM-YYYY"
                            onChange={this.setReminderime.bind(this)}
                            value={this.state.reminderTime}
                            className={this.state.reminderTimeError? 'field-error' : ''}
                          />
               </Card>
            </Col>
            <Col xs={12} sm={12} lg={8}>
              <div className="back_btns">
                <Button className="backbtn" onClick={this.showDashboard}> <MaterialIcon icon="home"/></Button>
                {/* <Button className="backbtn ticket_list" onClick={this.showTicketList}><MaterialIcon icon="back" /></Button> */}
              </div>
            <section id="ticket_details" className="add_new_tickets">
                <div>
                      <FormGroup id="session-1">
                          <Input type="select"  id="relatedto" 
                          className={this.state.relatedError? 'field-error relates-area' : 'relates-area'}
                          onChange={this.handleChange}  name="relatedTo" >
                              <option value="">Related To</option>
                              {this.state.topics.map((topic, index)=>
                              {
                                return(
                                  <option key={index} value={topic.id}
                                  selected={this.state.relatedTo  == topic.id? 'selected':''}
                                  >{topic.topic_name}</option>
                                )
                              })}
                            </Input>
                          </FormGroup>
                </div>
                {this.state.relatedArray.includes(this.state.relatedTo) &&
                (
                  <>
                      
                      <div className="ticket-subject ticket-incident-field d-flex align-items-center">
                          {this.state.relatedTo == 22?
                          (
                            <Label>Feedback Date</Label>
                          )
                          :
                          (
                            <Label>Incident Date</Label>
                          )}
                          
                          <DatePickerTick startPoint='1' onDateRangeChange={this.onDateRangeChangeStart} />
                      </div>

                      <div className="ticket-client-name">
                          <Typeahead
                            id="user-picker"
                            labelKey="client_name"
                            onInputChange={this.onChangeClient}
                            onChange={this.onChangeClient}
                            options={this.state.clients}
                            placeholder="Client Name"
                            selected=''
                            />
                      </div>
                      
                      <div className="ticket-subject">
                          <Input type="text" placeholder="Trello URL / Kimp360 Card URL" 
                          className={this.state.trelloError? 'field-error ticket-subjet-txtbox' : 'ticket-subjet-txtbox'}
                          name="trelloURL" onChange={this.handleChange}

                          />
                      </div>
                      <div className="ticket-subject">
                          <Input type="text" placeholder="KimpHub URL" 
                          className={this.state.basecampError? 'field-error ticket-subjet-txtbox' : 'ticket-subjet-txtbox'}
                          name="basecampURL" onChange={this.handleChange}
                          />
                      </div>
                  </>
                )}
                <div className="ticket-subject">
                    <Input type="text" placeholder="Ticket subject line" 
                    className={this.state.subjectError? 'field-error ticket-subjet-txtbox' : 'ticket-subjet-txtbox'}
                    name="ticketSubject" onChange={this.handleChange}
                    />
                </div>
                <div className="new-ticket-details">
                  <ReactQuill value={this.state.ticketDetails}
                    onChange={this.handleChangeEditor}
                    className={this.state.detailError? 'field-error' : ''}
                    placeholder= "Ticket description..." 
                    theme="snow" />
                </div>
                {/* <div className="ticket-detail-preview">
                  <div className="preview-title"><h2>{this.state.ticketSubject}</h2></div>
                  <div className="preview-description" dangerouslySetInnerHTML={{ __html: this.state.ticketDetails }} />
                </div> */}
                <div id="" className="flex-fill equalHM ">
                  <div className="form-group multi-preview">
                      {(this.fileArray || []).map(url => (
                          <div className="prv_imgs">
                              <a href={url} target="_blank"> <img src={url} alt="..." /></a>
                              <span className="remove-img" onClick={this.removePreviewImg.bind(this, url)}></span>
                          </div>
                      ))}
                  </div>
                  
                    <div className="">
                        {/* <Input multiple type="file" name="attachments" id="attachments" onChange={this.uploadMultipleFiles} ></Input>
                        <Button className="btn_uplod">Attach Files</Button> */}

                        
                      <TicketFileUpload myid={this.state.myId}
                        folderpath="task/commentattachments"
                        arrayname="commentAttachments"
                        storefiles={this.storeCommentFileNames}
                      />

                      {(this.state.commentAttachments && this.state.commentAttachments.length > 0 && !this.state.commentAttachmentsChange) &&
                      (
                        <div class="attachment-items d-flex justify-contents-between">
                          <TicketLightboxPop
                            attachments={this.state.commentAttachments}
                            deleteitem="yes"
                            deletefiles={this.deleteCommentFileNames}
                            arrayname={'commentAttachments'}
                            listtype={1}
                          />
                        </div>
                      )}

                    </div>
                    {this.state.fileTypeError &&
                    (
                      <div className="file-type-error">
                        Attachments should be under 2 MB size and in image or pdf format. 
                      </div>
                    )}
                </div>
                <div className="add-ticket">
                    <Button className="btn btn-green save-ticket add_cmd_btn" onClick={this.createTicket}>Add Ticket</Button>
                </div>
            </section>
            </Col>
         </Row>
       </div>{/*Themebody*/}
       <div id="st1" onClick={this.scrollUp}></div>
      </Row>
      </Container>
 </div>
    )
  }
}
