import React, { useState, useEffect, Fragment } from 'react';
import { render } from "react-dom";
import ReactDOM from "react-dom";
import Select from "react-select";
import {useGetSalaryMonths} from "./useQueryHooks";

export default function SelectMonth(props)
{
  const [multiValue, setMultiValue] = useState('Choose Month...');
  const [options, setOptions] = useState([]);

  const { isLoading: monthLoading, data: monthdata } = useGetSalaryMonths();

  const handleOnChange = (value) => {
      setMultiValue(value);
      props.selectedmonth(value.value);
  }

  return (
    <div className="salary_country_choose">
      {!monthLoading &&
      (
        <Select
        options={monthdata.data?.mmonths}
        onChange={handleOnChange.bind(this)}
        value={multiValue}
        isSearchable={false}
        placeholder="Choose Month..."
      />
      )}
    </div>
  );

}