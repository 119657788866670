import React, { useState, useEffect} from "react";
import ConfirmAction from "../../admincomment/ConfirmAction";
import axios from 'axios';
import { handleFileDownload } from "../HandleFileDownload";
import {
  useGetRevsionFiles,
  useGetRevsionSubFolders,
  useAddRevisonSubFolder,
  useRemoveSourceFile,
  useGetBreadData,
  useRemoveSourceVersionFolders,
  useGetRevsionSubFolderMaster,
  useFileApprovalRequest,
  useAddOtherFolder,
  useFileRejectRequest,
  useDownloadasZip,
  useFindShift,
  useFileApprovalRequestAll,
  useFileRejectionRequestAll
} from "./useQueryHooks";
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  UncontrolledTooltip  
} from "reactstrap";
import { Spinner } from "reactstrap";
import moment from "moment";
import KFileUploadProgress from "./KFileUploadProgress";
import MaterialIcon from "material-icons-react";
import { urls } from "../../../services/UrlList";
import ClipboardCopy from "./ClipboardCopy";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import FolderShareLink from "./FolderShareLink";
import MoveSourcePop from "./MoveSourcePop";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import { useMedia} from "../../../MediaContext";
import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';

export default function RevisionData(props) {
  const [sharePop, setSharePop] = useState(false);
  const [sharingId, setSharingId] = useState(0);
  const [parentFolder, setParentFolder] = useState(props.parentfolder);
  const [parentFolderName, setParentFolderName] = useState('');
  const [parentFolderExt, setParentFolderExt] = useState('');
  const [parentFolderType, setParentFolderType] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderId, setFolderId] = useState("");
  const [editPop, setEditPop] = useState(false);
  const [showRevision, setShowRevision] = useState(false);
  const [revisionView, setRevisionView] = useState(0);
  const [showRevisionList, setShowRevisionList] = useState(true);
  const [movingId, setMovingId] = useState(0);
  const [moveFolderPopup, setMoveFolderPopup] = useState(false);
  const [movementType, setMMovementType] = useState('');
  const [folderSend, setFolderSend] = useState(false);
  const [attachFolderProgress, setAttachFolderProgress] = useState(false);
  const [fileFolderView, setFileFolderView] = useState(true);
  const [showRejectionConfirm, setShowRejectionConfirm] = useState(false);
  const [rejectStatus, setRejectStatus] = useState('');
  const [rejectId, setRejectId] = useState('');
  
  const [showMassApproveConfirm, setShowMassApproveConfirm] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('');


  // const [noteEdit, setNoteEdit] = useState(false);
  // const [noteEditId, setNoteEditId] = useState(0);
  // const [noteEditValue, setNoteEditValue] = useState('');
  
  const [showFolderDelete, setShowFolderDelete] = useState(false);
  const [showFileDelete, setShowFileDelete] = useState(false);
  const [deletionID, setDeletionID] = useState(0);
  const [fileDeletionID, setFileDeletionID] = useState(0);
  const [addFolderIds, setAddFolderids] = useState([]);
  const [addingLoader, setAddingLoader] = useState(false);
  const [blockReference, setBlockReference]= useState(false);
  const [otherFolderName, setOtherFolderName] = useState('');
  const [otherFolderFlag, setOtherFolderFlag] = useState(false);
  const [otherFolderError, setOtherFolderError] = useState(false);
  const [tooltipOpen, setTooTtipOpen] =useState(false);
  
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [downloadId, setDownloadId] = useState([]);
  const [rejectionId, setRejectionId] = useState(0);
  const [showMassRejectionConfirm, setShowMassRejectionConfirm] = useState(false);
  const [massRejectionStatus, setMassRejectionStatus] = useState('');
  const [filesDataImages, setFilesDataImages] = useState([]);
  const [filesDataFiles, setFilesDataFiles] = useState([]);

  const closeDeleteDialogue = () => {setShowFolderDelete(false); setShowFileDelete(false); }
  const closeRejectionDialogue = () =>{setShowRejectionConfirm(false); setRejectStatus(''); setRejectId('');};
  const closeRejectionDialogueAll = () =>{setShowMassRejectionConfirm(false);};

  const showActionPop = () => setShowPopup(true);
  const closeDialogue = () => setShowPopup(false);
  const showEditFolder = () =>  setEditPop(!editPop);
  
  const handleFolderUpdate = (e) => setFolderName(e.target.value);

  const mediaStatus = useMedia();

  // const showNoteEdit = (sfile) =>
  // {
  //   setNoteEditId(sfile.id);
  //   setNoteEditValue(sfile.file_note);
  //   setNoteEdit(true);
  // }

  useEffect(()=>
  {
    if(mediaStatus>0)
    {
      fetchNewFiles();
    }
  },[mediaStatus])

  const setDownloadStart = (folderid, foldername) =>
  {
    setFolderId(folderid);
    setFolderName(foldername);
    downloadVersionFolderData(folderid, foldername);
  }


  const downloadVersionFolderData = (folderid, foldername) => {
    const data1 = {
      currentFolderName: foldername,
      revisionid: props.revisionID,
      currentFolderId: folderid,
      taskid: props.taskid
    };
    downloadVersionFolder(data1);
  };
  const { mutate: downloadVersionFolder, isLoading: mutateLoading, } = useDownloadasZip();

  const {
    isLoading: shiftLoading,
    data: shiftData,
  } = useFindShift();

  const toggleSharePop = ()=>
  {
    setSharePop(!sharePop);
  }

  const showAddPop = () =>
  {
    setFolderId('');
    setFolderName('');
    setEditPop(!editPop);
  }

  const toggleMovePop = ()=>
  {
    setMoveFolderPopup(!moveFolderPopup);
    fetchSubFolders();
    fetchNewFiles();
  }

  useEffect(() => {
    setTimeout(() => {
      fetchSubFolders();
      fetchNewFiles();
      fetchSubFolderMaster();
    }, 1000);
    return;
  }, [parentFolder]);


  const setRevisionNum = (folderid, foldername, folderext,foldertype) => {
    setParentFolder(folderid);
    setParentFolderName(foldername);
    setParentFolderExt(folderext);
    setParentFolderType(foldertype)
  };

  const storeCommentFileNames = () => {
    fetchNewFiles();
  };

  const {
    isLoading: subFolderLoading,
    isError,
    error,
    isFetching,
    data: subFolderData,
    refetch: fetchSubFolders,
  } = useGetRevsionSubFolders(props.revisionID, parentFolder);
  
  const {
    isLoading: filesLoading,
    isFetching: filesFetching,
    data: filesData,
    refetch: fetchNewFiles,
  } = useGetRevsionFiles(props.revisionID, props.taskid, parentFolder);

  const {
    isLoading: breadLoading,
    isFetching: breadFetching,
    data: breadData,
    refetch: fetchBread,
  } = useGetBreadData(props.revisionID, parentFolder);


  const {
    data: subFolderMaster,
    refetch: fetchSubFolderMaster,
  } = useGetRevsionSubFolderMaster(props.revisionID, props.taskid,);


  const addUpdateSubFolderData = () => {
    setAddingLoader(true);
    const data1 = {
      folders: addFolderIds,
      revision_id: props.revisionID,
      parent_folder_id: parentFolder,
      folderId: folderId
    };
    addUpdateSubFolder(data1);
    showEditFolder();
    setAddFolderids([]);
    setAddingLoader(false);
  };
  const { mutate: addUpdateSubFolder } = useAddRevisonSubFolder();



  const addUpdateOtherFolderData = async () => {
    if(otherFolderName ==='')
    {
      setOtherFolderError(true);
      return false;
    }
    const data1 = {
      revisionID: props.revisionID,
      taskid: props.taskid,
      folderName: otherFolderName
    };
    await addUpdateOtherFolder(data1);
    setOtherFolderFlag(false);
    fetchSubFolderMaster();
  };
  const { mutate: addUpdateOtherFolder } =  useAddOtherFolder();



  const deleteFilesData = () => {
    const data2 = {
      revisionid: props.revisionID,
      taskid: props.taskid,
      parent_folder_id: 0,
      fileid: fileDeletionID
    };
    removeSourceFile(data2);
    closeDeleteDialogue();
  };
  const { isLoading: deleteLoading, mutate: removeSourceFile } = useRemoveSourceFile();

  const handleVFolderDeleteConfirm = () => {
    const data2 = {
      revisionid: props.revisionID,
      taskid: props.taskid,
      folder_id:deletionID,
      parent_folder_id: parentFolder
    };
    removeSourceFolders(data2);
    closeDeleteDialogue();
  };
  const { isLoading: deleteFolderLoading, mutate: removeSourceFolders } = useRemoveSourceVersionFolders();

  const selectFolders = (value)=>
  {
    let temp = [...addFolderIds];
    var index = temp.indexOf(value);
    if (index !== -1) {
      temp.splice(index, 1);
      setAddFolderids(temp);
    }else
    {
      temp.push(value);
      setAddFolderids(temp);
    }
  }

 const handleApproveUpdate = (e) => {
    if(e.target.checked === true)
      handleApprovalRequest(e.target.value, 1);
    else
      handleApprovalRequest(e.target.value, 0)
  };

  const handleApprovalRequest = (id, action) => {
    const data2 = {
      revisionid: props.revisionID,
      taskid: props.taskid,
      fileid: id,
      action: action
    };
    handleApprovalRequestAction(data2);
  };
  const { isLoading: approavlLoading, mutate: handleApprovalRequestAction } = useFileApprovalRequest();

  const closeApprovalDialogue = () => {setShowMassApproveConfirm(false);setApprovalStatus('')};

  const trigerMassApproval=(e)=>
  {
    setShowMassApproveConfirm(true);
    e.target.checked === true ? setApprovalStatus(1) : setApprovalStatus(0);
  }

  const handleApproveUpdateAll = () => {
    let tempids = [];
    filesData.data.revisionfiles.forEach((file)=>
    {
        if(file.rejection_status===0)
        {
          tempids.push(file.id)
        }
    })
    handleApprovalRequestAll(tempids, approvalStatus);
  };

  const handleApprovalRequestAll = (ids, action) => {
    const data2 = {
      revisionid: props.revisionID,
      taskid: props.taskid,
      fileids: ids,
      action: action
    };
    handleApprovalRequestActionAll(data2);
    closeApprovalDialogue();
  };
  const { isLoading: approavlLoadingAll, mutate: handleApprovalRequestActionAll } = useFileApprovalRequestAll();





  const trigerRejection = (e)=>
  {
    setShowRejectionConfirm(true);
    setRejectId(e.target.value);
    if(e.target.checked === true)
      setRejectStatus(1);
    else
      setRejectStatus(0)
  }

  
  const handleFileRejection = async () => {
    setRejectionId(rejectId);
    handleRejectRequest(rejectId, rejectStatus);
  };

  const handleRejectRequest = (id, action) => {
    const data2 = {
      revisionid: props.revisionID,
      taskid: props.taskid,
      fileid: id,
      action: action,
      uname:props.myname
    };
    handleRejectRequestAction(data2);
    closeRejectionDialogue();
  };
  const { isLoading: rejectLoading, mutate: handleRejectRequestAction } = useFileRejectRequest();



  const trigerMassRejection=(e)=>
  {
    setShowMassRejectionConfirm(true);
    e.target.checked === true ? setMassRejectionStatus(1) : setMassRejectionStatus(0);
  }

  const handleRejectionUpdateAll = () => {
    let tempids = [];
    filesData.data.revisionfiles.forEach((file)=>
    {
        if(file.rejection_status===0)
        {
          tempids.push(file.id)
        }
    })
    handleRejectionRequestAll(tempids, approvalStatus);
  };

  const handleRejectionRequestAll = (ids, action) => {
    const data2 = {
      revisionid: props.revisionID,
      taskid: props.taskid,
      fileids: ids,
      action: action
    };
    handleRejectionRequestActionAll(data2);
    closeRejectionDialogueAll();
  };
  const { isLoading: rejectionLoadingAll, mutate: handleRejectionRequestActionAll } = useFileRejectionRequestAll();




  const setCommentStorage = (type, value) => 
 {
     let commentData = {};
     if (localStorage.getItem("commentData") !== null)
     {
        let commentTemp = JSON.parse(localStorage.getItem("commentData"));
        if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
        {
          commentData = commentTemp;
        }
        commentData.taskID = props.tid_cpt;
        if (type == 'cattachments')
          commentData.cattachments = value;
        else if (type == 'chubattachments')
        commentData.chubattachments = value;
        localStorage.setItem("commentData", JSON.stringify(commentData));
    }


    let commentData60 = {};
     if (localStorage.getItem("commentData60") !== null)
     {
        let commentTemp = JSON.parse(localStorage.getItem("commentData60"));
        if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
        {
          commentData60 = commentTemp;
        }
        commentData60.taskID = props.tid_cpt;
        if (type == 'cattachments')
          commentData60.cattachments = value;
        else if (type == 'chubattachments')
        commentData60.chubattachments = value;
        localStorage.setItem("commentData60", JSON.stringify(commentData60));
    }

  }


  const attachComposerFiles = (file)=>
  {
    let temp = [...props.attachingFiles];
    let index = props.attachingFiles.findIndex(x => x.fileid == file.id);
    if(index>=0)
    {
      temp.splice(index, 1);
    }else
    {
      let data = {
        fileid: file.id,
        file_name: file.filename,
        file_type: file.filename.split(".").pop(),
        path: urls.s3Url+file.file_location+"/"+file.filename
      }
      temp.push(data);
    }
    //setCommentStorage('cattachments', temp);
    props.updateattachfiles(temp);
  }

  const attachComposerHubFiles = (file)=>
  {
    let temp = [...props.attachingFiles];
    let index = props.attachingFiles.findIndex(x => x.fileid == file.id);
    if(index>=0)
    {
      temp.splice(index, 1);
    }else
    {
      let data = {
        fileid: file.id,
        file_name: file.filename,
        file_size: file.file_size,
        file_type: file.filename.split(".").pop(),
        path: urls.s3Url+file.file_location+"/"+file.filename
      }
      temp.push(data);
    }
    //setCommentStorage('chubattachments', temp);
    props.updateattachfiles(temp);
  }


  const attachComposerFilesAll = (e)=>
  {
    let temp1 = filesData?.data.revisionfiles;
    let temp = [...props.attachingFiles];
    if (e.target.checked) {
      temp1.forEach((p1)=>
      {
        let index = temp.findIndex(x => x.fileid == p1.id);
        if(index<=0 && p1.rejection_status===0)
        {
          let data = {
            fileid: p1.id,
            file_name: p1.filename,
            file_size: p1.file_size,
            file_type: p1.filename.split(".").pop(),
            path: urls.s3Url+p1.file_location+"/"+p1.filename
          }
          temp.push(data);
        }
      })
      props.updateattachfiles(temp);
    }else
    {
      temp1.forEach((p1)=>
      {
        let index = temp.findIndex(x => x.fileid == p1.id);
        if(index>=0)
        {
          temp.splice(index, 1);
        }
      })
      props.updateattachfiles(temp);
    }
    //setCommentStorage('chubattachments', temp);
  }


  const attachComposerHubFilesAll = (e)=>
  {
    let temp1 = filesData?.data.revisionfiles;
    let temp = [...props.attachingFiles];
    if (e.target.checked) {
      temp1.forEach((p1)=>
      {
        let index = temp.findIndex(x => x.fileid == p1.id);
        if(index<0 && p1.rejection_status===0)
        {
          let data = {
            fileid: p1.id,
            file_name: p1.filename,
            file_size: p1.file_size,
            file_type: p1.filename.split(".").pop(),
            path: urls.s3Url+p1.file_location+"/"+p1.filename
          }
          temp.push(data);
        }
      })
      props.updateattachfiles(temp);
    }else
    {
      temp1.forEach((p1)=>
      {
        let index = temp.findIndex(x => x.fileid == p1.id);
        if(index>=0)
        {
          temp.splice(index, 1);
        }
      })
      props.updateattachfiles(temp);
    }
    //setCommentStorage('chubattachments', temp);
  }


  const enableforEditing = (uploadedtime)=>
  {
    if(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com') || props.myPrivileges.includes('i_am_operations') )
    {
      if((moment((new Date()).getTime()).tz(props.mytimezone).format('X')) - (moment((new Date(uploadedtime)).getTime()).tz(props.mytimezone).format('X'))<3600)
      {
        return true;
      }else
      {
        return false;
      }
    }else
    {
      if((moment((new Date()).getTime()).tz(props.mytimezone).format('X')) - (moment((new Date(uploadedtime)).getTime()).tz(props.mytimezone).format('X'))<300)
      {
        return true;
      }else
      {
        return false;
      }
    }                          
  }

  const findChildFolders = async (folderid, foldername, foldertype, revision_id,unique_sharekey, share_expiry) => {
    const data = {
      folderid,
      foldername,
      foldertype,
      revision_id,
      unique_sharekey, 
      share_expiry
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.findChildFolders, data, {
      headers: headers
    })
    .then((response) => {
        if(response.data && response.data.length>0)
        {
          sendToEditor2(response.data);
        }
    })
    .catch((error) => {
      //notifyError();
    })
  };


  const sendToEditor = async (folder)=>
  {
   
      let attachingFoldersOutput = [...props.attachingFoldersOutput];
      let attachingFoldersSource = [...props.attachingFoldersSource];
      let attachingFoldersOthers = [...props.attachingFoldersOthers];
      if(folder.type===1)  // Output Folder
      {
        var itemkey = attachingFoldersOutput.map(function (item) { return item.folder_name; }).indexOf(folder.folder_name); 
        if(itemkey== -1)
        {
        let file={
            'folder_name':folder.folder_name,
            'path': urls.sourcedomain360+"source/f/"+props.taskid+"/"+folder.revision_id+"/"+folder.id+"/"+folder.unique_sharekey+"/"+folder.share_expiry+"?v=1"
          }
          attachingFoldersOutput.push(file);
        }
        props.updateattachfolders(attachingFoldersOutput, attachingFoldersSource, attachingFoldersOthers);
      }else if(folder.type===2)  // Source Folder
      {
        var itemkey = attachingFoldersSource.map(function (item) { return item.folder_name; }).indexOf(folder.folder_name); 
        if(itemkey== -1)
        {
          let file={
            'folder_name':folder.folder_name,
            'path': urls.sourcedomain360+"source/f/"+props.taskid+"/"+folder.revision_id+"/"+folder.id+"/"+folder.unique_sharekey+"/"+folder.share_expiry+"?v=1"
          }
          attachingFoldersSource.push(file);
        }
        props.updateattachfolders(attachingFoldersOutput, attachingFoldersSource, attachingFoldersOthers);
      }else if(folder.type===3)  // Other Folder
      {
        var itemkey = attachingFoldersOthers.map(function (item) { return item.folder_name; }).indexOf(folder.folder_name); 
        if(itemkey== -1)
        {
          let file={
            'folder_name':folder.folder_name,
            'path': urls.sourcedomain360+"source/f/"+props.taskid+"/"+folder.revision_id+"/"+folder.id+"/"+folder.unique_sharekey+"/"+folder.share_expiry+"?v=1"
          }
          attachingFoldersOthers.push(file);
        }
        props.updateattachfolders(attachingFoldersOutput, attachingFoldersSource, attachingFoldersOthers);
      }else if(folder.type===4)  // Other Folder
      {
        setAttachFolderProgress(true);
        await findChildFolders(folder.id, folder.folder_name, folder.type,folder.revision_id,folder.unique_sharekey,folder.share_expiry);
      }
      
      
      let commentData = {};
      if (localStorage.getItem("commentData") !== null) {
        let commentTemp = JSON.parse(localStorage.getItem("commentData"));
        if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
        {
          commentData = commentTemp;
        }
      }

      commentData.taskID = props.tid_cpt;
      commentData.attachingFoldersOutput = attachingFoldersOutput;
      commentData.attachingFoldersSource = attachingFoldersSource;
      commentData.attachingFoldersOthers = attachingFoldersOthers;
      localStorage.setItem("commentData", JSON.stringify(commentData));

      
      let commentData60 = {};
      if (localStorage.getItem("commentData60") !== null) {
        let commentTemp = JSON.parse(localStorage.getItem("commentData60"));
        if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
        {
          commentData60 = commentTemp;
        }
      }

      commentData60.taskID = props.tid_cpt;
      commentData60.attachingFoldersOutput = attachingFoldersOutput;
      commentData60.attachingFoldersSource = attachingFoldersSource;
      commentData60.attachingFoldersOthers = attachingFoldersOthers;
      localStorage.setItem("commentData60", JSON.stringify(commentData60));

  }

  const sendToEditor2 = async (folders)=>
  {
      let attachingFoldersOutput = [...props.attachingFoldersOutput];
      let attachingFoldersSource = [...props.attachingFoldersSource];
      let attachingFoldersOthers = [...props.attachingFoldersOthers];
      await folders.forEach(folder => {
        if(folder.type===1)  // Output Folder
          {
            var itemkey = attachingFoldersOutput.map(function (item) { return item.folder_name; }).indexOf(folder.folder_name); 
            if(itemkey== -1)
            {
            let file={
                'folder_name':folder.folder_name,
                'path': urls.sourcedomain360+"source/f/"+props.taskid+"/"+folder.revision_id+"/"+folder.id+"/"+folder.unique_sharekey+"/"+folder.share_expiry+"?v=1"
              }
              attachingFoldersOutput.push(file);
            }
          }else if(folder.type===2)  // Source Folder
          {
            var itemkey = attachingFoldersSource.map(function (item) { return item.folder_name; }).indexOf(folder.folder_name); 
            if(itemkey== -1)
            {
              let file={
                'folder_name':folder.folder_name,
                'path': urls.sourcedomain360+"source/f/"+props.taskid+"/"+folder.revision_id+"/"+folder.id+"/"+folder.unique_sharekey+"/"+folder.share_expiry+"?v=1"
              }
              attachingFoldersSource.push(file);
            }
          }else if(folder.type===3  || folder.type===4)  // Other Folder
          {
            var itemkey = attachingFoldersOthers.map(function (item) { return item.folder_name; }).indexOf(folder.folder_name); 
            if(itemkey== -1)
            {
              let file={
                'folder_name':folder.folder_name,
                'path': urls.sourcedomain360+"source/f/"+props.taskid+"/"+folder.revision_id+"/"+folder.id+"/"+folder.unique_sharekey+"/"+folder.share_expiry+"?v=1"
              }
              attachingFoldersOthers.push(file);
            }
          }
          
          // else if(folder.type===4)  // Other Folder
          // {
          //   findChildFolders(folder.id, folder.folder_name, folder.type);
          // }
          
        });

        let commentData = {};
        if (localStorage.getItem("commentData") !== null) {
          let commentTemp = JSON.parse(localStorage.getItem("commentData"));
          if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
          {
            commentData = commentTemp;
          }
        }

        commentData.taskID = props.tid_cpt;
        commentData.attachingFoldersOutput = attachingFoldersOutput;
        commentData.attachingFoldersSource = attachingFoldersSource;
        commentData.attachingFoldersOthers = attachingFoldersOthers;
        localStorage.setItem("commentData", JSON.stringify(commentData));


        let commentData60 = {};
        if (localStorage.getItem("commentData60") !== null) {
          let commentTemp = JSON.parse(localStorage.getItem("commentData60"));
          if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
          {
            commentData60 = commentTemp;
          }
        }
      
        commentData60.taskID = props.tid_cpt;
        commentData60.attachingFoldersOutput = attachingFoldersOutput;
        commentData60.attachingFoldersSource = attachingFoldersSource;
        commentData60.attachingFoldersOthers = attachingFoldersOthers;
        localStorage.setItem("commentData60", JSON.stringify(commentData60));

        props.updateattachfolders(attachingFoldersOutput, attachingFoldersSource, attachingFoldersOthers);
        setAttachFolderProgress(false);
  }

  const trigerHandleFileDownload = async (path, filename) =>
    {
        setDownloadProgress(true);
        let tempdowmloadids = [...downloadId];
        tempdowmloadids.push(filename);
        setDownloadId(tempdowmloadids);
        let downloadedfile = await  handleFileDownload(path, filename);
        let itemkey = tempdowmloadids.map(function (item) { return item; }).indexOf(downloadedfile); 
        tempdowmloadids.splice(itemkey, 1);
        setDownloadId(tempdowmloadids);
        if(tempdowmloadids.length<=0)
        {
            setDownloadProgress(false);
        }
    }


  useEffect(()=>
  {
    if(breadData?.data.length>0)
    {
      if(breadData.data[breadData.data.length - 1].folder_id === 20)
      {
        setBlockReference(true);
      }else
      {
        setBlockReference(false);
      }
    }else
    {
      setBlockReference(false);
    }
    
  },[breadData]);

  const fileUploadProgessCount = (progresscount)=>
  {
      props.setTestCreatePortal(progresscount);
  }
  
  const trigerMediaUpload = (media, data) =>
  {
      props.trigermediauploadcommand(media, data);
  }

  useEffect(()=>
  {
    let tempimg = [];
    let tempfiles = [];
    if(filesData)
    {
      filesData.data.revisionfiles.forEach((p1)=>
      {
        
        if(p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
        || p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif'
        || p1.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'ico' )
        {
          let temp1 = p1;
          temp1.src =  `${urls.s3Url}${p1.file_location}/${p1.filename}`;
          temp1.sfile = p1;
          temp1.tile = p1.filename;
          temp1.filename = p1.filename;
          temp1.description = p1.filename;
          tempimg.push(temp1);
        }else
        {
          let temp2 = p1;
          tempfiles.push(temp2);
        }
        
      })
      setFilesDataImages(tempimg);
      setFilesDataFiles(tempfiles);
    }

  }, [filesData])

  if (subFolderLoading || filesLoading || breadLoading) {
    //|| isFetching  || breadFetching
    return <div>Loading....</div>;
  }
  return (
    <div className={attachFolderProgress && 'disable-further'}>
      <div className="revision-bread d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <span
            className="bread-link"
            onClick={() => {
              props.setShowRevision(false);
              props.setShowRevisionList(true);
            }}
          >
            All Versions
          </span>
          <span className="bread-seperator">
            {" "}
            <MaterialIcon icon="chevron_right" />
          </span>
          <span
            onClick={setRevisionNum.bind(this, 0,'','','')}
            className={breadData?.data.length > 0 ? "bread-link" : "bread-item"}
          >{props.revisionView}</span>
          {breadData?.data.map((breadcrumb, index) => {
            return (
              <>
                <span className="bread-seperator">
                  <MaterialIcon icon="chevron_right" />
                </span>
                <span
                  onClick={setRevisionNum.bind(this, breadcrumb.id, breadcrumb.folder_name, breadcrumb.exts, breadcrumb.type)}
                  className={
                    index + 1 === breadData?.data.length
                      ? "bread-item"
                      : "bread-link"
                  }
                >
                  {breadcrumb.folder_name}
                </span>
              </>
            );
          })}
         
        </div>
        
        {(props.showRevision && !props.publisherstatus && !props.composerstatus && !blockReference && !shiftLoading && shiftData?.data.status==1) && (
          <div className="new-version-upload d-flex justify-content-end">
            <Button
              className={`btn btn-green task_status_change revidata_newfolder_btn  btn btn-secondary ${addingLoader && ' btn-inactive'}`}
              onClick={showAddPop}>
              {addingLoader ? 'Adding' : 'New Folder' } 
            </Button>
            {parentFolder>0 &&
            (
              <Button
                className={`btn btn-green task_status_change revidata_newfolder_btn file-folder-view  btn btn-secondary ${addingLoader && ' btn-inactive'}`}
                onClick={()=>{setFileFolderView(!fileFolderView);}}>
                {fileFolderView ? 'Files Only View ' : 'Files & Folder View' } 
              </Button>
            )}
            
          </div>
         )}
      </div>
      {fileFolderView &&
      (
        <Row className="subfolder d-flex">
            <Col sm={!props.publisherstatus && !props.composerstatus ? '6' : '12'}>
              <div className="uploaded-versions versions-left-box">
            {subFolderData?.data.revisionsubs.length>0 &&
            (
                <>
                {subFolderData?.data.revisionsubs.map((folder, index) => {
                  return (
                    <div key={folder.id} className={`folder_box_som ${mutateLoading && folderId === folder.id && 'active-source-folder-actions'}`}>
                      <Card className="d-flex folder-wrapper-som source-folder-items">
                        <div
                          className="d-flex"
                          onClick={setRevisionNum.bind(this, folder.id,folder.folder_name, folder.exts, folder.type)}>
                          <MaterialIcon icon={"folder"} />
                          <span className="folder_name">
                            <span className="revision-name">{folder.folder_name}</span>
                            {/* <span className="revision-date">{folder.updated_on}</span> */}
                            <span className="revision-date">{folder.uname}</span>
                          </span>
                        </div>
                        <div className={`d-flex source-folder-actions`}>
                          <div className="quick_actions_som quick_actions_som_inner">
                            <Button title="share" className="quick_action_btn" onClick={()=>{ setSharingId(folder.id); setSharePop(true);}}>
                              <MaterialIcon icon="share" />
                            </Button>
                            <Button className="quick_action_btn" title="download" onClick={()=>{setDownloadStart(folder.id, folder.folder_name);}}>
                              {mutateLoading && folderId === folder.id ?
                              (
                                <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                              ):
                              (
                                <MaterialIcon icon="download" />
                              )}
                            
                            </Button>
                            {(props.composerstatus) &&
                            (
                              <Button className={`quick_action_btn ${folder.type=== 4 && 'multilevel-folder'} `} title="Send to Editor" onClick={()=>{sendToEditor(folder);}} >
                                <MaterialIcon icon="drive_file_move" />
                              </Button>
                            )}
                            {/* <Button className="quick_action_btn" title="move" onClick={()=>{ setMMovementType('Folder'); setMovingId(folder.id); setMoveFolderPopup(true);}}>
                              <MaterialIcon icon="drive_file_move" />
                            </Button> */}
                            {/* <Button title="edit" className="quick_action_btn" onClick={()=>{setFolderId(folder.id);setFolderName(folder.folder_name);showEditFolder();}}>
                              <MaterialIcon icon="edit" />
                            </Button> */}
                            {(enableforEditing(folder.created_on)) &&(
                              <Button title="delete" className="quick_action_btn" 
                                onClick={()=>{setDeletionID(folder.id);setShowFolderDelete(true);  } }>
                                <MaterialIcon icon="delete" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </>
            )}
            </div>
           </Col>
           <Col className="d-flex align-items-center">
            {(!props.publisherstatus && !props.composerstatus && parentFolder>0) &&
                (
                  <div className="new-version-upload w-100">
                  <KFileUploadProgress
                    folderpath="sources"
                    arrayname="commentAttachments"
                    storefiles={storeCommentFileNames}
                    revisionid={props.revisionID}
                    folderid={parentFolder}
                    taskid={props.taskid}
                    filesprogresscount={fileUploadProgessCount}
                    foldername={parentFolderName}
                    folderext={parentFolderExt}
                    foldertype={parentFolderType}
                    uploadingtriger={trigerMediaUpload}
                  />
                </div>
                )}
           </Col>
          </Row>
      )}
      
      {(parentFolder>0 && filesData?.data.revisionfiles.length>0) &&(
        <>
            <div className={`revision-files ${!fileFolderView && 'files-only-view'}`}>
              <Table responsive className="theme_table">
                <thead>
                  <tr>
                    <th>File</th>
                    {(!props.publisherstatus && !props.composerstatus && !blockReference) &&(
                    <th>
                      <span className="d-flex attach-file-withchekbox">
                      {(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com') || props.myPrivileges.includes('i_am_operations') ) &&
                      (
                        <Input type="checkbox" name="approvedFile"
                        onClick={trigerMassApproval}
                        />
                      )}
                        <Label>Client Approved</Label>
                      </span>
                    </th>
                    )}
                    <th>
                      {(props.composerstatus)?
                      (
                        <span className="d-flex attach-file-withchekbox">
                          <Input type="checkbox" name="approvedFile"
                          onClick={(e)=> props.composerhubstatus ? attachComposerHubFilesAll(e) :  attachComposerFilesAll(e)}
                         
                          />
                          <Label>Attach</Label>
                        </span>
                      ):
                      (
                        <span className="d-flex attach-file-withchekbox"> 
                          <Input type="checkbox" name="approvedFile"
                          onClick={trigerMassRejection}
                          title="Mass Rejection"
                          />
                          <Label>Actions</Label>
                        </span>
                        
                      )}
                    </th>
                    <th>Uploaded</th>
                    {/* {(!props.publisherstatus && !props.composerstatus && !blockReference) &&(
                    <th>Discard</th>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                <LoadingWrapper isLoading={filesLoading || filesFetching}></LoadingWrapper>
                <div className="attachment_list_revision">
                    {/* {
                      filesData?.data.revisionfiles.map((sfile, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="d-flex file-name">
                              <span className="fileicon-size">
                                <span className="attachment-type">
                                        <>
                                          <Lightbox
                                            images={[
                                              {
                                                src: `${urls.s3Url}${sfile.file_location}/${sfile.filename}`,
                                                title: sfile.filename,
                                                description: '',
                                                filename: sfile.filename
                                              }
                                            ]}
                                            renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                                              return (
                                                <>
                                                {image.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || image.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                                                || image.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || image.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif'
                                                || image.filename.match(/\.([^\./\?]+)($|\?)/)[1] == 'ico' ?
                                                (
                                                <>
                                                <img
                                                  key={idx}
                                                  src={image.src}
                                                  className='img-circle'
                                                  style={{width: width, height: height}}
                                                  onClick={toggleLightbox.bind(null, idx)} />
                                                  <span>Test</span>
                                                </>
                                                ):
                                                (
                                                  <span className="attachment-bg bg-small attach_other">
                                                    {image.filename.split(".").pop()}
                                                  </span>
                                                )}
                                                </>
                                              )
                                            }}/>
                                        </>
                                  </span>
                              </span>
                              <div className="filename">
                                  <h6 className={`${props.composerstatus && 'small-window-view'}`} >
                                    <span id={"tools"+index} href="#">{sfile.filename}</span>
                                  </h6>
                                    <UncontrolledTooltip  placement="top"  target={"tools"+index} style={{maxWidth:'600px',width:'auto'}} >
                                      {sfile.filename}
                                    </UncontrolledTooltip >
                                  <span>{sfile.file_size}</span>
                              </div>
                              </span>
                          </td>
                          {(!props.publisherstatus && !props.composerstatus && !blockReference) &&(
                            <td>
                              {sfile.rejection_status===0 ?
                              (
                                <>
                                {(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com') || props.myPrivileges.includes('i_am_operations') ) ?
                                (
                                <Input type="checkbox" value={sfile.id} name="approvedFile"
                                checked={sfile.approved_file === 1 ? 'checked' : ''}
                                onClick={handleApproveUpdate}
                                />):
                                (
                                  <span>-</span>
                                )}
                                </>
                              ):
                              ( 
                                <span className="rejected-file">X</span>
                              )}
                              
                            </td>
                          )}

                          {(props.composerstatus) ?(
                            <td>
                              {sfile.rejection_status===0 ?
                              (
                                <Input type="checkbox" value={sfile.id} name="approvedFile"
                                checked ={props.attachingFiles.findIndex(x => x.fileid == sfile.id)>=0 ? 'checked': ''}
                                onClick={()=> props.composerhubstatus ? attachComposerHubFiles(sfile) :  attachComposerFiles(sfile) }
                                />
                              ):
                              (
                                <span className="rejected-file" title={'This file is a discarded file and won\'t be able to shared with clients'}>X</span>
                              )}
                              
                            </td>
                          ):
                          (
                            <td className="last-item">
                              <LoadingWrapper isLoading={deleteLoading}>
                                
                                <span className="source-file-actions d-flex justify-content-between ">
                                <div className={`actions-grop-table ${sfile.rejection_status === 1 && 'cross_iconbox'}`}>
                                  {(!props.publisherstatus && !props.composerstatus && !blockReference && props.myPrivileges.includes('file_reviewer')) &&(
                                    <>
                                    {rejectLoading && rejectionId.includes(sfile.id) ? 
                                      <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                                    :
                                      <Input type="checkbox" value={sfile.id} name="rejectstatus"
                                      checked={sfile.rejection_status === 1 ? 'checked' : ''}
                                      className={sfile.rejection_status === 1 && `rejected-permanent`}
                                      onClick={sfile.rejection_status != 1 ? trigerRejection:''} title={sfile.rejection_status === 1 ? (`Discarded File By ${sfile.rejected_by} `) : 'Discard File'}
                                      />
                                    }
                                    </>
                                  )}
                                  <ClipboardCopy
                                    copyText={`${urls.s3Url}${sfile.file_location}/${sfile.filename}`}
                                  />
                                  <a title="download" target="_blank" className="abottom simple-download" onClick={(e) => { e.preventDefault(); trigerHandleFileDownload(urls.s3Url+sfile.file_location+"/"+sfile.filename, sfile.filename)} }>
                                    {downloadProgress && downloadId.includes(sfile.filename) ? 
                                      <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                                    :
                                      <MaterialIcon icon="save_alt" />
                                    }
                                      
                                  </a>
                                  <span className="move-icon" onClick={()=>{setMMovementType('File'); setMovingId(sfile.id); setMoveFolderPopup(true);}}>
                                    <MaterialIcon icon="drive_file_move_outlined" />
                                  </span>
                                  {(!props.publisherstatus && enableforEditing(sfile.created_on)) &&(
                                    <span className="delete-source-file"
                                      onClick={()=> {setFileDeletionID(sfile.id); setShowFileDelete(true); }}>
                                      <MaterialIcon icon="remove_circle_outline" />
                                    </span>
                                  )}
                                  </div>
                                </span>
                               
                              </LoadingWrapper>
                            </td>
                          )}

                          <td>
                            {sfile.updated_on}
                            <br />
                            {sfile.name}
                          </td>
                          </tr>
                       
                      );
                    })} */}

                  <Lightbox
                        images={filesDataImages}
                        renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                          return (
                            <>
                                <tr key={image.id}>
                                <td>
                                  <span className="d-flex file-name">
                                    <span className="fileicon-size">
                                      <span className="attachment-type">
                                      <img
                                        key={idx}
                                        src={`${urls.s3Url}${image.file_location}/${image.filename}`}
                                        className='img-circle'
                                        style={{width: width, height: height}}
                                        onClick={toggleLightbox.bind(null, idx)} />
                                        </span>
                                    </span>
                                    <div className="filename">
                                        <h6 className={`${props.composerstatus && 'small-window-view'}`} >
                                          <span id={"tools"+image.id} href="#">{image.filename}</span>
                                        </h6>
                                          <UncontrolledTooltip  placement="top"  target={"tools"+image.id} style={{maxWidth:'600px',width:'auto'}} >
                                            {image.filename}
                                          </UncontrolledTooltip >
                                        <span>{image.file_size}</span>
                                    </div>
                                    </span>
                                </td>
                                {(!props.publisherstatus && !props.composerstatus && !blockReference) &&(
                                  <td>
                                    {image.rejection_status===0 ?
                                    (
                                      <>
                                        {(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com') || props.myPrivileges.includes('i_am_operations') ) ?
                                        (
                                        <Input type="checkbox" value={image.id} name="approvedFile"
                                        checked={image.approved_file === 1 ? 'checked' : ''}
                                        onClick={handleApproveUpdate}
                                        />):
                                        (
                                          <span>-</span>
                                        )}
                                      </>
                                    ):
                                    ( 
                                      <span className="rejected-file">X</span>
                                    )}
                                    
                                  </td>
                                )}

                                {(props.composerstatus) ?(
                                  <td>
                                    {image.rejection_status===0 ?
                                    (
                                      <Input type="checkbox" value={image.id} name="approvedFile"
                                      checked ={props.attachingFiles.findIndex(x => x.fileid == image.id)>=0 ? 'checked': ''}
                                      onClick={()=> props.composerhubstatus ? attachComposerHubFiles(image) :  attachComposerFiles(image) }
                                      />
                                    ):
                                    (
                                      <span className="rejected-file" title={'This file is a discarded file and won\'t be able to shared with clients'}>X</span>
                                    )}
                                    
                                  </td>
                                ):
                                (
                                  <td className="last-item">
                                    <LoadingWrapper isLoading={deleteLoading}>
                                      
                                      <span className="source-file-actions d-flex justify-content-between ">
                                      <div className={`actions-grop-table ${image.rejection_status === 1 && 'cross_iconbox'}`}>
                                        {(!props.publisherstatus && !props.composerstatus && !blockReference && props.myPrivileges.includes('file_reviewer')) &&(
                                          <>
                                          {rejectLoading && rejectionId.includes(image.id) ? 
                                            <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                                          :
                                            <Input type="checkbox" value={image.id} name="rejectstatus"
                                            checked={image.rejection_status === 1 ? 'checked' : ''}
                                            className={image.rejection_status === 1 && `rejected-permanent`}
                                            onClick={image.rejection_status != 1 ? trigerRejection:''} title={image.rejection_status === 1 ? (`Discarded File By ${image.rejected_by} `) : 'Discard File'}
                                            />
                                          }
                                          </>
                                        )}
                                        <ClipboardCopy
                                          copyText={`${urls.s3Url}${image.file_location}/${image.filename}`}
                                        />
                                        <a title="download" target="_blank" className="abottom simple-download" onClick={(e) => { e.preventDefault(); trigerHandleFileDownload(urls.s3Url+image.file_location+"/"+image.filename, image.filename)} }>
                                          {downloadProgress && downloadId.includes(image.filename) ? 
                                            <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                                          :
                                            <MaterialIcon icon="save_alt" />
                                          }
                                            
                                        </a>
                                        {/* <span className="move-icon" onClick={()=>{setMMovementType('File'); setMovingId(image.id); setMoveFolderPopup(true);}}>
                                          <MaterialIcon icon="drive_file_move_outlined" />
                                        </span> */}
                                        {(!props.publisherstatus && enableforEditing(image.created_on)) &&(
                                          <span className="delete-source-file"
                                            onClick={()=> {setFileDeletionID(image.id); setShowFileDelete(true); }}>
                                            <MaterialIcon icon="remove_circle_outline" />
                                          </span>
                                        )}
                                        </div>
                                      </span>
                                    
                                    </LoadingWrapper>
                                  </td>
                                )}

                                <td>
                                  {image.updated_on}
                                  <br />
                                  {image.name}
                                </td>
                                </tr>
                            </>
                          )
                        }}/>

                      {
                      filesDataFiles.map((sfile, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="d-flex file-name">
                              <span className="fileicon-size">
                                <span className="attachment-type">
                                    <span className="attachment-bg bg-small attach_other">
                                      {sfile.filename.split(".").pop()}
                                    </span>
                                  </span>
                              </span>
                              <div className="filename">
                                  <h6 className={`${props.composerstatus && 'small-window-view'}`} >
                                    <span id={"tools"+index} href="#">{sfile.filename}</span>
                                  </h6>
                                    <UncontrolledTooltip  placement="top"  target={"tools"+index} style={{maxWidth:'600px',width:'auto'}} >
                                      {sfile.filename}
                                    </UncontrolledTooltip >
                                  <span>{sfile.file_size}</span>
                              </div>
                              </span>
                          </td>
                          {(!props.publisherstatus && !props.composerstatus && !blockReference) &&(
                            <td>
                              {sfile.rejection_status===0 ?
                              (
                                <>
                                {(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com') || props.myPrivileges.includes('i_am_operations') ) ?
                                (
                                <Input type="checkbox" value={sfile.id} name="approvedFile"
                                checked={sfile.approved_file === 1 ? 'checked' : ''}
                                onClick={handleApproveUpdate}
                                />):
                                (
                                  <span>-</span>
                                )}
                                </>
                              ):
                              ( 
                                <span className="rejected-file">X</span>
                              )}
                              
                            </td>
                          )}

                          {(props.composerstatus) ?(
                            <td>
                              {sfile.rejection_status===0 ?
                              (
                                <Input type="checkbox" value={sfile.id} name="approvedFile"
                                checked ={props.attachingFiles.findIndex(x => x.fileid == sfile.id)>=0 ? 'checked': ''}
                                onClick={()=> props.composerhubstatus ? attachComposerHubFiles(sfile) :  attachComposerFiles(sfile) }
                                />
                              ):
                              (
                                <span className="rejected-file" title={'This file is a discarded file and won\'t be able to shared with clients'}>X</span>
                              )}
                              
                            </td>
                          ):
                          (
                            <td className="last-item">
                              <LoadingWrapper isLoading={deleteLoading}>
                                
                                <span className="source-file-actions d-flex justify-content-between ">
                                <div className={`actions-grop-table ${sfile.rejection_status === 1 && 'cross_iconbox'}`}>
                                  {(!props.publisherstatus && !props.composerstatus && !blockReference && props.myPrivileges.includes('file_reviewer')) &&(
                                    <>
                                    {rejectLoading && rejectionId.includes(sfile.id) ? 
                                      <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                                    :
                                      <Input type="checkbox" value={sfile.id} name="rejectstatus"
                                      checked={sfile.rejection_status === 1 ? 'checked' : ''}
                                      className={sfile.rejection_status === 1 && `rejected-permanent`}
                                      onClick={sfile.rejection_status != 1 ? trigerRejection:''} title={sfile.rejection_status === 1 ? (`Discarded File By ${sfile.rejected_by} `) : 'Discard File'}
                                      />
                                    }
                                    </>
                                  )}
                                  <ClipboardCopy
                                    copyText={`${urls.s3Url}${sfile.file_location}/${sfile.filename}`}
                                  />
                                  <a title="download" target="_blank" className="abottom simple-download" onClick={(e) => { e.preventDefault(); trigerHandleFileDownload(urls.s3Url+sfile.file_location+"/"+sfile.filename, sfile.filename)} }>
                                    {downloadProgress && downloadId.includes(sfile.filename) ? 
                                      <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                                    :
                                      <MaterialIcon icon="save_alt" />
                                    }
                                      
                                  </a>
                                  {/* <span className="move-icon" onClick={()=>{setMMovementType('File'); setMovingId(sfile.id); setMoveFolderPopup(true);}}>
                                    <MaterialIcon icon="drive_file_move_outlined" />
                                  </span> */}
                                  {(!props.publisherstatus && enableforEditing(sfile.created_on)) &&(
                                    <span className="delete-source-file"
                                      onClick={()=> {setFileDeletionID(sfile.id); setShowFileDelete(true); }}>
                                      <MaterialIcon icon="remove_circle_outline" />
                                    </span>
                                  )}
                                  </div>
                                </span>
                               
                              </LoadingWrapper>
                            </td>
                          )}

                          <td>
                            {sfile.updated_on}
                            <br />
                            {sfile.name}
                          </td>
                          </tr>
                       
                      );
                    })}


                    </div>
                </tbody>
              </Table>
            </div>
        </>
      )}
    
      {showFolderDelete ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeDeleteDialogue}
          show={showFolderDelete}
          note="*This action cannot be undone, are you sure?"
          content="You are about to delete this subfolder and files"
          handlevfolderdeletion={handleVFolderDeleteConfirm}
          confirmBoxAction="deleteversionfolder"
        />
      ) : null}

      {showFileDelete ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeDeleteDialogue}
          show={showFileDelete}
          note="*This action cannot be undone, are you sure?"
          content="You are about to delete this file"
          handlevfiledeletion={deleteFilesData}
          confirmBoxAction="deleteversionfile"
        />
      ) : null}

      {showRejectionConfirm ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeRejectionDialogue}
          show={showRejectionConfirm}
          note="*This action cannot be undone, are you sure?"
          content= {rejectStatus == 1 ? "You are about to mark this file as rejected." : "You are about to mark this file as unrejected." }
          handlevfilerejection={handleFileRejection}
          confirmBoxAction="rejectversionfile"
        />
      ) : null}

        {showMassApproveConfirm ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeApprovalDialogue}
          show={showMassApproveConfirm}
          note="*Are you sure?"
          content= {approvalStatus == 1 ? "You are about to mark all the files as client approved, except the files rejected." : "You are about to mark all the files as unapproved." }
          handlevfileapproval={handleApproveUpdateAll}
          confirmBoxAction="massapprovefiles"
        />
      ) : null}

        {showMassRejectionConfirm ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeRejectionDialogueAll}
          show={showMassRejectionConfirm}
          note="*Are you sure?"
          content= {"You are about to mark all the files as rejected, including client approved, if any." }
          handlefilerejectionall={handleRejectionUpdateAll}
          confirmBoxAction="massrejectfiles"
        />
      ) : null}

      <Modal
        isOpen={editPop}
        toggle={showEditFolder}
        className="addtodo_wrapper"
        size="lg" style={{maxWidth: '700px', width: '100%'}}
      >
        <ModalHeader value="1" toggle={showEditFolder}>Select Folders
          <Button className="clase_btn_cp" value="1" onClick={showEditFolder}>
            <MaterialIcon icon="close" />{" "}
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} md={12}>
              <div className="folderwrap">
                <span className="fieldset">Output Folders</span>
                <div className="d-flex master-folders">
                  {subFolderMaster?.data.folders_output.map((folder, index)=>
                  {
                    return(
                      <span className={`master-folder-items ${addFolderIds.includes(folder.id) && 'folder-selected'}`} key={index}
                      onClick={selectFolders.bind(this,folder.id)}
                      >{folder.name}</span>
                    )
                  })}
                </div>
              </div>
            </Col>
            
            <Col xs={12} md={12}>
              <div className="folderwrap">
                <span className="fieldset">Source Folders</span>
                <div className="d-flex master-folders">
                  {subFolderMaster?.data.folders_source.map((folder, index)=>
                  {
                    return(
                      <span className={`master-folder-items ${addFolderIds.includes(folder.id) && 'folder-selected'}`} key={index}
                      onClick={selectFolders.bind(this,folder.id)}
                      >{folder.name}</span>
                    )
                  })}
                </div>
              </div>
            </Col>

            <Col xs={12} md={12}>
              <div className="folderwrap">
                <span className="fieldset">Other Default Folders</span>
                <div className="d-flex master-folders">
                  {subFolderMaster?.data.folders_reference.map((folder, index)=>
                  {
                    return(
                      <span className={`master-folder-items ${addFolderIds.includes(folder.id) && 'folder-selected'}`} key={index}
                      onClick={selectFolders.bind(this,folder.id)}
                      >{folder.name}</span>
                    )
                  })}
                </div>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="folderwrap">
                <span className="fieldset">Custom Folders</span>
                <div className="d-flex master-folders other-folders-wrap">
                  <span className="d-flex folders">
                    {subFolderMaster?.data.other_folders.map((folder, index)=>
                    {
                      return(
                        <span className={`master-folder-items ${addFolderIds.includes(folder.id) && 'folder-selected'}`} key={index}
                        onClick={selectFolders.bind(this,folder.id)}
                        >{folder.name}</span>
                      )
                    })}
                    <span className="add-other-folder" onClick={(e)=>setOtherFolderFlag(true)}><MaterialIcon icon="add"/> </span>
                  </span>
                </div>
                {otherFolderFlag &&
                (
                  <div className="otjer-folder-creation mt-3">
                    <Input className={`add-newfolder-input ${otherFolderError && 'add-newfolder-input-error'} `} placeholder="Enter Folder Name" name="otherNew" maxLength={20} onChange={(e)=>{setOtherFolderName(e.target.value); setOtherFolderError(false);}} />
                    <Button name="add-other" className="add-folder-cancel-btn" onClick={(e)=>{setOtherFolderFlag(false); setOtherFolderError(false);} }>Cancel </Button>
                    <Button name="add-other" className="add-folder-add-btn" onClick={addUpdateOtherFolderData}>Add</Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              {addFolderIds.length>0 &&
              (
                <Button className="save_btn" onClick={addUpdateSubFolderData}>Add Selected Folders</Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {sharePop &&(
          <FolderShareLink 
          taskid={props.taskid}
          sharepop={sharePop} 
          folderid={sharingId} 
          revisionid={props.revisionID} 
          closepop={toggleSharePop} />
      )}
      {moveFolderPopup &&(
          <MoveSourcePop 
          taskid={props.taskid}
          movepop={moveFolderPopup} 
          movingid={movingId} 
          revisionid={props.revisionID} 
          movetype={movementType}
          closepop={toggleMovePop} />
      )}
    </div>
  );
}
